import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import kooledgeLogo from '../../images/kooledge-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { toggleSideMenu } from '../../actions/navbarAction';

import NavbarMenu from '../NavbarMenu/NavbarMenu';
import NavbarMenuMobile from '../NavbarMenuMobile/NavbarMenuMobile';

import './Navbar.scss';

const Navbar = () => {
  const navbarState = useSelector((state) => state.navbar);

  const dispatch = useDispatch();

  var navbarMenuMobileClass = classNames('navbar-menu-mobile', {
    active: navbarState.showSideMenu,
  });

  return (
    <div className="navbar">
      <div className="my-container navbar-container">
        <div className="navbar-logo-wrapper">
          <div onClick={() => (window.location.href = '/')}>
            <img className="navbar-logo" src={kooledgeLogo} alt="logo" />
          </div>
        </div>
        {!navbarState.showSideMenu && (
          <FontAwesomeIcon
            icon={faBars}
            className="menu-icon-bars"
            onClick={() => dispatch(toggleSideMenu())}
          />
        )}
        <NavbarMenu />
      </div>
      <NavbarMenuMobile className={navbarMenuMobileClass} />
    </div>
  );
};

export default Navbar;
