import React, { useState } from 'react';
import ItemsCarousel from 'react-items-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import TopicContentCard from '../TopicContentCard/TopicContentCard';
import AddTopicContentCard from '../AddTopicContentCard/AddTopicContentCard';

import './TopicContentList.scss';

const TopicContentList = (props) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  return (
    <div className="topic-content-list-wrapper">
      <div className="topic-content-list">
        <div style={{ padding: '0 60px', width: '100%', maxWidth: '100%' }}>
          <ItemsCarousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            infiniteLoop={false}
            gutter={20}
            activePosition={'center'}
            chevronWidth={60}
            disableSwipe={false}
            alwaysShowChevrons={false}
            numberOfCards={3}
            slidesToScroll={1}
            outsideChevron={true}
            showSlither={false}
            firstAndLastGutter={false}
            leftChevron={
              <div className="chevron">
                <FontAwesomeIcon icon={faChevronLeft} className="chevron-icon" />
              </div>
            }
            rightChevron={
              <div className="chevron">
                <FontAwesomeIcon icon={faChevronRight} className="chevron-icon" />
              </div>
            }
          >
            {!props.quizView && props.videos.length
              ? props.videos
                  .filter((video) => video.uuid !== props.featuredVideo?.uuid)
                  .map((video) => (
                    <TopicContentCard
                      key={video.uuid}
                      topicUuid={props.topicUuid}
                      video={video}
                      featuredVideo={props.featuredVideo}
                      currentVideo={props.currentVideo}
                      setCurrentVideo={props.setCurrentVideo}
                      setCurrentVideoTitle={props.setCurrentVideoTitle}
                      isStartQuiz={props.isStartQuiz}
                      setIsStartQuiz={props.setIsStartQuiz}
                      isTakeQuiz={props.isTakeQuiz}
                      setIsTakeQuiz={props.setIsTakeQuiz}
                      quizView={props.quizView}
                      editMode={props.editMode}
                    />
                  ))
              : null}
            {props.quizView && props.quizzes.length
              ? props.quizzes
                  .filter((quiz) => quiz.uuid !== props.officialQuiz?.uuid)
                  .map((quiz) => (
                    <TopicContentCard
                      key={quiz.uuid}
                      topicUuid={props.topicUuid}
                      quiz={quiz}
                      officialQuiz={props.officialQuiz}
                      currentQuiz={props.currentQuiz}
                      setCurrentQuiz={props.setCurrentQuiz}
                      isStartQuiz={props.isStartQuiz}
                      setIsStartQuiz={props.setIsStartQuiz}
                      isTakeQuiz={props.isTakeQuiz}
                      setIsTakeQuiz={props.setIsTakeQuiz}
                      quizView={props.quizView}
                      editMode={props.editMode}
                    />
                  ))
              : null}
            <AddTopicContentCard
              key="addtopiccontentcard"
              topicUuid={props.topicUuid}
              quizView={props.quizView}
              topicVideos={!props.quizView ? props.videos : []}
              topicQuizzes={props.quizView ? props.quizzes : []}
              editMode={props.editMode}
            />
          </ItemsCarousel>
        </div>
      </div>
    </div>
  );
};

export default TopicContentList;
