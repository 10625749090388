import axios from 'axios';
import axiosApiInstance from './axios';

import { url, GET_COMMENTS_LIMIT } from './constants';

import {
  ADD_COMMENT,
  ADD_COMMENT_ERROR,
  DELETE_COMMENT,
  DELETE_COMMENT_ERROR,
  ADD_REPLY,
  ADD_REPLY_ERROR,
} from './actionTypes';

export const addComment = (formData) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.post(`${url}/video/comments/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
    });

    dispatch({
      type: ADD_COMMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ADD_COMMENT_ERROR,
      payload: err.response,
    });
  }
};

export const deleteComment = (commentUuid) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.delete(`${url}/video/comments/${commentUuid}/`);

    dispatch({
      type: DELETE_COMMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DELETE_COMMENT_ERROR,
      payload: err.response,
    });
  }
};

export const addReply = (formData) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.post(`${url}/video/comments/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
    });

    dispatch({
      type: ADD_REPLY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ADD_REPLY_ERROR,
      payload: err.response,
    });
  }
};

export const getComments = async (videoUuid, topicUuid, limit, offset) => {
  if (localStorage.getItem('accessToken')) {
    var res = await axiosApiInstance.get(
      `${url}/video/get_comments?limit=${limit}&video=${videoUuid}&topic=${topicUuid}&offset=${offset}`,
    );
  } else {
    var res = await axios.get(
      `${url}/video/get_comments?limit=${limit}&video=${videoUuid}&topic=${topicUuid}&offset=${offset}`,
    );
  }

  return res;
};
