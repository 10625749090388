import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import sortby from 'lodash.sortby';
import InfiniteScroll from 'react-infinite-scroll-component';

import { appendFlows } from '../../actions/flowAction';
import { HOME_DROPDOWN_ITEMS, GET_FLOWS_LIMIT } from '../../actions/constants';

import CustomDropdown from '../CustomDropdown/CustomDropdown';
import HomeFlow from '../HomeFlow/HomeFlow';
import Loader from '../Loader/Loader';

import './HomeFlowList.scss';

const HomeFlowList = () => {
  const [selectedFilter, setSelectedFilter] = useState('all-flows');

  const flowState = useSelector((state) => state.flow);

  const dispatch = useDispatch();

  const handleDropdownChange = (filter) => {
    setSelectedFilter(filter);
  };

  const fetchMoreFlows = async () => {
    dispatch(appendFlows(GET_FLOWS_LIMIT, flowState.allFlows.length));
  };

  return (
    <div className="home-flow-list-container">
      <div className="home-flow-list-heading-row">
        <h1 className="home-flow-list-heading">Flows</h1>
        <CustomDropdown
          handleDropdownChange={handleDropdownChange}
          type="bordered"
          value={selectedFilter}
          items={HOME_DROPDOWN_ITEMS}
        />
      </div>
      {flowState.allFlows.length > 0 ? (
        <InfiniteScroll
          dataLength={flowState.allFlows.length}
          next={fetchMoreFlows}
          hasMore={flowState.next ? true : false}
          loader={
            <Loader
              margin="-20px 0 60px"
              height="4vh"
              svgWidth="1.4rem"
              svgHeight="1.4rem"
              svgBorderWidth="0.2em"
            />
          }
        >
          <div className="home-flow-list-grid">
            {selectedFilter === 'all-flows'
              ? sortby(flowState.allFlows, [
                  selectedFilter === 'most-likes'
                    ? 'likes'
                    : selectedFilter === 'most-views'
                    ? 'views'
                    : selectedFilter === 'most-recent'
                    ? 'created_at'
                    : null,
                ]).map((flow) => (
                  <HomeFlow
                    key={flow.uuid}
                    flowId={flow.uuid}
                    title={flow.title}
                    thumbnail={flow.thumbnail}
                    views={flow.views}
                    videos={flow.topics
                      .map((topic) => topic.videos.length)
                      .reduce((a, b) => a + b, 0)}
                    likes={flow.likes}
                    topics={flow.topics.length}
                    quizzes={flow.topics
                      .map((topic) => topic.quizzes.length)
                      .reduce((a, b) => a + b, 0)}
                    tags={flow.tags}
                    description={flow.description}
                    creatorName={flow.creator_name}
                  />
                ))
              : sortby(flowState.allFlows, [
                  selectedFilter === 'most-likes'
                    ? 'likes'
                    : selectedFilter === 'most-views'
                    ? 'views'
                    : selectedFilter === 'most-recent'
                    ? 'created_at'
                    : null,
                ])
                  .reverse()
                  .map((flow) => (
                    <HomeFlow
                      key={flow.uuid}
                      flowId={flow.uuid}
                      title={flow.title}
                      thumbnail={flow.thumbnail}
                      views={flow.views}
                      videos={flow.topics
                        .map((topic) => topic.videos.length)
                        .reduce((a, b) => a + b, 0)}
                      likes={flow.likes}
                      topics={flow.topics.length}
                      quizzes={flow.topics
                        .map((topic) => topic.quizzes.length)
                        .reduce((a, b) => a + b, 0)}
                      tags={flow.tags}
                      description={flow.description}
                      creatorName={flow.creator_name}
                    />
                  ))}
          </div>
        </InfiniteScroll>
      ) : (
        <p className="no-flows">No Flows</p>
      )}
    </div>
  );
};

export default HomeFlowList;
