import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { verifyEmail } from '../../../actions/authAction';

import './VerifyEmail.scss';

const VerifyEmail = () => {
  const authState = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const match = useRouteMatch();

  useEffect(() => {
    dispatch(verifyEmail({ uidb64: match.params.uidb64, token: match.params.token }));
  }, []);

  return (
    <div className="my-container verify-email-page-container">
      <div className="verify-email-page">
        {authState.verified ? <h2>Email verified</h2> : <h2>Verifying email...</h2>}
      </div>
    </div>
  );
};

export default VerifyEmail;
