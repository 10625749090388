import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { TextField } from '@material-ui/core';

import authBg from '../../images/auth-bg.png';

import {
  requestPasswordResetEmail,
  clearPasswordResetState,
  setAuthLoading,
} from '../../actions/authAction';

import Loader from '../Loader/Loader';

import './ForgotPassword.scss';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const authState = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    return () => {
      dispatch(clearPasswordResetState());
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(setAuthLoading());
    dispatch(requestPasswordResetEmail({ email }));
  };

  return authState.authLoading ? (
    <Loader />
  ) : localStorage.getItem('accessToken') ? (
    <Redirect to="/" />
  ) : authState.successMessage === 'Email sent' ? (
    <div
      className="forgot-password-container"
      style={{
        backgroundImage: `url(${authBg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="auth-bg-overlay"></div>
      <div className="auth-card">
        <div className="auth-card-header forgot-password-header-success">
          <h3 className="auth-card-header-text">Recover Credentials</h3>
        </div>
        <div className="auth-card-body">
          <p className="forgot-password-body-success-text">
            If an account exists for {authState.recoveryEmail}, you will receive an email to recover
            your password. If it doesn’t arrive, be sure to check your spam folder.
          </p>
        </div>
        <div className="auth-card-footer">
          <button
            className="auth-card-button-dark back-to-sign-in-button"
            onClick={() => history.push('/login')}
          >
            Back to Sign In
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="forgot-password-container"
      style={{
        backgroundImage: `url(${authBg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="auth-card">
        <div className="auth-card-header auth-card-header-multiline">
          <div className="auth-card-header-multiline-heading-wrapper">
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="auth-card-hearder-icon"
              onClick={() => history.push('/login')}
            />
            <h3 className="auth-card-header-text">Recover Credentials</h3>
            <FontAwesomeIcon
              icon={faTimes}
              className="auth-card-hearder-icon"
              onClick={() => history.push('/')}
            />
          </div>
          <p className="auth-card-header-multiline-text">
            Enter email to receive your username and password
          </p>
        </div>
        <div className="auth-card-body">
          <form className="auth-form" id="forgotPasswordForm" onSubmit={handleSubmit}>
            <TextField
              className="auth-card-input"
              label="Email"
              variant="filled"
              type="email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </form>
        </div>
        <div className="auth-card-footer">
          <button
            className="auth-card-button-dark back-to-sign-in-button"
            type="submit"
            form="forgotPasswordForm"
          >
            Recover Password
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
