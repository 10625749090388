import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

import QuizQuestion from '../QuizQuestion/QuizQuestion';

import './QuizQuestionCollapsed.scss';

const QuizQuestionCollapsed = (props) => {
  const [expandQuestion, setExpandQuestion] = useState(false);

  const handleDeleteQuestion = (e) => {
    e.stopPropagation();
    props.deleteQuestion(props.questionId);
  };

  return !expandQuestion ? (
    <div
      className="quiz-question-collapsed-wrapper"
      onClick={() => setExpandQuestion(!expandQuestion)}
    >
      <div className="quiz-question-wrapper">
        <p className="question-label">{`Question ${props.questionId + 1}`}</p>
        <p className="question-text">{props.question.statement}</p>
      </div>
      <div className="delete-question" onClick={handleDeleteQuestion}>
        <FontAwesomeIcon icon={faTrashAlt} className="trash-icon" />
        <p className="delete-text">Delete Question</p>
      </div>
    </div>
  ) : (
    <QuizQuestion
      questionId={props.questionId}
      question={props.question}
      expanded={expandQuestion}
      setExpandQuestion={setExpandQuestion}
      updateQuestion={props.updateQuestion}
      setDeletedAnswers={props.setDeletedAnswers}
      edit={props.edit}
    />
  );
};

export default QuizQuestionCollapsed;
