import axiosApiInstance from './axios';

import { url } from './constants';

import {
  CREATE_QUIZ,
  CREATE_QUIZ_ERROR,
  GET_MY_QUIZZES,
  GET_MY_QUIZZES_ERROR,
  ADD_QUIZ_TO_TOPIC,
  ADD_QUIZ_TO_TOPIC_ERROR,
  REMOVE_QUIZ_FROM_TOPIC,
  REMOVE_QUIZ_FROM_TOPIC_ERROR,
  SET_OFFICIAL_QUIZ,
  SET_OFFICIAL_QUIZ_ERROR,
  REMOVE_OFFICIAL_QUIZ,
  REMOVE_OFFICIAL_QUIZ_ERROR,
  INCREMENT_QUIZ_TRY,
  INCREMENT_QUIZ_TRY_ERROR,
  SUBMIT_QUIZ,
  SUBMIT_QUIZ_ERROR,
  CREATE_PDF,
  CREATE_PDF_ERROR,
  SET_QUIZ_SUBMITTED,
  CLEAR_SUBMISSION_RESPONSE,
  CLEAR_QUIZ_STATE,
  QUIZ_LOADING,
  GET_FLOW,
} from './actionTypes';

export const createQuiz = (formData) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.post(`${url}/quiz/quiz/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
    });

    dispatch({
      type: CREATE_QUIZ,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_QUIZ_ERROR,
      payload: err.response,
    });
  }
};

export const addQuizToTopic = (formData, topicUuid, flowUuid) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.post(`${url}/flow/add_quiz/${topicUuid}`, formData);

    dispatch({
      type: ADD_QUIZ_TO_TOPIC,
      payload: res.data,
    });

    const res2 = await axiosApiInstance.get(`${url}/flow/get_flows/${flowUuid}/`);

    dispatch({
      type: GET_FLOW,
      payload: res2.data,
    });
  } catch (err) {
    dispatch({
      type: ADD_QUIZ_TO_TOPIC_ERROR,
      payload: err.response,
    });
  }
};

export const removeQuizFromTopic = (formData, topicUuid, flowUuid) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.post(`${url}/flow/remove_quiz/${topicUuid}`, formData);

    dispatch({
      type: REMOVE_QUIZ_FROM_TOPIC,
      payload: res.data,
    });

    const res2 = await axiosApiInstance.get(`${url}/flow/get_flows/${flowUuid}/`);

    dispatch({
      type: GET_FLOW,
      payload: res2.data,
    });
  } catch (err) {
    dispatch({
      type: REMOVE_QUIZ_FROM_TOPIC_ERROR,
      payload: err.response,
    });
  }
};

export const setOfficialQuiz = (formData, topicUuid, flowUuid) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.post(`${url}/flow/set_official_quiz/${topicUuid}`, formData);

    dispatch({
      type: SET_OFFICIAL_QUIZ,
      payload: res.data,
    });

    const res2 = await axiosApiInstance.get(`${url}/flow/get_flows/${flowUuid}/`);

    dispatch({
      type: GET_FLOW,
      payload: res2.data,
    });
  } catch (err) {
    dispatch({
      type: SET_OFFICIAL_QUIZ_ERROR,
      payload: err.response,
    });
  }
};

export const removeOfficialQuiz = (topicUuid, flowUuid) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.post(`${url}/flow/remove_official_quiz/${topicUuid}`);

    dispatch({
      type: REMOVE_OFFICIAL_QUIZ,
      payload: res.data,
    });

    const res2 = await axiosApiInstance.get(`${url}/flow/get_flows/${flowUuid}/`);

    dispatch({
      type: GET_FLOW,
      payload: res2.data,
    });
  } catch (err) {
    dispatch({
      type: REMOVE_OFFICIAL_QUIZ_ERROR,
      payload: err.response,
    });
  }
};

export const incrementQuizTry = (quizUuid) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.patch(`${url}/quiz/add_try/${quizUuid}`);

    dispatch({
      type: INCREMENT_QUIZ_TRY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: INCREMENT_QUIZ_TRY_ERROR,
      payload: err.response,
    });
  }
};

export const submitQuiz = (formData) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.post(`${url}/quiz/take_quiz/`, formData);

    if (res.data.topic_pdf || res.data.flow_pdf) {
      dispatch({
        type: CREATE_PDF,
        payload: res.data,
      });
    } else {
      dispatch({
        type: CREATE_PDF_ERROR,
      });
    }

    dispatch({
      type: SUBMIT_QUIZ,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SUBMIT_QUIZ_ERROR,
      payload: err.response,
    });
  }
};

export const setQuizSubmitted = (param) => (dispatch) => {
  dispatch({
    type: SET_QUIZ_SUBMITTED,
    payload: param,
  });
};

export const getMyQuizzes = () => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(`${url}/quiz/my_quizzes`);

    dispatch({
      type: GET_MY_QUIZZES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_MY_QUIZZES_ERROR,
      payload: err.response,
    });
  }
};

export const clearSubmissionResponse = () => (dispatch) => {
  dispatch({
    type: CLEAR_SUBMISSION_RESPONSE,
  });
};

export const clearQuizState = () => (dispatch) => {
  dispatch({
    type: CLEAR_QUIZ_STATE,
  });
};

export const setQuizLoading = () => (dispatch) => {
  dispatch({
    type: QUIZ_LOADING,
  });
};
