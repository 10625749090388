import React from 'react';
import { Select, MenuItem, FormControl } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import './CustomDropdown.scss';

const styles = (theme) => ({
  icon: {
    fill: '#080E2F',
    fontSize: '1.5rem',
    marginRight: '18px',
  },
});

const CustomDropdown = ({ type, classes, value, items, handleDropdownChange }) => {
  const formControlClass = classNames(
    { 'bordered-filter': type === 'bordered' },
    { 'underlined-filter': type === 'underlined' },
  );

  return (
    <FormControl variant="" className={formControlClass}>
      <Select
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
        MenuProps={{
          classes: {
            paper: 'home-dropdown-menu',
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        value={value}
        IconComponent={KeyboardArrowDownIcon}
        onChange={(e) => handleDropdownChange(e.target.value)}
      >
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(CustomDropdown);
