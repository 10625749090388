import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useElementSize from 'react-element-size';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

import homeBanner from '../../images/home-banner.png';
import blankPicture from '../../images/blank-image.png';

import './HomeBannerItem.scss';

const HomeBannerItem = ({ flow }) => {
  const history = useHistory();

  const homeBannerItem = useElementSize();

  const BANNER_WIDTH = homeBannerItem.size.width;

  useEffect(() => {
    document.querySelector('.home-banner-item-wrapper').parentNode.style.height = '100%';
  }, []);

  return (
    <div
      className="home-banner-item-wrapper"
      style={{
        backgroundImage: `url(${flow.banner ? flow.banner : homeBanner})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
      ref={homeBannerItem.setRef}
    >
      <div
        className="home-banner-item-overlay"
        style={{ padding: `${(BANNER_WIDTH / 1000) * 40}px ${(BANNER_WIDTH / 1000) * 60}px` }}
      >
        <div
          className="home-banner-item-details"
          style={{ rowGap: `${(BANNER_WIDTH / 1000) * 20}px` }}
        >
          <h1 className="title" style={{ fontSize: `${(BANNER_WIDTH / 1000) * 1.95}rem` }}>
            {flow.title}
          </h1>
          {flow.description && (
            <p
              className="description"
              style={{
                fontSize: `${(BANNER_WIDTH / 1000) * 0.7}rem`,
                margin: `${(BANNER_WIDTH / 1000) * -15}px 0 0`,
              }}
            >
              {flow.description}
            </p>
          )}
          <div className="play-flow-button-wrapper">
            <button
              className="play-flow-button"
              type="button"
              style={{ padding: `${(BANNER_WIDTH / 1000) * 10}px ${(BANNER_WIDTH / 1000) * 18}px` }}
              onClick={() => history.push(`/flow/${flow.uuid}`)}
            >
              <FontAwesomeIcon
                icon={faPlay}
                className="play-flow-button-icon"
                style={{ fontSize: `${(BANNER_WIDTH / 1000) * 0.8}rem` }}
              />
              <h3
                className="play-flow-button-text"
                style={{
                  fontSize: `${(BANNER_WIDTH / 1000) * 0.8}rem`,
                  margin: `0 0 0 ${(BANNER_WIDTH / 1000) * 10}px`,
                }}
              >
                Play Flow
              </h3>
            </button>
          </div>
          <div className="creator-details-wrapper">
            <img
              className="creator-profile-image"
              src={flow.avatar ? flow.avatar : blankPicture}
              style={{
                width: `${(BANNER_WIDTH / 1000) * 40}px`,
                height: `${(BANNER_WIDTH / 1000) * 40}px`,
                margin: `0 ${(BANNER_WIDTH / 1000) * 13}px 0 0`,
              }}
            />
            <h3
              className="creator-name"
              style={{
                fontSize: `${(BANNER_WIDTH / 1000) * 0.8}rem`,
              }}
            >
              {flow.creator_name}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBannerItem;
