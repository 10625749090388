import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faClone } from '@fortawesome/free-regular-svg-icons';

import {
  EmailShareButton,
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton,
  EmailIcon,
  WhatsappIcon,
  FacebookIcon,
  TwitterIcon,
} from 'react-share';

import { SOCIAL_ICON_SIZE } from '../../actions/constants';

import './SocialShareModal.scss';

const SocialShareModal = (props) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCopyUrl = () => {
    var url = document.getElementById('copyUrl').innerText;
    var elem = document.createElement('textarea');
    document.body.appendChild(elem);
    elem.value = url;
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);

    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleModalClose = () => {
    setOpenSnackbar(false);
    props.onHide();
  };

  return (
    <Modal className="social-share-modal" {...props} centered>
      {
        <div className="social-share-container">
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="close-modal-icon"
            onClick={handleModalClose}
          />
          <Modal.Header>
            <h3 className="header-text">Social Share</h3>
            <p className="header-sub-text">
              Share this across the following social platforms, or send a unique link directly.
            </p>
          </Modal.Header>
          <Modal.Body>
            <div className="social-share-body">
              <EmailShareButton url={props.url}>
                <EmailIcon round size={SOCIAL_ICON_SIZE} />
              </EmailShareButton>
              <WhatsappShareButton url={props.url}>
                <WhatsappIcon round size={SOCIAL_ICON_SIZE} />
              </WhatsappShareButton>
              <FacebookShareButton url={props.url}>
                <FacebookIcon round size={SOCIAL_ICON_SIZE} />
              </FacebookShareButton>
              <TwitterShareButton url={props.url}>
                <TwitterIcon round size={SOCIAL_ICON_SIZE} />
              </TwitterShareButton>
            </div>
            <div className="copy-text-wrapper">
              <p id="copyUrl" className="copy-text">
                {props.url}
              </p>
              <FontAwesomeIcon icon={faClone} className="copy-icon" onClick={handleCopyUrl} />
            </div>
          </Modal.Body>
          <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
            <MuiAlert elevation={6} variant="filled" severity="success" {...props}>
              Copied to clipboard.
            </MuiAlert>
          </Snackbar>
        </div>
      }
    </Modal>
  );
};

export default SocialShareModal;
