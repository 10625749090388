import React from 'react';
import { Link } from 'react-router-dom';
import kooledgeLogo from '../../images/kooledge-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons';

import './Footer.scss';

const Footer = () => {
  return (
    <div className="footer">
      <div className="my-container footer-container">
        <div className="footer-div-1">
          <Link to="/">
            <img className="navbar-logo" src={kooledgeLogo} alt="logo" />
          </Link>
          <Link to="" className="about-kooledge-link">
            About Kooledge
          </Link>
        </div>
        <div className="footer-div-2">
          <p className="footer-copyright">Copyright © Kooledge 2020. All Rights Reserved</p>
          <div className="footer-socials-wrapper">
            <FontAwesomeIcon icon={faTwitter} className="footer-socials-icon" />
            <FontAwesomeIcon icon={faInstagram} className="footer-socials-icon middle" />
            <FontAwesomeIcon icon={faFacebookF} className="footer-socials-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
