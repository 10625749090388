import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import {
  verifyTopicCertificate,
  verifyFlowCertificate,
  clearCertificateState,
} from '../../../actions/certificateAction';

import MyCertificate from '../../MyCertificate/MyCertificate';

import './VerifyCertificate.scss';

const VerifyCertificate = () => {
  const certificateState = useSelector((state) => state.certificate);

  const dispatch = useDispatch();

  const match = useRouteMatch();

  useEffect(() => {
    if (match.params.type === 'topic') {
      dispatch(verifyTopicCertificate(match.params.uuid));
    } else {
      dispatch(verifyFlowCertificate(match.params.uuid));
    }

    return () => {
      dispatch(clearCertificateState());
    };
  }, []);

  return (
    <div className="my-container verify-certificate-page-container">
      <div className="verify-certificate-page">
        {!certificateState.errorMessage &&
          (certificateState.certificate ? (
            <div className="verified-certificate-wrapper">
              <MyCertificate
                type={match.params.type}
                certificate={certificateState.certificate}
                verified={true}
              />
            </div>
          ) : (
            <h2>Please wait...</h2>
          ))}
        {certificateState.errorMessage && <h2>Certificate not found.</h2>}
      </div>
    </div>
  );
};

export default VerifyCertificate;
