import { TOGGLE_PROFILE_MENU, TOGGLE_SIDE_MENU } from './actionTypes';

export const toggleProfileMenu = () => (dispatch) => {
  dispatch({
    type: TOGGLE_PROFILE_MENU,
  });
};

export const toggleSideMenu = () => (dispatch) => {
  dispatch({
    type: TOGGLE_SIDE_MENU,
  });
};
