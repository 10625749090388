import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactPlayer from 'react-player/lazy';
import { Link } from 'react-scroll';
import blankThumbnail from '../../images/blank-thumbnail.png';
import replayIcon from '../../images/replay-icon-black.png';
import quizIcon from '../../images/quiz-icon-black.png';
import topicIcon from '../../images/book-icon-black.png';

import { viewVideo } from '../../actions/videoAction';
import { formatSeconds } from '../../actions/helper';

import PostVideoOption from '../PostVideoOption/PostVideoOption';

import './ResponsivePlayer.scss';

const ResponsivePlayer = (props) => {
  const [playerState, setPlayerState] = useState(props.url ? 'video' : '');
  const [nextTopicUuid, setNextTopicUuid] = useState('');
  const [error, setError] = useState(false);

  const flowState = useSelector((state) => state.flow);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.topic) {
      const nextTopicIndex =
        flowState.topics.findIndex((topic) => topic.uuid === props.topic.uuid) + 1;

      if (typeof flowState.topics[nextTopicIndex] !== 'undefined') {
        setNextTopicUuid(flowState.topics[nextTopicIndex].uuid);
      } else {
        setNextTopicUuid('');
      }
    }
  }, []);

  useEffect(() => {
    props.url ? setPlayerState('video') : setPlayerState('');
  }, [props.url]);

  const handleOnStart = () => {
    if (!props.edit) {
      dispatch(viewVideo({ topic_uuid: props.topic.uuid }, props.currentVideo.uuid));
    }
  };

  const handleOnProgress = (prog) => {
    if (!props.edit) {
      var ret = formatSeconds(prog.playedSeconds);
      props.setPlayerProgress(ret);
    }
  };

  const handleOnPlay = () => {
    setError(false);
    if (!props.edit) {
      props.setPlayerPlaying(true);
    }
  };

  const handleOnError = (e) => {
    if (e) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleOnEnded = () => {
    setPlayerState('post-video');
  };

  const handleOnReplay = () => {
    props.url ? setPlayerState('video') : setPlayerState('');
  };
  const handleTakeQuiz = () => {
    props.setQuizWindowModalShow(true);
  };

  const playerRenderSwitch = (param) => {
    switch (param) {
      case 'video':
        return (
          <ReactPlayer
            ref={props.playerRef}
            className="react-player"
            url={props.url}
            width="100%"
            height="100%"
            controls={true}
            onStart={handleOnStart}
            onPlay={handleOnPlay}
            onProgress={handleOnProgress}
            onError={handleOnError}
            onEnded={handleOnEnded}
            playing={props.playerPlaying}
            config={{
              vimeo: {
                playerOptions: {
                  color: '30beee',
                },
              },
            }}
          />
        );
      case 'post-video':
        return (
          <div className="post-video-options-wrapper">
            <PostVideoOption
              icon={<img className="option-icon" src={replayIcon} alt="replay-icon" />}
              optionText="Replay"
              handleOnClick={handleOnReplay}
            />
            {!props.edit && props.topic.official_quiz && (
              <PostVideoOption
                icon={<img className="option-icon" src={quizIcon} alt="quiz-icon" />}
                optionText="Take Quiz"
                handleOnClick={handleTakeQuiz}
              />
            )}
            {!props.edit && nextTopicUuid !== '' && (
              <Link
                to={nextTopicUuid}
                spy={true}
                smooth={true}
                duration={500}
                offset={-800}
                onClick={() => props.setExpandCard(false)}
              >
                <PostVideoOption
                  icon={<img className="option-icon" src={topicIcon} alt="topic-icon" />}
                  optionText="Next Topic"
                />
              </Link>
            )}
          </div>
        );
      case 'error':
        return (
          <p className="error-message">
            This video is currently being processed by Vimeo. It will be available in a few minutes.
          </p>
        );
      default:
        return (
          <div
            className="blank-player"
            style={{
              backgroundImage: `url(${blankThumbnail})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <p className="no-videos-text">No featured video</p>
          </div>
        );
    }
  };

  return (
    <div className="player-wrapper">
      {playerRenderSwitch(playerState)}
      {error && (
        <p className="player-error">
          This video is currently being transcoded by Vimeo and will be available in a few minutes.
        </p>
      )}
    </div>
  );
};

export default ResponsivePlayer;
