import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import blankImage from '../../images/blank-image.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { TextField, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { withStyles } from '@material-ui/core/styles';

import { updateUserInfo, setProfileDashboardLoading } from '../../actions/profileAction';

import { COUNTRIES } from '../../actions/constants';

import UpdatePassword from '../UpdatePassword/UpdatePassword';

import './ProfileSettings.scss';

const styles = (theme) => ({
  icon: {
    fill: '#ABABAB',
    fontSize: '2rem',
    marginRight: '13px',
  },
});

const ProfileSettings = ({ classes }) => {
  const profileState = useSelector((state) => state.profile);

  const [avatar, setAvatar] = useState(
    profileState.profile.avatar ? profileState.profile.avatar : '',
  );
  const [avatarImage, setAvatarImage] = useState(
    profileState.avatar ? profileState.avatar : blankImage,
  );
  const [firstName, setFirstName] = useState(profileState.firstName ? profileState.firstName : '');
  const [lastName, setLastName] = useState(profileState.lastName ? profileState.lastName : '');
  const [gender, setGender] = useState(
    profileState.profile.gender ? profileState.profile.gender : '',
  );
  const [country, setCountry] = useState(
    profileState.profile.country ? profileState.profile.country : '',
  );
  const [birthday, setBirthday] = useState(
    profileState.profile.birthday
      ? format(new Date(profileState.profile.birthday), 'yyyy-MM-dd')
      : '',
  );
  const [updatePasswordShow, setUpdatePasswordShow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (birthday) {
      document.getElementById('dateLabel').classList.add('MuiInputLabel-shrink');
    }
  }, [birthday]);

  const triggerAvatarInput = () => {
    document.querySelector('.avatar-input').click();
  };

  const handleAvatarUpload = (e) => {
    if (e.target.files[0]) {
      setAvatarImage(URL.createObjectURL(e.target.files[0]));
      setAvatar(e.target.files[0]);
    }
  };

  const handleRemoveAvatar = () => {
    setAvatarImage('');
    setAvatar('');
  };

  const handleDateButtonClick = () => {
    document.getElementById('dateLabel').classList.add('MuiInputLabel-shrink');
    document
      .querySelector('.hidden-date-picker .MuiInputAdornment-root .MuiIconButton-root')
      .click();
  };

  const handleDateInputClick = () => {
    document.getElementById('dateLabel').classList.add('MuiInputLabel-shrink');
    document
      .querySelector('.hidden-date-picker .MuiInputAdornment-root .MuiIconButton-root')
      .click();
  };

  const handleOnSave = (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (avatar !== null && (typeof avatar == 'object' || avatar === '')) {
      formData.append('avatar', avatar);
    }
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('gender', gender);
    formData.append('country', country);
    formData.append('birthday', format(new Date(birthday), 'yyyy-MM-dd'));

    dispatch(setProfileDashboardLoading());
    dispatch(updateUserInfo(profileState.uuid, formData));
  };

  return (
    <>
      <div className="profile-settings-wrapper">
        <form className="profile-settings-grid" id="profileSettingsForm" onSubmit={handleOnSave}>
          <div className="avatar-input-wrapper">
            <div className="avatar-image-wrapper">
              {avatar ? (
                <div
                  className="avatar-image"
                  style={{
                    backgroundImage: `url(${avatarImage})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <div className="avatar-image-overlay" onClick={handleRemoveAvatar}>
                    <FontAwesomeIcon icon={faTimes} className="delete-avatar-icon" />
                  </div>
                </div>
              ) : (
                <div className="avatar-image-none">
                  <FontAwesomeIcon icon={faUser} className="blank-avatar-icon" />
                </div>
              )}
            </div>
            <div className="avatar-input-text">
              <p className="set-avatar">Profile Picture</p>
              <div className="avatar-input-wrapper" onClick={triggerAvatarInput}>
                Upload
                <input
                  className="avatar-input"
                  name="avatar-input"
                  type="file"
                  accept="image/jpg, image/jpeg, image/png"
                  style={{ display: 'none' }}
                  onChange={handleAvatarUpload}
                />
              </div>
            </div>
          </div>
          <TextField
            className="auth-card-input"
            label="First Name"
            variant="filled"
            required
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            className="auth-card-input"
            label="Last Name"
            variant="filled"
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="MuiFormControl-root MuiTextField-root auth-card-input">
              <label
                id="dateLabel"
                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-filled"
                data-shrink="fals"
              >
                Date Of Birth
              </label>
              <div className="MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-formControl MuiInputBase-adornedEnd MuiFilledInput-adornedEnd">
                <input
                  id="birthdayPicker"
                  aria-invalid="false"
                  type="text"
                  className="MuiInputBase-input MuiFilledInput-input MuiInputBase-inputAdornedEnd"
                  value={birthday ? format(new Date(birthday), 'yyyy-MM-dd') : ''}
                  onClick={handleDateInputClick}
                />
                <div className="MuiInputAdornment-root MuiInputAdornment-positionEnd">
                  <button
                    className="MuiButtonBase-root MuiIconButton-root"
                    tabIndex="0"
                    type="button"
                    aria-label="change date"
                    onClick={handleDateButtonClick}
                  >
                    <span className="MuiIconButton-label">
                      <svg
                        className="MuiSvgIcon-root"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"></path>
                        <path fill="none" d="M0 0h24v24H0z"></path>
                      </svg>
                    </span>
                    <span className="MuiTouchRipple-root"></span>
                  </button>
                </div>
              </div>
            </div>
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className="hidden-date-picker"
              label="Date Of Birth"
              format="yyyy-mm-dd"
              value={birthday}
              onChange={setBirthday}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              style={{
                display: 'none',
              }}
              maxDate={
                new Date(
                  `${new Date().getFullYear() - 18}-${new Date().getMonth() + 1 < 10 ? '0' : ''}${
                    new Date().getMonth() + 1
                  }-${new Date().getDate()}`,
                )
              }
            />
          </MuiPickersUtilsProvider>
          <FormControl variant="filled" className="auth-card-input">
            <InputLabel>Gender</InputLabel>
            <Select
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              defaultValue={gender}
              IconComponent={KeyboardArrowDownIcon}
              onChange={(e) => setGender(e.target.value)}
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="non_binary">Non Binary</MenuItem>
              <MenuItem value="prefer_not_to_say">Prefer Not To Say</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="filled" className="auth-card-input">
            <InputLabel>Country</InputLabel>
            <Select
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              defaultValue={country}
              IconComponent={KeyboardArrowDownIcon}
              onChange={(e) => setCountry(e.target.value)}
            >
              <MenuItem value=""></MenuItem>
              {COUNTRIES.map((country) => (
                <MenuItem key={country.key} value={country.value}>
                  {country.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
        <h3 className="change-password-button" onClick={() => setUpdatePasswordShow(true)}>
          Change Password
        </h3>
        <button className="save-button" type="submit" form="profileSettingsForm">
          Save
        </button>
        <UpdatePassword show={updatePasswordShow} onHide={() => setUpdatePasswordShow(false)} />
      </div>
    </>
  );
};

export default withStyles(styles)(ProfileSettings);
