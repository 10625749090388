export const ENV_DEBUG = false;

export let url;
if (ENV_DEBUG) {
    url = 'http://127.0.0.1:8000/api/v1';
} else {
    url = 'https://kooledge-be.herokuapp.com/api/v1'
}

export const MAXIMUM_TAGS = 5;
export const MAXIMUM_SELECTED_CONTENT = 5;
export const MAXIMUM_TITLE_LENGTH = 50;
export const MAXIMUM_DESCRIPTION_LENGTH = 250;
export const MAXIMUM_QUIZ_QUESTION_LENGTH = 400;
export const MAXIMUM_QUIZ_ANSWER_LENGTH = 200;
export const MAXIMUM_TAG_LENGTH = 20;
export const MINIMUM_PASSWORD_LENGTH = 8;
export const MINIMUM_COMMENT_LENGTH = 1;
export const GET_COMMENTS_LIMIT = 12;
export const GET_FLOWS_LIMIT = 12;
export const SOCIAL_ICON_SIZE = 50;

export const HOME_DROPDOWN_ITEMS = [
    {
        value: 'all-flows',
        name: 'All Flows',
    },
    {
        value: 'most-recent',
        name: 'Most Recent',
    },
    {
        value: 'most-views',
        name: 'Most Views',
    },
    {
        value: 'most-likes',
        name: 'Most Likes',
    },
];

export const COUNTRIES = [
    {key: 'AF', value: 'Afghanistan'},
    {key: 'AX', value: 'Aland Islands'},
    {key: 'AL', value: 'Albania'},
    {key: 'DZ', value: 'Algeria'},
    {key: 'AS', value: 'American Samoa'},
    {key: 'AD', value: 'Andorra'},
    {key: 'AO', value: 'Angola'},
    {key: 'AI', value: 'Anguilla'},
    {key: 'AQ', value: 'Antarctica'},
    {key: 'AG', value: 'Antigua And Barbuda'},
    {key: 'AR', value: 'Argentina'},
    {key: 'AM', value: 'Armenia'},
    {key: 'AW', value: 'Aruba'},
    {key: 'AU', value: 'Australia'},
    {key: 'AT', value: 'Austria'},
    {key: 'AZ', value: 'Azerbaijan'},
    {key: 'BS', value: 'Bahamas'},
    {key: 'BH', value: 'Bahrain'},
    {key: 'BD', value: 'Bangladesh'},
    {key: 'BB', value: 'Barbados'},
    {key: 'BY', value: 'Belarus'},
    {key: 'BE', value: 'Belgium'},
    {key: 'BZ', value: 'Belize'},
    {key: 'BJ', value: 'Benin'},
    {key: 'BM', value: 'Bermuda'},
    {key: 'BT', value: 'Bhutan'},
    {key: 'BO', value: 'Bolivia'},
    {key: 'BA', value: 'Bosnia And Herzegovina'},
    {key: 'BW', value: 'Botswana'},
    {key: 'BV', value: 'Bouvet Island'},
    {key: 'BR', value: 'Brazil'},
    {key: 'IO', value: 'British Indian Ocean Territory'},
    {key: 'BN', value: 'Brunei Darussalam'},
    {key: 'BG', value: 'Bulgaria'},
    {key: 'BF', value: 'Burkina Faso'},
    {key: 'BI', value: 'Burundi'},
    {key: 'KH', value: 'Cambodia'},
    {key: 'CM', value: 'Cameroon'},
    {key: 'CA', value: 'Canada'},
    {key: 'CV', value: 'Cape Verde'},
    {key: 'KY', value: 'Cayman Islands'},
    {key: 'CF', value: 'Central African Republic'},
    {key: 'TD', value: 'Chad'},
    {key: 'CL', value: 'Chile'},
    {key: 'CN', value: 'China'},
    {key: 'CX', value: 'Christmas Island'},
    {key: 'CC', value: 'Cocos (Keeling) Islands'},
    {key: 'CO', value: 'Colombia'},
    {key: 'KM', value: 'Comoros'},
    {key: 'CG', value: 'Congo'},
    {key: 'CD', value: 'Congo, Democratic Republic'},
    {key: 'CK', value: 'Cook Islands'},
    {key: 'CR', value: 'Costa Rica'},
    {key: 'CI', value: "Cote D'Ivoire"},
    {key: 'HR', value: 'Croatia'},
    {key: 'CU', value: 'Cuba'},
    {key: 'CY', value: 'Cyprus'},
    {key: 'CZ', value: 'Czech Republic'},
    {key: 'DK', value: 'Denmark'},
    {key: 'DJ', value: 'Djibouti'},
    {key: 'DM', value: 'Dominica'},
    {key: 'DO', value: 'Dominican Republic'},
    {key: 'EC', value: 'Ecuador'},
    {key: 'EG', value: 'Egypt'},
    {key: 'SV', value: 'El Salvador'},
    {key: 'GQ', value: 'Equatorial Guinea'},
    {key: 'ER', value: 'Eritrea'},
    {key: 'EE', value: 'Estonia'},
    {key: 'ET', value: 'Ethiopia'},
    {key: 'FK', value: 'Falkland Islands (Malvinas)'},
    {key: 'FO', value: 'Faroe Islands'},
    {key: 'FJ', value: 'Fiji'},
    {key: 'FI', value: 'Finland'},
    {key: 'FR', value: 'France'},
    {key: 'GF', value: 'French Guiana'},
    {key: 'PF', value: 'French Polynesia'},
    {key: 'TF', value: 'French Southern Territories'},
    {key: 'GA', value: 'Gabon'},
    {key: 'GM', value: 'Gambia'},
    {key: 'GE', value: 'Georgia'},
    {key: 'DE', value: 'Germany'},
    {key: 'GH', value: 'Ghana'},
    {key: 'GI', value: 'Gibraltar'},
    {key: 'GR', value: 'Greece'},
    {key: 'GL', value: 'Greenland'},
    {key: 'GD', value: 'Grenada'},
    {key: 'GP', value: 'Guadeloupe'},
    {key: 'GU', value: 'Guam'},
    {key: 'GT', value: 'Guatemala'},
    {key: 'GG', value: 'Guernsey'},
    {key: 'GN', value: 'Guinea'},
    {key: 'GW', value: 'Guinea-Bissau'},
    {key: 'GY', value: 'Guyana'},
    {key: 'HT', value: 'Haiti'},
    {key: 'HM', value: 'Heard Island & Mcdonald Islands'},
    {key: 'VA', value: 'Holy See (Vatican City State)'},
    {key: 'HN', value: 'Honduras'},
    {key: 'HK', value: 'Hong Kong'},
    {key: 'HU', value: 'Hungary'},
    {key: 'IS', value: 'Iceland'},
    {key: 'IN', value: 'India'},
    {key: 'ID', value: 'Indonesia'},
    {key: 'IR', value: 'Iran'},
    {key: 'IQ', value: 'Iraq'},
    {key: 'IE', value: 'Ireland'},
    {key: 'IM', value: 'Isle Of Man'},
    {key: 'IL', value: 'Israel'},
    {key: 'IT', value: 'Italy'},
    {key: 'JM', value: 'Jamaica'},
    {key: 'JP', value: 'Japan'},
    {key: 'JE', value: 'Jersey'},
    {key: 'JO', value: 'Jordan'},
    {key: 'KZ', value: 'Kazakhstan'},
    {key: 'KE', value: 'Kenya'},
    {key: 'KI', value: 'Kiribati'},
    {key: 'KR', value: 'Korea'},
    {key: 'KW', value: 'Kuwait'},
    {key: 'KG', value: 'Kyrgyzstan'},
    {key: 'LA', value: "Lao People's Democratic Republic"},
    {key: 'LV', value: 'Latvia'},
    {key: 'LB', value: 'Lebanon'},
    {key: 'LS', value: 'Lesotho'},
    {key: 'LR', value: 'Liberia'},
    {key: 'LY', value: 'Libyan Arab Jamahiriya'},
    {key: 'LI', value: 'Liechtenstein'},
    {key: 'LT', value: 'Lithuania'},
    {key: 'LU', value: 'Luxembourg'},
    {key: 'MO', value: 'Macao'},
    {key: 'MK', value: 'Macedonia'},
    {key: 'MG', value: 'Madagascar'},
    {key: 'MW', value: 'Malawi'},
    {key: 'MY', value: 'Malaysia'},
    {key: 'MV', value: 'Maldives'},
    {key: 'ML', value: 'Mali'},
    {key: 'MT', value: 'Malta'},
    {key: 'MH', value: 'Marshall Islands'},
    {key: 'MQ', value: 'Martinique'},
    {key: 'MR', value: 'Mauritania'},
    {key: 'MU', value: 'Mauritius'},
    {key: 'YT', value: 'Mayotte'},
    {key: 'MX', value: 'Mexico'},
    {key: 'FM', value: 'Micronesia'},
    {key: 'MD', value: 'Moldova'},
    {key: 'MC', value: 'Monaco'},
    {key: 'MN', value: 'Mongolia'},
    {key: 'ME', value: 'Montenegro'},
    {key: 'MS', value: 'Montserrat'},
    {key: 'MA', value: 'Morocco'},
    {key: 'MZ', value: 'Mozambique'},
    {key: 'MM', value: 'Myanmar'},
    {key: 'NA', value: 'Namibia'},
    {key: 'NR', value: 'Nauru'},
    {key: 'NP', value: 'Nepal'},
    {key: 'NL', value: 'Netherlands'},
    {key: 'AN', value: 'Netherlands Antilles'},
    {key: 'NC', value: 'New Caledonia'},
    {key: 'NZ', value: 'New Zealand'},
    {key: 'NI', value: 'Nicaragua'},
    {key: 'NE', value: 'Niger'},
    {key: 'NG', value: 'Nigeria'},
    {key: 'NU', value: 'Niue'},
    {key: 'NF', value: 'Norfolk Island'},
    {key: 'MP', value: 'Northern Mariana Islands'},
    {key: 'NO', value: 'Norway'},
    {key: 'OM', value: 'Oman'},
    {key: 'PK', value: 'Pakistan'},
    {key: 'PW', value: 'Palau'},
    {key: 'PS', value: 'Palestinian Territory, Occupied'},
    {key: 'PA', value: 'Panama'},
    {key: 'PG', value: 'Papua New Guinea'},
    {key: 'PY', value: 'Paraguay'},
    {key: 'PE', value: 'Peru'},
    {key: 'PH', value: 'Philippines'},
    {key: 'PN', value: 'Pitcairn'},
    {key: 'PL', value: 'Poland'},
    {key: 'PT', value: 'Portugal'},
    {key: 'PR', value: 'Puerto Rico'},
    {key: 'QA', value: 'Qatar'},
    {key: 'RE', value: 'Reunion'},
    {key: 'RO', value: 'Romania'},
    {key: 'RU', value: 'Russian Federation'},
    {key: 'RW', value: 'Rwanda'},
    {key: 'BL', value: 'Saint Barthelemy'},
    {key: 'SH', value: 'Saint Helena'},
    {key: 'KN', value: 'Saint Kitts And Nevis'},
    {key: 'LC', value: 'Saint Lucia'},
    {key: 'MF', value: 'Saint Martin'},
    {key: 'PM', value: 'Saint Pierre And Miquelon'},
    {key: 'VC', value: 'Saint Vincent And Grenadines'},
    {key: 'WS', value: 'Samoa'},
    {key: 'SM', value: 'San Marino'},
    {key: 'ST', value: 'Sao Tome And Principe'},
    {key: 'SA', value: 'Saudi Arabia'},
    {key: 'SN', value: 'Senegal'},
    {key: 'RS', value: 'Serbia'},
    {key: 'SC', value: 'Seychelles'},
    {key: 'SL', value: 'Sierra Leone'},
    {key: 'SG', value: 'Singapore'},
    {key: 'SK', value: 'Slovakia'},
    {key: 'SI', value: 'Slovenia'},
    {key: 'SB', value: 'Solomon Islands'},
    {key: 'SO', value: 'Somalia'},
    {key: 'ZA', value: 'South Africa'},
    {key: 'GS', value: 'South Georgia And Sandwich Isl.'},
    {key: 'ES', value: 'Spain'},
    {key: 'LK', value: 'Sri Lanka'},
    {key: 'SD', value: 'Sudan'},
    {key: 'SR', value: 'Suriname'},
    {key: 'SJ', value: 'Svalbard And Jan Mayen'},
    {key: 'SZ', value: 'Swaziland'},
    {key: 'SE', value: 'Sweden'},
    {key: 'CH', value: 'Switzerland'},
    {key: 'SY', value: 'Syrian Arab Republic'},
    {key: 'TW', value: 'Taiwan'},
    {key: 'TJ', value: 'Tajikistan'},
    {key: 'TZ', value: 'Tanzania'},
    {key: 'TH', value: 'Thailand'},
    {key: 'TL', value: 'Timor-Leste'},
    {key: 'TG', value: 'Togo'},
    {key: 'TK', value: 'Tokelau'},
    {key: 'TO', value: 'Tonga'},
    {key: 'TT', value: 'Trinidad And Tobago'},
    {key: 'TN', value: 'Tunisia'},
    {key: 'TR', value: 'Turkey'},
    {key: 'TM', value: 'Turkmenistan'},
    {key: 'TC', value: 'Turks And Caicos Islands'},
    {key: 'TV', value: 'Tuvalu'},
    {key: 'UG', value: 'Uganda'},
    {key: 'UA', value: 'Ukraine'},
    {key: 'AE', value: 'United Arab Emirates'},
    {key: 'GB', value: 'United Kingdom'},
    {key: 'US', value: 'United States'},
    {key: 'UM', value: 'United States Outlying Islands'},
    {key: 'UY', value: 'Uruguay'},
    {key: 'UZ', value: 'Uzbekistan'},
    {key: 'VU', value: 'Vanuatu'},
    {key: 'VE', value: 'Venezuela'},
    {key: 'VN', value: 'Viet Nam'},
    {key: 'VG', value: 'Virgin Islands, British'},
    {key: 'VI', value: 'Virgin Islands, U.S.'},
    {key: 'WF', value: 'Wallis And Futuna'},
    {key: 'EH', value: 'Western Sahara'},
    {key: 'YE', value: 'Yemen'},
    {key: 'ZM', value: 'Zambia'},
    {key: 'ZW', value: 'Zimbabwe'},
];

export const MINUTES = [
    {value: 0, name: 0},
    {value: 1, name: 1},
    {value: 2, name: 2},
    {value: 3, name: 3},
    {value: 4, name: 4},
    {value: 5, name: 5},
    {value: 6, name: 6},
    {value: 7, name: 7},
    {value: 8, name: 8},
    {value: 9, name: 9},
    {value: 10, name: 10},
    {value: 11, name: 11},
    {value: 12, name: 12},
    {value: 13, name: 13},
    {value: 14, name: 14},
    {value: 15, name: 15},
    {value: 16, name: 16},
    {value: 17, name: 17},
    {value: 18, name: 18},
    {value: 19, name: 19},
    {value: 20, name: 20},
    {value: 21, name: 21},
    {value: 22, name: 22},
    {value: 23, name: 23},
    {value: 24, name: 24},
    {value: 25, name: 25},
    {value: 26, name: 26},
    {value: 27, name: 27},
    {value: 28, name: 28},
    {value: 29, name: 29},
    {value: 30, name: 30},
    {value: 31, name: 31},
    {value: 32, name: 32},
    {value: 33, name: 33},
    {value: 34, name: 34},
    {value: 35, name: 35},
    {value: 36, name: 36},
    {value: 37, name: 37},
    {value: 38, name: 38},
    {value: 39, name: 39},
    {value: 40, name: 40},
    {value: 41, name: 41},
    {value: 42, name: 42},
    {value: 43, name: 43},
    {value: 44, name: 44},
    {value: 45, name: 45},
    {value: 46, name: 46},
    {value: 47, name: 47},
    {value: 48, name: 48},
    {value: 49, name: 49},
    {value: 50, name: 50},
    {value: 51, name: 51},
    {value: 52, name: 52},
    {value: 53, name: 53},
    {value: 54, name: 54},
    {value: 55, name: 55},
    {value: 56, name: 56},
    {value: 57, name: 57},
    {value: 58, name: 58},
    {value: 59, name: 59},
    {value: 60, name: 60},
];

export const SECONDS = [
    {value: 0, name: 0},
    {value: 10, name: 10},
    {value: 20, name: 20},
    {value: 30, name: 30},
    {value: 40, name: 40},
    {value: 50, name: 50},
];

export const PERCENTAGES = [
    {value: 1, name: 1},
    {value: 2, name: 2},
    {value: 3, name: 3},
    {value: 4, name: 4},
    {value: 5, name: 5},
    {value: 6, name: 6},
    {value: 7, name: 7},
    {value: 8, name: 8},
    {value: 9, name: 9},
    {value: 10, name: 10},
    {value: 11, name: 11},
    {value: 12, name: 12},
    {value: 13, name: 13},
    {value: 14, name: 14},
    {value: 15, name: 15},
    {value: 16, name: 16},
    {value: 17, name: 17},
    {value: 18, name: 18},
    {value: 19, name: 19},
    {value: 20, name: 20},
    {value: 21, name: 21},
    {value: 22, name: 22},
    {value: 23, name: 23},
    {value: 24, name: 24},
    {value: 25, name: 25},
    {value: 26, name: 26},
    {value: 27, name: 27},
    {value: 28, name: 28},
    {value: 29, name: 29},
    {value: 30, name: 30},
    {value: 31, name: 31},
    {value: 32, name: 32},
    {value: 33, name: 33},
    {value: 34, name: 34},
    {value: 35, name: 35},
    {value: 36, name: 36},
    {value: 37, name: 37},
    {value: 38, name: 38},
    {value: 39, name: 39},
    {value: 40, name: 40},
    {value: 41, name: 41},
    {value: 42, name: 42},
    {value: 43, name: 43},
    {value: 44, name: 44},
    {value: 45, name: 45},
    {value: 46, name: 46},
    {value: 47, name: 47},
    {value: 48, name: 48},
    {value: 49, name: 49},
    {value: 50, name: 50},
    {value: 51, name: 51},
    {value: 52, name: 52},
    {value: 53, name: 53},
    {value: 54, name: 54},
    {value: 55, name: 55},
    {value: 56, name: 56},
    {value: 57, name: 57},
    {value: 58, name: 58},
    {value: 59, name: 59},
    {value: 60, name: 60},
    {value: 61, name: 61},
    {value: 62, name: 62},
    {value: 63, name: 63},
    {value: 64, name: 64},
    {value: 65, name: 65},
    {value: 66, name: 66},
    {value: 67, name: 67},
    {value: 68, name: 68},
    {value: 69, name: 69},
    {value: 70, name: 70},
    {value: 71, name: 71},
    {value: 72, name: 72},
    {value: 73, name: 73},
    {value: 74, name: 74},
    {value: 75, name: 75},
    {value: 76, name: 76},
    {value: 77, name: 77},
    {value: 78, name: 78},
    {value: 79, name: 79},
    {value: 80, name: 80},
    {value: 81, name: 81},
    {value: 82, name: 82},
    {value: 83, name: 83},
    {value: 84, name: 84},
    {value: 85, name: 85},
    {value: 86, name: 86},
    {value: 87, name: 87},
    {value: 88, name: 88},
    {value: 89, name: 89},
    {value: 90, name: 90},
    {value: 91, name: 91},
    {value: 92, name: 92},
    {value: 93, name: 93},
    {value: 94, name: 94},
    {value: 95, name: 95},
    {value: 96, name: 96},
    {value: 97, name: 97},
    {value: 98, name: 98},
    {value: 99, name: 99},
    {value: 100, name: 100},
];
