import axios from 'axios';
import axiosApiInstance from './axios';

import { url } from './constants';

import {
  CREATE_FLOW,
  CREATE_FLOW_ERROR,
  GET_FLOW,
  GET_FLOW_ERROR,
  GET_ALL_FLOWS,
  GET_ALL_FLOWS_ERROR,
  GET_FEATURED_FLOWS,
  GET_FEATURED_FLOWS_ERROR,
  APPEND_FLOWS,
  APPEND_FLOWS_ERROR,
  SEARCH_FLOWS,
  SEARCH_FLOWS_ERROR,
  CLEAR_SEARCHED_FLOWS,
  CLEAR_ALL_FLOWS,
  EDIT_FLOW,
  EDIT_FLOW_ERROR,
  DELETE_FLOW,
  DELETE_FLOW_ERROR,
  CLEAR_FLOW_STATE,
  SEARCH_FLOW_LOADING,
  FLOW_LOADING,
  CLEAR_LOADING,
  LOAD_SEARCH_RESULTS,
  INCREMENT_SEARCH_FLOWS_API_COUNT,
} from './actionTypes';

export const createFlow = (formData) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.post(`${url}/flow/flow/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    dispatch({
      type: CREATE_FLOW,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_FLOW_ERROR,
      payload: err.response,
    });
  }
};

export const getFlow = (uuid) => async (dispatch) => {
  try {
    if (localStorage.getItem('accessToken')) {
      var res = await axiosApiInstance.get(`${url}/flow/get_flows/${uuid}/`);
    } else {
      var res = await axios.get(`${url}/flow/get_flows/${uuid}/`);
    }

    dispatch({
      type: GET_FLOW,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_FLOW_ERROR,
      payload: err.response,
    });
  }
};

export const getAllFlows = (limit, offset) => async (dispatch) => {
  try {
    if (localStorage.getItem('accessToken')) {
      var res = await axiosApiInstance.get(
        `${url}/flow/get_flows/?limit=${limit}&offset=${offset}`,
      );
    } else {
      var res = await axios.get(`${url}/flow/get_flows/?limit=${limit}&offset=${offset}`);
    }

    dispatch({
      type: GET_ALL_FLOWS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_FLOWS_ERROR,
      payload: err.response,
    });
  }
};

export const getFeaturedFlows = (limit, offset) => async (dispatch) => {
  try {
    var res = await axios.get(`${url}/flow/featured_flows/`);

    dispatch({
      type: GET_FEATURED_FLOWS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_FEATURED_FLOWS_ERROR,
      payload: err.response,
    });
  }
};

export const appendFlows = (limit, offset) => async (dispatch) => {
  try {
    if (localStorage.getItem('accessToken')) {
      var res = await axiosApiInstance.get(
        `${url}/flow/get_flows/?limit=${limit}&offset=${offset}`,
      );
    } else {
      var res = await axios.get(`${url}/flow/get_flows/?limit=${limit}&offset=${offset}`);
    }

    dispatch({
      type: APPEND_FLOWS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: APPEND_FLOWS_ERROR,
      payload: err.response,
    });
  }
};

export const searchFlows = (title) => async (dispatch) => {
  try {
    if (localStorage.getItem('accessToken')) {
      var res = await axiosApiInstance.get(`${url}/flow/get_flows/?search=${title}`);
    } else {
      var res = await axios.get(`${url}/flow/get_flows/?search=${title}`);
    }

    dispatch({
      type: SEARCH_FLOWS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SEARCH_FLOWS_ERROR,
      payload: err.response,
    });
  }
};

export const clearSearchedFlows = () => (dispatch) => {
  dispatch({
    type: CLEAR_SEARCHED_FLOWS,
  });
};

export const clearAllFlows = () => (dispatch) => {
  dispatch({
    type: CLEAR_ALL_FLOWS,
  });
};

export const editFlow = (uuid, formData) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.patch(`${url}/flow/flow/${uuid}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
    });

    dispatch({
      type: EDIT_FLOW,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_FLOW_ERROR,
      payload: err.response,
    });
  }
};

export const deleteFlow = (uuid) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.delete(`${url}/flow/flow/${uuid}/`);

    dispatch({
      type: DELETE_FLOW,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DELETE_FLOW_ERROR,
      payload: err.response,
    });
  }
};

export const setSearchFlowLoading = () => (dispatch) => {
  dispatch({
    type: SEARCH_FLOW_LOADING,
  });
};

export const setFlowLoading = () => (dispatch) => {
  dispatch({
    type: FLOW_LOADING,
  });
};

export const clearLoading = () => (dispatch) => {
  dispatch({
    type: CLEAR_LOADING,
  });
};

export const clearFlowState = () => (dispatch) => {
  dispatch({
    type: CLEAR_FLOW_STATE,
  });
};

export const setLoadSearchResults = (set) => (dispatch) => {
  dispatch({
    type: LOAD_SEARCH_RESULTS,
    payload: set,
  });
};

export const incrementSearchFlowsAPICount = () => (dispatch) => {
  dispatch({
    type: INCREMENT_SEARCH_FLOWS_API_COUNT,
  });
};
