import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { format } from 'date-fns';
import TimeAgo from 'react-timeago';
import classNames from 'classnames';
import blankImage from '../../images/blank-image.png';

import { getComments, deleteComment } from '../../actions/commentAction';
import { GET_COMMENTS_LIMIT } from '../../actions/constants';

import CustomModal from '../CustomModal/CustomModal';

import '../Comment/Comment.scss';

const Reply = (props) => {
  const [disabled, setDisabled] = useState(false);
  const [customModalShow, setCustomModalShow] = useState(false);

  const dispatch = useDispatch();

  const match = useRouteMatch();

  const handleDeleteComment = async () => {
    setDisabled(true);

    await dispatch(deleteComment(props.reply.uuid, props.video.uuid, props.topicUuid));

    var res = await getComments(props.video.uuid, props.topicUuid, GET_COMMENTS_LIMIT, 0);

    if (res) {
      props.setCurrentVideoComments(res.data.results);
      props.setCurrentVideoNextComment(res.data.next);
    }

    setDisabled(false);
  };

  const nameClass = classNames('name', { 'owner-name': props.reply.is_creator });

  return (
    <>
      <div className="comment reply" style={{ opacity: disabled ? '0.3' : '1' }}>
        <div className="user-info">
          <img className="avatar" src={props.reply.avatar ? props.reply.avatar : blankImage} />
          <div className="name-wrapper">
            <h3 className={nameClass}>{props.reply.user}</h3>
            <p className="comment-timestamp">-</p>
            <p className="comment-timestamp">
              <TimeAgo date={format(new Date(props.reply.timestamp), 'MMM dd, yyyy hh:mm:ss a')} />
            </p>
          </div>
        </div>
        <div className="content-wrapper">
          <p className="content">{props.reply.content}</p>
          <div className="comment-actions">
            <div className="comment-buttons-wrapper">
              {localStorage.getItem('accessToken') &&
                (props.isOwner || props.reply.creator_uuid === localStorage.getItem('uuid')) && (
                  <button
                    className="delete-button"
                    type="button"
                    disabled={disabled}
                    onClick={() => setCustomModalShow(true)}
                  >
                    Delete
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        show={customModalShow}
        onHide={() => setCustomModalShow(false)}
        modalTitle="Delete Reply"
        modalBody="Are you sure you want to delete this reply?"
        submitButtonText="Delete"
        handleSubmit={handleDeleteComment}
      />
    </>
  );
};

export default Reply;
