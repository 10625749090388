import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { RadioGroup, FormControlLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

import { MAXIMUM_QUIZ_ANSWER_LENGTH } from '../../actions/constants';

import StyledRadio from '../StyledRadio/StyledRadio';

import './QuizAnswer.scss';

const QuizAnswer = (props) => {
  const [answerInput, setAnswerInput] = useState(props.answer.statement);
  const [radioGroupValue, setRadioGroupValue] = useState(
    props.answer.is_correct ? 'is_correct' : '',
  );
  const [isCorrect, setIsCorrect] = useState(props.answer.is_correct);

  useEffect(() => {
    setAnswerInput(props.answer.statement);
    setRadioGroupValue(props.answer.is_correct ? 'is_correct' : '');
    setIsCorrect(props.answer.is_correct);
  }, [props.answers]);

  const handleIsCorrectClick = (e) => {
    var updatedAnswer = '';
    if (e.target.value === radioGroupValue) {
      setRadioGroupValue('');
      setIsCorrect(false);

      if (props.answer.hasOwnProperty('uuid')) {
        updatedAnswer = {
          uuid: props.answer.uuid,
          statement: answerInput,
          is_correct: false,
        };
      } else {
        updatedAnswer = {
          statement: answerInput,
          is_correct: false,
        };
      }
      props.updateAnswer(updatedAnswer, props.answerId);
    } else {
      setRadioGroupValue(e.target.value);
      setIsCorrect(true);

      if (props.answer.hasOwnProperty('uuid')) {
        updatedAnswer = {
          uuid: props.answer.uuid,
          statement: answerInput,
          is_correct: true,
        };
      } else {
        updatedAnswer = {
          statement: answerInput,
          is_correct: true,
        };
      }
      props.updateAnswer(updatedAnswer, props.answerId);
    }
  };

  const handleStatementBlur = (e) => {
    var updatedAnswer = '';

    if (props.answer.hasOwnProperty('uuid')) {
      updatedAnswer = {
        uuid: props.answer.uuid,
        statement: answerInput,
        is_correct: isCorrect,
      };
    } else {
      updatedAnswer = {
        statement: answerInput,
        is_correct: isCorrect,
      };
    }
    props.updateAnswer(updatedAnswer, props.answerId);
  };

  return (
    <div className="quiz-answer-wrapper">
      <TextField
        id="answerStatement"
        className="create-panel-answer-input"
        value={answerInput}
        onChange={(e) => setAnswerInput(e.target.value)}
        onBlur={handleStatementBlur}
        inputProps={{
          maxLength: MAXIMUM_QUIZ_ANSWER_LENGTH,
        }}
        multiline={true}
        required
      />
      <RadioGroup
        className="answer-radio"
        value={radioGroupValue}
        aria-label="is-correct"
        name="styled-answer-radio"
        onClick={handleIsCorrectClick}
      >
        <FormControlLabel
          value="is_correct"
          control={<StyledRadio onClick={handleIsCorrectClick} />}
          label="Is correct"
        />
      </RadioGroup>
      <div className="delete-answer" onClick={() => props.removeAnswer(props.answerId)}>
        <FontAwesomeIcon icon={faTrashAlt} className="trash-icon" />
        <p className="delete-text">Delete Answer</p>
      </div>
    </div>
  );
};

export default QuizAnswer;
