import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './VideoNote.scss';

const VideoNote = (props) => {
  const handleOpenFile = () => {
    if (!props.removeNote) {
      window.open(props.note.file, '_blank');
    }
  };

  const handleRemoveNote = (e) => {
    e.stopPropagation();
    props.removeNote(props.noteId);
  };

  return (
    <div className="video-note-wrapper" onClick={handleOpenFile}>
      <FontAwesomeIcon icon={faFilePdf} className="note-icon" />
      <h1 className="note-title">{props.note.name}</h1>
      {props.removeNote && (
        <FontAwesomeIcon icon={faTimes} className="remove-icon" onClick={handleRemoveNote} />
      )}
    </div>
  );
};

export default VideoNote;
