import axios from 'axios';
import axiosApiInstance from './axios';

import { url } from './constants';

import {
  CREATE_TOPIC_CERTIFICATE,
  CREATE_TOPIC_CERTIFICATE_ERROR,
  CREATE_PDF,
  CREATE_PDF_ERROR,
  VERIFY_CERTIFICATE,
  VERIFY_CERTIFICATE_ERROR,
  CLEAR_CERTIFICATE_STATE,
} from './actionTypes';

export const createTopicCertificate = (formData) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.post(
      `${url}/certificates/create_topic_certificate/`,
      formData,
    );

    if (res.data.topic_pdf || res.data.flow_pdf) {
      dispatch({
        type: CREATE_PDF,
        payload: res.data,
      });
    } else {
      dispatch({
        type: CREATE_PDF_ERROR,
      });
    }

    dispatch({
      type: CREATE_TOPIC_CERTIFICATE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_TOPIC_CERTIFICATE_ERROR,
      payload: err.response,
    });
  }
};

export const verifyTopicCertificate = (uuid) => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/certificates/topic_certificate/${uuid}/`);

    dispatch({
      type: VERIFY_CERTIFICATE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: VERIFY_CERTIFICATE_ERROR,
      payload: err.response,
    });
  }
};

export const verifyFlowCertificate = (uuid) => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/certificates/flow_certificate/${uuid}/`);

    dispatch({
      type: VERIFY_CERTIFICATE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: VERIFY_CERTIFICATE_ERROR,
      payload: err.response,
    });
  }
};

export const clearCertificateState = () => (dispatch) => {
  dispatch({
    type: CLEAR_CERTIFICATE_STATE,
  });
};
