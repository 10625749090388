import {
  UPLOAD_VIDEO,
  UPLOAD_VIDEO_ERROR,
  GET_MY_VIDEOS,
  GET_MY_VIDEOS_ERROR,
  ADD_VIDEO_TO_TOPIC,
  ADD_VIDEO_TO_TOPIC_ERROR,
  REMOVE_VIDEO_FROM_TOPIC,
  REMOVE_VIDEO_FROM_TOPIC_ERROR,
  SET_FEATURED_VIDEO,
  SET_FEATURED_VIDEO_ERROR,
  REMOVE_FEATURED_VIDEO,
  REMOVE_FEATURED_VIDEO_ERROR,
  SEARCH_VIDEOS,
  SEARCH_VIDEOS_ERROR,
  CLEAR_VIDEO_STATE,
  CLEAR_SEARCH_VIDEOS_STATE,
  VIDEO_LOADING,
  LIKE_VIDEO,
  LIKE_VIDEO_ERROR,
  DISLIKE_VIDEO,
  DISLIKE_VIDEO_ERROR,
  VIEW_VIDEO,
  VIEW_VIDEO_ERROR,
  SUGGEST_VIDEO,
  SUGGEST_VIDEO_ERROR,
  SEARCH_VIDEO_LOADING,
  INCREMENT_SEARCH_VIDEOS_API_COUNT,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  video: '',
  myVideos: [],
  searchedVideos: [],
  errorMessage: '',
  videoLoading: false,
  searchVidoesAPICount: 0,
};

const videoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPLOAD_VIDEO:
      return {
        ...state,
        video: action.payload,
        errorMessage: '',
        videoLoading: false,
      };
    case UPLOAD_VIDEO_ERROR:
      return {
        ...state,
        video: '',
        errorMessage: action.payload,
        videoLoading: false,
      };
    case GET_MY_VIDEOS:
      return {
        ...state,
        myVideos: action.payload,
        errorMessage: '',
        videoLoading: false,
      };
    case GET_MY_VIDEOS_ERROR:
      return {
        ...state,
        myVideos: [],
        errorMessage: action.payload,
        videoLoading: false,
      };
    case ADD_VIDEO_TO_TOPIC:
      return {
        ...state,
        myVideos: [],
        searchedVideos: [],
        errorMessage: '',
        videoLoading: false,
      };
    case ADD_VIDEO_TO_TOPIC_ERROR:
      return {
        ...state,
        myVideos: [],
        searchedVideos: [],
        errorMessage: action.payload,
        videoLoading: false,
      };
    case SEARCH_VIDEOS:
      return {
        ...state,
        searchedVideos: action.payload,
        errorMessage: '',
        searchVideoLoading: false,
        searchVidoesAPICount: state.searchVidoesAPICount - 1,
      };
    case SEARCH_VIDEOS_ERROR:
      return {
        ...state,
        searchedVideos: [],
        errorMessage: action.payload,
        searchVideoLoading: false,
        searchVidoesAPICount: state.searchVidoesAPICount - 1,
      };
    case REMOVE_VIDEO_FROM_TOPIC:
      return {
        ...state,
        myVideos: [],
        errorMessage: '',
        videoLoading: false,
      };
    case REMOVE_VIDEO_FROM_TOPIC_ERROR:
      return {
        ...state,
        myVideos: [],
        errorMessage: action.payload,
        videoLoading: false,
      };
    case SUGGEST_VIDEO:
      return {
        ...state,
        errorMessage: '',
        videoLoading: false,
      };
    case SUGGEST_VIDEO_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        videoLoading: false,
      };
    case SET_FEATURED_VIDEO:
      return {
        ...state,
        errorMessage: '',
        videoLoading: false,
      };
    case SET_FEATURED_VIDEO_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        videoLoading: false,
      };
    case REMOVE_FEATURED_VIDEO:
      return {
        ...state,
        myVideos: [],
        errorMessage: '',
        videoLoading: false,
      };
    case REMOVE_FEATURED_VIDEO_ERROR:
      return {
        ...state,
        myVideos: [],
        errorMessage: action.payload,
        videoLoading: false,
      };
    case CLEAR_VIDEO_STATE:
      return {
        ...state,
        video: '',
        myVideos: [],
        errorMessage: '',
        videoLoading: false,
      };
    case CLEAR_SEARCH_VIDEOS_STATE:
      return {
        ...state,
        searchedVideos: [],
        errorMessage: '',
        videoLoading: false,
      };
    case VIDEO_LOADING:
      return {
        ...state,
        videoLoading: true,
      };
    case LIKE_VIDEO:
      return {
        ...state,
        video: action.payload,
      };
    case LIKE_VIDEO_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case DISLIKE_VIDEO:
      return {
        ...state,
        video: action.payload,
      };
    case DISLIKE_VIDEO_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case VIEW_VIDEO:
      return {
        ...state,
        video: action.payload,
      };
    case VIEW_VIDEO_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case SEARCH_VIDEO_LOADING:
      return {
        ...state,
        searchVideoLoading: true,
      };
    case INCREMENT_SEARCH_VIDEOS_API_COUNT:
      return {
        ...state,
        searchVidoesAPICount: state.searchVidoesAPICount + 1,
      };
    default:
      return state;
  }
};

export default videoReducer;
