import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import {
  updatePassword,
  setAuthLoading,
  clearError,
  clearPasswordResetState,
} from '../../actions/authAction';

import { MINIMUM_PASSWORD_LENGTH } from '../../actions/constants';

import Loader from '../Loader/Loader';

import './UpdatePassword.scss';

const UpdatePassword = (props) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const handleClickShowCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
  const handleMouseDownCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);

  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const handleClickShowConfirmNewPassword = () =>
    setShowConfirmNewPassword(!showConfirmNewPassword);
  const handleMouseDownConfirmNewPassword = () =>
    setShowConfirmNewPassword(!showConfirmNewPassword);

  const authState = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearPasswordResetState());
      dispatch(clearError());
    };
  }, []);

  const handleModalClose = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    setShowCurrentPassword(false);
    setShowNewPassword(false);
    setShowConfirmNewPassword(false);
    dispatch(clearPasswordResetState());
    dispatch(clearError());
    props.onHide();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword === confirmNewPassword) {
      dispatch(setAuthLoading());
      dispatch(updatePassword({ password: currentPassword, new_password: newPassword }));
    }
  };

  return (
    <Modal className="update-password-modal" {...props} centered backdrop="static">
      {authState.authLoading ? (
        <div className="update-password-container">
          <Loader margin="0" height="512px" width="480px" />
        </div>
      ) : authState.successMessage === 'Password updated' ? (
        <div className="update-password-container">
          <div className="auth-card">
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="close-modal-icon"
              onClick={handleModalClose}
            />
            <div className="auth-card-header update-password-header-success">
              <h3 className="auth-card-header-text">You're good to go!</h3>
            </div>
            <div className="auth-card-body">
              <p className="update-password-body-success-text">
                You have successfully updated your password.
              </p>
            </div>
            <div className="auth-card-footer">
              <button
                className="auth-card-button-dark auth-card-button-dark-no-margin"
                onClick={handleModalClose}
              >
                Back to Profile
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="update-password-container">
          <div className="auth-card">
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="close-modal-icon"
              onClick={handleModalClose}
            />
            <div className="auth-card-header auth-card-header-multiline">
              <h3 className="auth-card-header-text">Change Password</h3>
              <p className="auth-card-header-multiline-text">
                Create password with minimum {MINIMUM_PASSWORD_LENGTH} characters
              </p>
            </div>
            <div className="auth-card-body">
              <form className="auth-form" id="updatePasswordForm" onSubmit={handleSubmit}>
                <TextField
                  className="auth-card-input"
                  style={{
                    height: authState.errorMessage ? '91px' : '64px',
                  }}
                  label="Current Password"
                  variant="filled"
                  type={showCurrentPassword ? 'text' : 'password'}
                  required
                  helperText={authState.errorMessage ? authState.errorMessage : ''}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCurrentPassword}
                          onMouseDown={handleMouseDownCurrentPassword}
                        >
                          {!showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  className="auth-card-input"
                  label="New Password"
                  variant="filled"
                  type={showNewPassword ? 'text' : 'password'}
                  required
                  onChange={(e) => setNewPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownNewPassword}
                        >
                          {!showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    inputProps: {
                      minLength: MINIMUM_PASSWORD_LENGTH,
                    },
                  }}
                />
                <TextField
                  className="auth-card-input"
                  style={{
                    height:
                      confirmNewPassword !== '' &&
                      newPassword !== '' &&
                      confirmNewPassword !== newPassword
                        ? '91px'
                        : '64px',
                  }}
                  label="Confirm New Password"
                  variant="filled"
                  type={showConfirmNewPassword ? 'text' : 'password'}
                  required
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  helperText={
                    confirmNewPassword !== '' &&
                    newPassword !== '' &&
                    confirmNewPassword !== newPassword
                      ? 'Passwords do not match'
                      : ''
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmNewPassword}
                          onMouseDown={handleMouseDownConfirmNewPassword}
                        >
                          {!showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    inputProps: {
                      minLength: MINIMUM_PASSWORD_LENGTH,
                    },
                  }}
                />
              </form>
            </div>
            <div className="auth-card-footer">
              <button
                className="auth-card-button-dark auth-card-button-dark-no-margin"
                type="submit"
                form="updatePasswordForm"
              >
                Update Password
              </button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default UpdatePassword;
