import axios from 'axios';
import axiosApiInstance from './axios';

import { url } from './constants';

import {
  UPLOAD_VIDEO,
  UPLOAD_VIDEO_ERROR,
  GET_MY_VIDEOS,
  GET_MY_VIDEOS_ERROR,
  ADD_VIDEO_TO_TOPIC,
  ADD_VIDEO_TO_TOPIC_ERROR,
  REMOVE_VIDEO_FROM_TOPIC,
  REMOVE_VIDEO_FROM_TOPIC_ERROR,
  SET_FEATURED_VIDEO,
  SET_FEATURED_VIDEO_ERROR,
  REMOVE_FEATURED_VIDEO,
  REMOVE_FEATURED_VIDEO_ERROR,
  SEARCH_VIDEOS,
  SEARCH_VIDEOS_ERROR,
  CLEAR_VIDEO_STATE,
  CLEAR_SEARCH_VIDEOS_STATE,
  VIDEO_LOADING,
  LIKE_VIDEO,
  LIKE_VIDEO_ERROR,
  DISLIKE_VIDEO,
  DISLIKE_VIDEO_ERROR,
  VIEW_VIDEO,
  VIEW_VIDEO_ERROR,
  SUGGEST_VIDEO,
  SUGGEST_VIDEO_ERROR,
  SEARCH_VIDEO_LOADING,
  INCREMENT_SEARCH_VIDEOS_API_COUNT,
  GET_FLOW,
} from './actionTypes';

export const uploadVideo = (formData) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.post(`${url}/video/video/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
    });

    dispatch({
      type: UPLOAD_VIDEO,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: UPLOAD_VIDEO_ERROR,
      payload: err.response,
    });
  }
};

export const addVideoToTopic = (formData, topicUuid, flowUuid) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.post(`${url}/flow/add_to_topic/${topicUuid}`, formData);

    dispatch({
      type: ADD_VIDEO_TO_TOPIC,
      payload: res.data,
    });

    const res2 = await axiosApiInstance.get(`${url}/flow/get_flows/${flowUuid}/`);

    dispatch({
      type: GET_FLOW,
      payload: res2.data,
    });
  } catch (err) {
    dispatch({
      type: ADD_VIDEO_TO_TOPIC_ERROR,
      payload: err.response,
    });
  }
};

export const removeVideoFromTopic = (formData, topicUuid, flowUuid) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.post(`${url}/flow/remove_video/${topicUuid}`, formData);

    dispatch({
      type: REMOVE_VIDEO_FROM_TOPIC,
      payload: res.data,
    });

    const res2 = await axiosApiInstance.get(`${url}/flow/get_flows/${flowUuid}/`);

    dispatch({
      type: GET_FLOW,
      payload: res2.data,
    });
  } catch (err) {
    dispatch({
      type: REMOVE_VIDEO_FROM_TOPIC_ERROR,
      payload: err.response,
    });
  }
};

export const suggestVideo = (formData) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.post(`${url}/video/suggest_video`, formData);

    dispatch({
      type: SUGGEST_VIDEO,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SUGGEST_VIDEO_ERROR,
      payload: err.response,
    });
  }
};

export const setFeaturedVideo = (formData, topicUuid, flowUuid) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.post(
      `${url}/flow/set_featured_video/${topicUuid}`,
      formData,
    );

    dispatch({
      type: SET_FEATURED_VIDEO,
      payload: res.data,
    });

    const res2 = await axiosApiInstance.get(`${url}/flow/get_flows/${flowUuid}/`);

    dispatch({
      type: GET_FLOW,
      payload: res2.data,
    });
  } catch (err) {
    dispatch({
      type: SET_FEATURED_VIDEO_ERROR,
      payload: err.response,
    });
  }
};

export const removeFeaturedVideo = (topicUuid, flowUuid) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.post(`${url}/flow/remove_featured_video/${topicUuid}`);

    dispatch({
      type: REMOVE_FEATURED_VIDEO,
      payload: res.data,
    });

    const res2 = await axiosApiInstance.get(`${url}/flow/get_flows/${flowUuid}/`);

    dispatch({
      type: GET_FLOW,
      payload: res2.data,
    });
  } catch (err) {
    dispatch({
      type: REMOVE_FEATURED_VIDEO_ERROR,
      payload: err.response,
    });
  }
};

export const likeVideo = (formData, videoUuid, flowUuid) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.patch(`${url}/video/like_video/${videoUuid}/`, formData);

    dispatch({
      type: LIKE_VIDEO,
      payload: res.data,
    });

    const res2 = await axiosApiInstance.get(`${url}/flow/get_flows/${flowUuid}/`);

    dispatch({
      type: GET_FLOW,
      payload: res2.data,
    });
  } catch (err) {
    dispatch({
      type: LIKE_VIDEO_ERROR,
      payload: err.response,
    });
  }
};

export const dislikeVideo = (formData, videoUuid, flowUuid) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.patch(`${url}/video/dislike_video/${videoUuid}/`, formData);

    dispatch({
      type: DISLIKE_VIDEO,
      payload: res.data,
    });

    const res2 = await axiosApiInstance.get(`${url}/flow/get_flows/${flowUuid}/`);

    dispatch({
      type: GET_FLOW,
      payload: res2.data,
    });
  } catch (err) {
    dispatch({
      type: DISLIKE_VIDEO_ERROR,
      payload: err.response,
    });
  }
};

export const viewVideo = (formData, videoUuid) => async (dispatch) => {
  try {
    if (localStorage.getItem('accessToken')) {
      var res = await axiosApiInstance.patch(`${url}/video/view_video/${videoUuid}/`, formData);
    } else {
      var res = await axios.patch(`${url}/video/view_video/${videoUuid}/`, formData);
    }

    dispatch({
      type: VIEW_VIDEO,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: VIEW_VIDEO_ERROR,
      payload: err.response,
    });
  }
};

export const getMyVideos = () => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(`${url}/video/my_videos`);

    dispatch({
      type: GET_MY_VIDEOS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_MY_VIDEOS_ERROR,
      payload: err.response,
    });
  }
};

export const searchVideos = (title) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(`${url}/video/video/?search=${title}`);

    dispatch({
      type: SEARCH_VIDEOS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SEARCH_VIDEOS_ERROR,
      payload: err.response,
    });
  }
};

export const clearVideoState = () => (dispatch) => {
  dispatch({
    type: CLEAR_VIDEO_STATE,
  });
};

export const clearSearchVideosState = () => (dispatch) => {
  dispatch({
    type: CLEAR_SEARCH_VIDEOS_STATE,
  });
};

export const setVideoLoading = () => (dispatch) => {
  dispatch({
    type: VIDEO_LOADING,
  });
};

export const setSearchVideoLoading = () => (dispatch) => {
  dispatch({
    type: SEARCH_VIDEO_LOADING,
  });
};

export const incrementSearchVideosAPICount = () => (dispatch) => {
  dispatch({
    type: INCREMENT_SEARCH_VIDEOS_API_COUNT,
  });
};
