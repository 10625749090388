import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faSignOutAlt,
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import blankPicture from '../../images/blank-image.png';

import { signOut } from '../../actions/authAction';
import { clearProfile } from '../../actions/profileAction';

import './MyProfileSideMenu.scss';

const MyProfileSideMenu = (props) => {
  const [showSideMenu, setShowSideMenu] = useState(false);

  const profileState = useSelector((state) => state.profile);

  const dispatch = useDispatch();

  const handleMenuItemSelect = (e) => {
    document
      .getElementById(props.previousSelectedMenuItem)
      .classList.remove('menu-list-item-selected');
    document.getElementById(e.target.id).classList.add('menu-list-item-selected');
    props.setPreviousSelectedMenuItem(e.target.id);
    props.setSelectedMenuItem(e.target.id);
    props.setShowProfileSettings(false);
    setShowSideMenu(false);
    window.scrollTo(0, 0);
  };

  const handleProfileSettingsClick = () => {
    props.setShowProfileSettings(true);
    props.setSelectedMenuItem('');
    setShowSideMenu(false);
    window.scrollTo(0, 0);
  };

  const handleLogout = async () => {
    dispatch(signOut());
    dispatch(clearProfile());
    window.location.href = '/';
  };

  const myProfileSideMenuClass = classNames('my-profile-side-menu-wrapper', {
    active: showSideMenu,
  });

  return (
    <>
      {showSideMenu ? (
        <div id="my-profile-overlay" onClick={() => setShowSideMenu(false)}></div>
      ) : null}
      <div className={myProfileSideMenuClass}>
        <div className="my-profile-side-menu-arrow" onClick={() => setShowSideMenu(!showSideMenu)}>
          <FontAwesomeIcon
            icon={showSideMenu ? faChevronLeft : faChevronRight}
            className="arrow-icon"
          />
        </div>
        <div className="my-profile-side-menu">
          <div className="personal-info-section">
            <div className="personal-info-section-row-1">
              <div className="profile-image-wrapper">
                <img
                  className="profile-image"
                  src={profileState.avatar ? profileState.avatar : blankPicture}
                ></img>
                <div className="settings-icon-wrapper" onClick={handleProfileSettingsClick}>
                  <FontAwesomeIcon icon={faCog} className="settings-icon" />
                </div>
              </div>
              <div className="data-sum-wrapper">
                <h3 className="data-sum">{profileState.myFlows.length}</h3>
                <p className="data-title">Flows</p>
              </div>
              <div className="data-sum-wrapper videos-sum-wrapper">
                <h3 className="data-sum">{profileState.myVideos.length}</h3>
                <p className="data-title">Videos</p>
              </div>
            </div>
            <div className="personal-info-section-row-2">
              <h3 className="user-full-name">
                {profileState.firstName + ' ' + profileState.lastName}
              </h3>
              <p className="user-email">{profileState.email}</p>
            </div>
          </div>
          <div className="menu-list-section">
            <div
              id="my-flows"
              className="menu-list-item menu-list-item-selected"
              onClick={handleMenuItemSelect}
            >
              <p className="menu-list-item-title">My Flows</p>
              <div className="menu-list-item-sum">{profileState.myFlows.length}</div>
            </div>
            <div id="my-videos" className="menu-list-item" onClick={handleMenuItemSelect}>
              <p className="menu-list-item-title">My Videos</p>
              <div className="menu-list-item-sum">{profileState.myVideos.length}</div>
            </div>
            <div id="my-quizzes" className="menu-list-item" onClick={handleMenuItemSelect}>
              <p className="menu-list-item-title">My Quizzes</p>
              <div className="menu-list-item-sum">{profileState.myQuizzes.length}</div>
            </div>
            <div id="my-certifications" className="menu-list-item" onClick={handleMenuItemSelect}>
              <p className="menu-list-item-title">My Certifications</p>
              <div className="menu-list-item-sum">
                {profileState.myCertificates
                  .map(
                    (certificate) =>
                      certificate.flow_certificate.length + certificate.topic_certificates.length,
                  )
                  .reduce((a, b) => a + b, 0)}
              </div>
            </div>
            <div id="video-suggestions" className="menu-list-item" onClick={handleMenuItemSelect}>
              <p className="menu-list-item-title">Video Suggestions</p>
              <div className="menu-list-item-sum">
                {profileState.videoSuggestions
                  .map((flow) =>
                    flow.topics.map((topic) => topic.suggestions.length).reduce((a, b) => a + b, 0),
                  )
                  .reduce((a, b) => a + b, 0)}
              </div>
            </div>
          </div>
          <div className="logout-button-wrapper" onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} className="logout-icon" />
            <p className="logout-button-text">Logout</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfileSideMenu;
