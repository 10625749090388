import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import HomeFlow from '../HomeFlow/HomeFlow';
import MyVideoCard from '../MyVideoCard/MyVideoCard';
import MyQuizCard from '../MyQuizCard/MyQuizCard';
import MyCertificate from '../MyCertificate/MyCertificate';
import ProfileSettings from '../ProfileSettings/ProfileSettings';
import AddTopicVideoModal from '../AddTopicVideoModal/AddTopicVideoModal';
import AddTopicQuizModal from '../AddTopicQuizModal/AddTopicQuizModal';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import Loader from '../Loader/Loader';

import './MyProfileDashboard.scss';

const MyProfileDashboard = ({ selectedMenuItem }) => {
  const profileState = useSelector((state) => state.profile);

  const [addVideoModalShow, setAddVideoModalShow] = useState(false);
  const [addQuizModalShow, setAddQuizModalShow] = useState(false);
  const [selectedSuggestionFilter, setSelectedSuggestionFilter] = useState('');
  const [selectedSuggestion, setSelectedSuggestion] = useState(profileState.videoSuggestions[0]);
  const [selectedCertificateFilter, setSelectedCertificateFilter] = useState('');
  const [selectedCertificate, setSelectedCertificate] = useState(profileState.myCertificates[0]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [suggestionApproved, setSuggestionApproved] = useState(false);

  const history = useHistory();

  const handleDropdownChange = (filter) => {
    if (selectedMenuItem === 'video-suggestions') {
      setSelectedSuggestionFilter(filter);
    } else if (selectedMenuItem === 'my-certifications') {
      setSelectedCertificateFilter(filter);
    }
  };

  useEffect(() => {
    if (selectedMenuItem === 'video-suggestions' && profileState.videoSuggestions.length > 0) {
      if (selectedSuggestionFilter) {
        setSelectedSuggestion(
          profileState.videoSuggestions.find(
            (suggestion) => suggestion.uuid === selectedSuggestionFilter,
          ),
        );
      } else {
        setSelectedSuggestion(profileState.videoSuggestions[0]);
      }
    }
  }, [selectedSuggestionFilter]);

  useEffect(() => {
    if (selectedMenuItem === 'video-suggestions' && profileState.videoSuggestions.length > 0) {
      const flow = profileState.videoSuggestions.find(
        (suggestion) => suggestion.uuid === selectedSuggestionFilter,
      );
      if (flow) {
        setSelectedSuggestion(flow);
      } else {
        setSelectedSuggestionFilter(profileState.videoSuggestions[0].uuid);
      }
    }
  }, [profileState.videoSuggestions]);

  useEffect(() => {
    if (selectedMenuItem === 'my-certifications' && profileState.myCertificates.length > 0) {
      if (selectedCertificateFilter) {
        setSelectedCertificate(
          profileState.myCertificates.find(
            (certificate) => certificate.uuid === selectedCertificateFilter,
          ),
        );
      } else {
        setSelectedCertificate(profileState.myCertificates[0]);
      }
    }
  }, [selectedCertificateFilter]);

  useEffect(() => {
    if (selectedMenuItem === 'my-certifications' && profileState.myCertificates.length > 0) {
      const flow = profileState.myCertificates.find(
        (certificate) => certificate.uuid === selectedCertificateFilter,
      );
      if (flow) {
        setSelectedCertificate(flow);
      } else {
        setSelectedCertificateFilter(profileState.myCertificates[0].uuid);
      }
    }
  }, [profileState.myCertificates]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const headingRenderSwitch = (param) => {
    switch (param) {
      case 'my-flows':
        return 'My Flows';
      case 'my-videos':
        return 'My Videos';
      case 'my-quizzes':
        return 'My Quizzes';
      case 'my-certifications':
        return 'My Certifications';
      case 'video-suggestions':
        return 'Video Suggestions';
      default:
        return 'Account Settings';
    }
  };

  const contentRenderSwitch = (param) => {
    switch (param) {
      case 'my-flows':
        return profileState.myFlows.length ? (
          <div className="content-list-grid">
            {profileState.myFlows.map((myFlow) => (
              <HomeFlow
                key={myFlow.uuid}
                flowId={myFlow.uuid}
                title={myFlow.title}
                thumbnail={myFlow.thumbnail}
                views={myFlow.views}
                videos={myFlow.topics
                  .map((topic) => topic.videos.length)
                  .reduce((a, b) => a + b, 0)}
                likes={myFlow.likes}
                topics={myFlow.topics.length}
                quizzes={myFlow.topics
                  .map((topic) => topic.quizzes.length)
                  .reduce((a, b) => a + b, 0)}
                tags={myFlow.tags}
                description={myFlow.description}
                creatorName={myFlow.creator_name}
              />
            ))}
          </div>
        ) : (
          <p className="no-content">No Flows</p>
        );
      case 'my-videos':
        return profileState.myVideos.length ? (
          <div className="content-list-grid video">
            {profileState.myVideos.map((myVideo) => (
              <MyVideoCard
                key={myVideo.uuid}
                video={myVideo}
                showSelect={false}
                searchCard={false}
                owner={true}
                edit={true}
              />
            ))}
          </div>
        ) : (
          <p className="no-content">No Videos</p>
        );
      case 'my-quizzes':
        return profileState.myQuizzes.length ? (
          <div className="content-list-grid quiz">
            {profileState.myQuizzes.map((myQuiz) => (
              <MyQuizCard
                key={myQuiz.uuid}
                quiz={myQuiz}
                showSelect={false}
                searchCard={false}
                edit={true}
              />
            ))}
          </div>
        ) : (
          <p className="no-content">No Quizzes</p>
        );
      case 'my-certifications':
        return profileState.myCertificates.length > 0 ? (
          <div className="my-certifications-section">
            {selectedCertificate.flow_certificate.length > 0 && (
              <div className="certifications-wrapper">
                <h3 className="certifications-heading">Flow Certificates</h3>
                <div className="content-list-grid certificate">
                  <MyCertificate
                    type="flow"
                    certificate={selectedCertificate.flow_certificate[0]}
                    selectedCertificate={selectedCertificate}
                  />
                </div>
              </div>
            )}
            {selectedCertificate.topic_certificates.length > 0 && (
              <div className="certifications-wrapper">
                <h3 className="certifications-heading">Topic Certificates</h3>
                <div className="content-list-grid certificate">
                  {selectedCertificate.topic_certificates.map((certificate) => (
                    <MyCertificate
                      key={certificate.uuid}
                      type="topic"
                      certificate={certificate}
                      selectedCertificate={selectedCertificate}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : (
          <p className="no-content">No Certificates</p>
        );
      case 'video-suggestions':
        return profileState.videoSuggestions.length > 0 ? (
          selectedSuggestion.topics.map((topic) => (
            <div className="video-suggestions-section">
              <div className="video-suggestions-wrapper">
                <h3 className="video-suggestions-heading">
                  <span>
                    <FontAwesomeIcon className="dot-icon" icon={faCircle} />
                  </span>
                  {topic.title}
                </h3>
                <div className="content-list-grid video-suggestions">
                  {topic.suggestions.map((suggestion) => (
                    <MyVideoCard
                      key={suggestion.uuid}
                      video={suggestion}
                      suggestion={true}
                      setOpenSnackbar={setOpenSnackbar}
                      setSuggestionApproved={setSuggestionApproved}
                    />
                  ))}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="no-content">No Suggestions</p>
        );
      default:
        return <ProfileSettings />;
    }
  };

  return (
    <>
      <div className="my-profile-dashboard">
        <div className="my-profile-buttons-wrapper">
          <button
            className="my-profile-button"
            type="button"
            onClick={() => history.push('/create-flow')}
          >
            New Flow
          </button>
          <button
            className="my-profile-button"
            type="button"
            onClick={() => setAddVideoModalShow(true)}
          >
            New Video
          </button>
          <button
            className="my-profile-button"
            type="button"
            onClick={() => setAddQuizModalShow(true)}
          >
            New Quiz
          </button>
          <button className="my-profile-button" type="button">
            Invite Friends
          </button>
        </div>
        <div className="my-profile-dashboard-content">
          <div className="content-heading-row">
            <h3 className="content-heading">{headingRenderSwitch(selectedMenuItem)}</h3>
            {selectedMenuItem === 'my-certifications' && profileState.myCertificates.length > 0 && (
              <CustomDropdown
                handleDropdownChange={handleDropdownChange}
                type="bordered"
                items={profileState.myCertificates.map((certificate) => ({
                  value: certificate.uuid,
                  name: certificate.title,
                }))}
                value={
                  selectedCertificateFilter
                    ? selectedCertificateFilter
                    : profileState.myCertificates[0].uuid
                }
              />
            )}
            {selectedMenuItem === 'video-suggestions' && profileState.videoSuggestions.length > 0 && (
              <CustomDropdown
                handleDropdownChange={handleDropdownChange}
                type="bordered"
                items={profileState.videoSuggestions.map((suggestion) => ({
                  value: suggestion.uuid,
                  name: suggestion.title,
                }))}
                value={
                  selectedSuggestionFilter
                    ? selectedSuggestionFilter
                    : profileState.videoSuggestions[0].uuid
                }
              />
            )}
          </div>
          {profileState.profileDashboardLoading ? (
            <Loader margin="0" height="60vh" />
          ) : (
            contentRenderSwitch(selectedMenuItem)
          )}
        </div>
      </div>
      <AddTopicVideoModal
        show={addVideoModalShow}
        onHide={() => setAddVideoModalShow(false)}
        newVideo={true}
      />
      <AddTopicQuizModal
        show={addQuizModalShow}
        onHide={() => setAddQuizModalShow(false)}
        newQuiz={true}
      />
      <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={suggestionApproved ? 'success' : 'error'}
        >
          {suggestionApproved ? 'Video has been added to topic.' : 'Suggestion rejected.'}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default MyProfileDashboard;
