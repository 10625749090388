import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFeaturedFlows, getAllFlows, setFlowLoading } from '../../../actions/flowAction';
import { GET_FLOWS_LIMIT } from '../../../actions/constants';

import HomeBanner from '../../HomeBanner/HomeBanner';
import HomeFlowList from '../../HomeFlowList/HomeFlowList';
import Loader from '../../Loader/Loader';

import './Home.scss';

const Home = () => {
  const flowState = useSelector((state) => state.flow);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFlowLoading());
    dispatch(getFeaturedFlows());
    dispatch(getAllFlows(GET_FLOWS_LIMIT, 0));
  }, []);

  return flowState.flowLoading ? (
    <Loader />
  ) : (
    <div className="my-container home-page-container">
      <div className="home-page">
        {flowState.featuredFlows.length > 0 && <HomeBanner />}
        <HomeFlowList />
      </div>
    </div>
  );
};

export default Home;
