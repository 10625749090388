import React from 'react';
import { Page, Text, Image, View, Document, StyleSheet } from '@react-pdf/renderer';
import { format } from 'date-fns';

import kooledgeLogo from '../../images/kooledge-logo-vector.png';

import './MyPDFDocument.scss';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    border: '13px solid #C09B60',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    padding: 40,
  },
  section: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },
  centeredSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  textSection: {
    marginBottom: 30,
  },
  logo: {
    width: '45%',
    marginBottom: 40,
  },
  text: {
    fontSize: 12,
    fontWeight: 400,
    color: '#000',
    lineHeight: 1.5,
  },
  boldText: {
    fontSize: 14,
    fontWeight: 700,
    color: '#000',
    lineHeight: 1.5,
  },
});

const MyPDFDocument = (props) => {
  return (
    <Document>
      <Page style={styles.page} size="A4" fixed>
        <View style={styles.centeredSection} fixed>
          <Image style={styles.logo} src={kooledgeLogo} />
        </View>
        {props.type === 'topic' && (
          <View style={styles.section}>
            <View style={(styles.section, styles.textSection)}>
              <Text style={styles.text}>
                On {format(new Date(props.pdfData?.date_obtained), 'dd/MM/yy')},{' '}
                {props.pdfData?.username} successfully completed the following:
              </Text>
            </View>
            <View style={(styles.section, styles.textSection)}>
              <Text style={styles.text}>Flow: {props.pdfData?.flow_title}</Text>
              <Text style={styles.text}>Topic: {props.pdfData?.topic_title}</Text>
              <Text style={styles.text}>Score: {props.pdfData?.marks_obtained} points</Text>
              <Text style={styles.text}>Percentile: {props.pdfData?.percentage}%</Text>
            </View>
            <View style={(styles.section, styles.textSection)}>
              <Text style={styles.text}>This certificate can be confirmed at:</Text>
              <Text
                style={styles.text}
              >{`${window.location.origin}/certificate/topic/${props.pdfData?.uuid}`}</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.text}>Sincerely,</Text>
              <Text style={styles.boldText}>Kooledge Certifications</Text>
            </View>
          </View>
        )}
        {props.type === 'flow' && (
          <View style={styles.section}>
            <View style={(styles.section, styles.textSection)}>
              <Text style={styles.text}>
                On{' '}
                {format(
                  new Date(
                    props.myProfile
                      ? props.pdfData?.flow_certificate[0].date_obtained
                      : props.pdfData?.date_obtained,
                  ),
                  'dd/MM/yy',
                )}
                ,{' '}
                {props.myProfile
                  ? props.pdfData?.flow_certificate[0].username
                  : props.pdfData?.username}{' '}
                successfully completed all required quizzes for the flow{' '}
                {props.myProfile
                  ? props.pdfData?.flow_certificate[0].flow_title
                  : props.pdfData?.flow_title}{' '}
                as described below:
              </Text>
            </View>
            {props.pdfData?.topic_certificates.map((topic_certificate) => (
              <View style={(styles.section, styles.textSection)} key={topic_certificate.uuid}>
                <Text style={styles.text}>Topic: {topic_certificate.topic_title}</Text>
                <Text style={styles.text}>
                  Date: {format(new Date(topic_certificate.date_obtained), 'dd/MM/yy')}
                </Text>
                <Text style={styles.text}>Score: {topic_certificate.marks_obtained} points</Text>
                <Text style={styles.text}>Percentile: {topic_certificate.percentage}%</Text>
              </View>
            ))}
            <View style={(styles.section, styles.textSection)}>
              <Text style={styles.text}>
                Haasin Farooq obtained an aggregate final score of{' '}
                {props.myProfile ? props.pdfData?.flow_certificate[0].score : props.pdfData?.score}{' '}
                points, corresponding to percentile{' '}
                {props.myProfile
                  ? props.pdfData?.flow_certificate[0].percentage
                  : props.pdfData?.percentage}
                %.
              </Text>
            </View>
            <View style={(styles.section, styles.textSection)}>
              <Text style={styles.text}>This certificate can be confirmed at:</Text>
              <Text style={styles.text}>{`${window.location.origin}/certificate/flow/${
                props.myProfile ? props.pdfData?.flow_certificate[0].uuid : props.pdfData?.uuid
              }`}</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.text}>Sincerely,</Text>
              <Text style={styles.boldText}>Kooledge Certifications</Text>
            </View>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default MyPDFDocument;
