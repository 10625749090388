import {
  REGISTER,
  REGISTER_ERROR,
  VERIFY_EMAIL,
  VERIFY_EMAIL_ERROR,
  LOGIN,
  LOGIN_ERROR,
  LOGOUT,
  PASSWORD_RESET_EMAIL,
  PASSWORD_RESET_EMAIL_ERROR,
  CLEAR_PASSWORD_RESET_STATE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_ERROR,
  CLEAR_ERROR,
  AUTH_LOADING,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  verified: false,
  accessToken: '',
  successMessage: '',
  errorMessage: '',
  authLoading: false,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER:
      return {
        ...state,
        verified: false,
        accessToken: action.payload.access_token,
        errorMessage: '',
        authLoading: false,
      };
    case REGISTER_ERROR:
      return {
        ...state,
        verified: false,
        accessToken: '',
        errorMessage: action.payload,
        authLoading: false,
      };
    case VERIFY_EMAIL:
      return {
        ...state,
        verified: true,
        errorMessage: '',
      };
    case VERIFY_EMAIL_ERROR:
      return {
        ...state,
        verified: false,
        errorMessage: 'Verification error',
      };
    case LOGIN:
      return {
        ...state,
        accessToken: action.payload.access_token,
        errorMessage: '',
        authLoading: false,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        accessToken: '',
        errorMessage: action.payload.data,
        authLoading: false,
      };
    case LOGOUT:
      return {
        ...state,
        accessToken: '',
        errorMessage: '',
        authLoading: false,
      };
    case PASSWORD_RESET_EMAIL:
      return {
        ...state,
        errorMessage: '',
        successMessage: 'Email sent',
        recoveryEmail: action.payload.email,
        authLoading: false,
      };
    case PASSWORD_RESET_EMAIL_ERROR:
      return {
        ...state,
        successMessage: '',
        errorMessage: action.payload,
        recoveryEmail: '',
        authLoading: false,
      };
    case CLEAR_PASSWORD_RESET_STATE:
      return {
        ...state,
        successMessage: '',
        errorMessage: '',
        recoveryEmail: '',
        authLoading: false,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        errorMessage: '',
        successMessage: 'Password changed',
        authLoading: false,
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: '',
        authLoading: false,
      };
    case UPDATE_PASSWORD:
      return {
        ...state,
        errorMessage: '',
        successMessage: 'Password updated',
        authLoading: false,
      };
    case UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        errorMessage: action.payload.data.errors[0],
        successMessage: '',
        authLoading: false,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        accessToken: '',
        errorMessage: '',
      };
    case AUTH_LOADING:
      return {
        ...state,
        authLoading: true,
      };
    default:
      return state;
  }
};

export default authReducer;
