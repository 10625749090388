import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPlay } from '@fortawesome/free-solid-svg-icons';
import { faStar, faStickyNote, faUser } from '@fortawesome/free-regular-svg-icons';
import blankThumbnail from '../../images/blank-thumbnail.png';

import { setFeaturedVideo, removeVideoFromTopic } from '../../actions/videoAction';
import { setOfficialQuiz, removeQuizFromTopic } from '../../actions/quizAction';
import { editQuiz } from '../../actions/profileAction';

import CardStats from '../CardStats/CardStats';
import CustomModal from '../CustomModal/CustomModal';
import QuizWindowModal from '../QuizWindowModal/QuizWindowModal';

import './TopicContentCard.scss';

const TopicContentCard = (props) => {
  const [quizWindowModalShow, setQuizWindowModalShow] = useState(false);
  const [removeContentModalShow, setRemoveContentModalShow] = useState(false);
  const [makeFeatureModalShow, setMakeFeatureModalShow] = useState(false);

  const dispatch = useDispatch();

  const match = useRouteMatch();

  const handleMakeFeatured = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (props.quizView) {
      if (props.quiz.visibility === 'everyone' || props.quiz.visibility === 'just_me') {
        setMakeFeatureModalShow(true);
      } else {
        formData.append('quiz_uuid', props.quiz.uuid);

        dispatch(setOfficialQuiz(formData, props.topicUuid, match.params.uuid));
      }
    } else {
      formData.append('video_uuid', props.video.uuid);

      await dispatch(setFeaturedVideo(formData, props.topicUuid, match.params.uuid));
      props.setCurrentVideo(props.video);
    }
  };

  const handleMakeOfficial = async () => {
    await dispatch(editQuiz(props.quiz.uuid, { visibility: 'registered_users' }));
    dispatch(setOfficialQuiz({ quiz_uuid: props.quiz.uuid }, props.topicUuid, match.params.uuid));
  };

  const handleRemoveContent = () => {
    const formData = new FormData();

    if (props.quizView) {
      formData.append('quiz_uuid', props.quiz.uuid);

      dispatch(removeQuizFromTopic(formData, props.topicUuid, match.params.uuid));
    } else {
      formData.append('video_uuid', props.video.uuid);

      dispatch(removeVideoFromTopic(formData, props.topicUuid, match.params.uuid));

      if (props.video.uuid === props.currentVideo.uuid) {
        if (props.featuredVideo) {
          if (props.currentVideo.uuid === props.featuredVideo.uuid) {
            props.setCurrentVideo('');
            props.setCurrentVideoTitle('');
          } else {
            props.setCurrentVideo(props.featuredVideo);
          }
        } else {
          props.setCurrentVideo('');
          props.setCurrentVideoTitle('');
        }
      }
    }
  };

  const handlePlayVideo = () => {
    props.setCurrentVideo(props.video);
  };

  return (
    <>
      <div className="topic-content-card">
        {props.editMode ? (
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="delete-topic-icon"
            onClick={() => setRemoveContentModalShow(!removeContentModalShow)}
          />
        ) : null}
        <div
          className="topic-content-card-thumbnail"
          style={
            props.quizView
              ? {
                  backgroundImage: `url(${
                    props.quiz.thumbnail ? props.quiz.thumbnail : blankThumbnail
                  })`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }
              : {
                  backgroundImage: `url(${
                    props.video.thumbnail ? props.video.thumbnail : blankThumbnail
                  })`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }
          }
        >
          {props.editMode ? (
            <div className="topic-content-card-make-featured-overlay" onClick={handleMakeFeatured}>
              <FontAwesomeIcon icon={faStar} className="make-featured-icon" />
              <p className="topic-content-card-make-featured-text">
                {props.quizView ? 'Make official' : 'Make featured'}
              </p>
            </div>
          ) : props.quizView ? (
            <div
              className="topic-content-card-thumbnail-overlay"
              onClick={() => setQuizWindowModalShow(true)}
            >
              <FontAwesomeIcon icon={faStickyNote} className="take-quiz-icon" />
              <p className="topic-content-card-take-quiz-text">Take quiz</p>
            </div>
          ) : (
            <div className="topic-content-card-thumbnail-overlay" onClick={handlePlayVideo}>
              <FontAwesomeIcon icon={faPlay} className="play-icon" />
            </div>
          )}
        </div>
        <div className="topic-content-card-title-and-stats-wrapper">
          {props.quizView ? (
            <div className="topic-content-card-title-row">
              <h3 className="topic-content-card-title">{props.quiz.title}</h3>
            </div>
          ) : (
            <div className="topic-content-card-title-row">
              <h3 className="topic-content-card-title">{props.video.title}</h3>
            </div>
          )}
          {props.quizView ? (
            <div className="topic-content-card-quiz-stats">
              <CardStats
                tries={props.quiz.try_count}
                percentageOfTriesToPass={props.quiz.average_try_count}
                questions={props.quiz.questions.length}
                width="auto"
                margin="15px 0 0"
              />
              {props.quiz.average_time > 0 && (
                <CardStats averageTime={props.quiz.average_time} width="auto" margin="8px 0 0" />
              )}
              {props.quiz.estimated_time > 0 && (
                <CardStats
                  estimatedTime={props.quiz.estimated_time}
                  width="auto"
                  margin="8px 0 0"
                />
              )}
            </div>
          ) : (
            <div className="topic-content-card-video-stats">
              <div className="video-owner-wrapper">
                <FontAwesomeIcon icon={faUser} className="card-stats-icon" />
                <p className="owner-name">{props.video.creator_name}</p>
              </div>
              <CardStats
                views={props.video.views}
                likes={props.video.likes}
                width="auto"
                margin="8px 0 0"
              />
            </div>
          )}
        </div>
      </div>
      <QuizWindowModal
        show={quizWindowModalShow}
        onHide={() => setQuizWindowModalShow(false)}
        topicUuid={props.topicUuid}
        quiz={props.quiz}
        isStartQuiz={props.isStartQuiz}
        setIsStartQuiz={props.setIsStartQuiz}
        isTakeQuiz={props.isTakeQuiz}
        setIsTakeQuiz={props.setIsTakeQuiz}
      />
      <CustomModal
        show={removeContentModalShow}
        onHide={() => setRemoveContentModalShow(false)}
        modalTitle={`Remove ${props.quizView ? 'Quiz' : 'Video'}`}
        modalBody={`Are you sure you want to remove this ${props.quizView ? 'quiz' : 'video'}?`}
        submitButtonText="Remove"
        handleSubmit={handleRemoveContent}
      />
      <CustomModal
        type="topic"
        show={makeFeatureModalShow}
        onHide={() => setMakeFeatureModalShow(false)}
        modalTitle="Make Official"
        modalBody={`This quiz is visible to ${
          props.quiz?.visibility === 'everyone' ? 'everyone' : 'only you'
        }. Official quizzes can only be attempted by registered users. Do you want to change quiz visibility to 'registered users' and make it official?`}
        submitButtonText="Continue"
        handleSubmit={handleMakeOfficial}
      />
    </>
  );
};

export default TopicContentCard;
