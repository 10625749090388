import {
  GET_PROFILE,
  GET_PROFILE_ERROR,
  GET_MY_DATA,
  GET_MY_DATA_ERROR,
  GET_MY_PROFILE_VIDEOS,
  GET_MY_PROFILE_VIDEOS_ERROR,
  GET_MY_PROFILE_QUIZZES,
  GET_MY_PROFILE_QUIZZES_ERROR,
  GET_MY_PROFILE_SUGGESTIONS,
  GET_MY_PROFILE_SUGGESTIONS_ERROR,
  UPDATE_USER_INFO,
  UPDATE_USER_INFO_ERROR,
  EDIT_VIDEO,
  EDIT_VIDEO_ERROR,
  DELETE_VIDEO,
  DELETE_VIDEO_ERROR,
  APPROVE_SUGGESTED_VIDEO,
  APPROVE_SUGGESTED_VIDEO_ERROR,
  EDIT_QUIZ,
  EDIT_QUIZ_ERROR,
  DELETE_QUIZ,
  DELETE_QUIZ_ERROR,
  CLEAR_PROFILE,
  PROFILE_LOADING,
  PROFILE_DASHBOARD_LOADING,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  uuid: '',
  email: '',
  firstName: '',
  lastName: '',
  avatar: '',
  profile: [],
  myFlows: [],
  myVideos: [],
  myQuizzes: [],
  myCertificates: [],
  videoSuggestions: [],
  errorMessage: '',
  profileLoading: false,
  profileDashboardLoading: false,
};

const profileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        uuid: action.payload.uuid,
        email: action.payload.email,
        firstName: action.payload.first_name,
        lastName: action.payload.last_name,
        avatar: action.payload.profile.avatar,
        errorMessage: '',
      };
    case GET_PROFILE_ERROR:
      return {
        ...state,
        uuid: '',
        email: '',
        firstName: '',
        lastName: '',
        avatar: '',
        errorMessage: action.payload,
      };
    case GET_MY_DATA:
      return {
        ...state,
        profile: action.payload.profile,
        myFlows: action.payload.flows,
        myVideos: action.payload.videos,
        myQuizzes: action.payload.quizzes,
        myCertificates: action.payload.certificates,
        videoSuggestions: action.payload.suggestions,
        errorMessage: '',
        profileLoading: false,
        profileDashboardLoading: false,
      };
    case GET_MY_DATA_ERROR:
      return {
        ...state,
        profile: [],
        myFlows: [],
        myVideos: [],
        myQuizzes: [],
        myCertificates: [],
        videoSuggestions: [],
        errorMessage: action.payload,
        profileLoading: false,
        profileDashboardLoading: false,
      };
    case GET_MY_PROFILE_VIDEOS:
      return {
        ...state,
        myVideos: action.payload,
        errorMessage: '',
        profileLoading: false,
        profileDashboardLoading: false,
      };
    case GET_MY_PROFILE_VIDEOS_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        profileLoading: false,
        profileDashboardLoading: false,
      };
    case GET_MY_PROFILE_QUIZZES:
      return {
        ...state,
        myQuizzes: action.payload,
        errorMessage: '',
        profileLoading: false,
        profileDashboardLoading: false,
      };
    case GET_MY_PROFILE_QUIZZES_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        profileLoading: false,
        profileDashboardLoading: false,
      };
    case GET_MY_PROFILE_SUGGESTIONS:
      return {
        ...state,
        videoSuggestions: action.payload.suggestions,
        errorMessage: '',
        profileLoading: false,
        profileDashboardLoading: false,
      };
    case GET_MY_PROFILE_SUGGESTIONS_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        profileLoading: false,
        profileDashboardLoading: false,
      };
    case UPDATE_USER_INFO:
      return {
        ...state,
        email: action.payload.email,
        firstName: action.payload.first_name,
        lastName: action.payload.last_name,
        avatar: action.payload.profile.avatar,
        profile: action.payload.profile,
        errorMessage: '',
        profileDashboardLoading: false,
      };
    case UPDATE_USER_INFO_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        profileDashboardLoading: false,
      };
    case EDIT_VIDEO:
      return {
        ...state,
        errorMessage: '',
      };
    case EDIT_VIDEO_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case DELETE_VIDEO:
      return {
        ...state,
        errorMessage: '',
      };
    case DELETE_VIDEO_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case APPROVE_SUGGESTED_VIDEO:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case APPROVE_SUGGESTED_VIDEO_ERROR:
      return {
        ...state,
        errorMessage: '',
      };
    case EDIT_QUIZ:
      return {
        ...state,
        errorMessage: '',
      };
    case EDIT_QUIZ_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case DELETE_QUIZ:
      return {
        ...state,
        errorMessage: '',
      };
    case DELETE_QUIZ_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case CLEAR_PROFILE:
      return {
        ...state,
        uuid: '',
        email: '',
        firstName: '',
        lastName: '',
        avatar: '',
        profile: [],
        myFlows: [],
        myVideos: [],
        myQuizzes: [],
        myCertificates: [],
        videoSuggestions: [],
        errorMessage: '',
        profileLoading: false,
        profileDashboardLoading: false,
      };
    case PROFILE_LOADING:
      return {
        ...state,
        profileLoading: true,
      };
    case PROFILE_DASHBOARD_LOADING:
      return {
        ...state,
        profileDashboardLoading: true,
      };
    default:
      return state;
  }
};

export default profileReducer;
