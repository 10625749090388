export const formatSeconds = (seconds) => {
  if (seconds) {
    const secondsRounded = seconds.toFixed(0);

    var hrs = ~~(secondsRounded / 3600);
    var mins = ~~((secondsRounded % 3600) / 60);
    var secs = ~~secondsRounded % 60;
    var ret = '';

    if (hrs > 0) {
      ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
    }

    ret += '' + mins + ':' + (secs < 10 ? '0' : '');
    ret += '' + secs;

    return ret;
  } else {
    return '0:00';
  }
};
