import {
  CREATE_TOPIC_CERTIFICATE,
  CREATE_TOPIC_CERTIFICATE_ERROR,
  CREATE_PDF,
  CREATE_PDF_ERROR,
  VERIFY_CERTIFICATE,
  VERIFY_CERTIFICATE_ERROR,
  CLEAR_CERTIFICATE_STATE,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  newCertificate: '',
  topicPDF: null,
  flowPDF: null,
  certificate: '',
};

const quizReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_TOPIC_CERTIFICATE:
      return {
        ...state,
        newCertificate: action.payload,
        errorMessage: '',
      };
    case CREATE_TOPIC_CERTIFICATE_ERROR:
      return {
        ...state,
        newCertificate: '',
        errorMessage: action.payload,
      };
    case CREATE_PDF:
      return {
        ...state,
        topicPDF: action.payload.topic_pdf,
        flowPDF: action.payload.flow_pdf,
        errorMessage: '',
      };
    case CREATE_PDF_ERROR:
      return {
        ...state,
        topicPDF: null,
        flowPDF: null,
      };
    case VERIFY_CERTIFICATE:
      return {
        ...state,
        certificate: action.payload,
        errorMessage: '',
      };
    case VERIFY_CERTIFICATE_ERROR:
      return {
        ...state,
        certificate: '',
        errorMessage: action.payload,
      };
    case CLEAR_CERTIFICATE_STATE:
      return {
        ...state,
        newCertificate: '',
        topicPDF: null,
        flowPDF: null,
        certificate: '',
      };
    default:
      return state;
  }
};

export default quizReducer;
