import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RadioGroup, FormControlLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import blankThumbnail from '../../images/blank-thumbnail.png';
import cardThumbnail from '../../images/card-thumbnail.png';
import shareFlow from '../../images/share-flow-icon.png';

import { editFlow } from '../../actions/flowAction';
import { MAXIMUM_TITLE_LENGTH, MAXIMUM_DESCRIPTION_LENGTH } from '../../actions/constants';

import CardStats from '../CardStats/CardStats';
import Tag from '../Tag/Tag';
import StyledRadio from '../StyledRadio/StyledRadio';
import TagModal from '../TagModal/TagModal';
import SocialShareModal from '../SocialShareModal/SocialShareModal';

import './FlowCard.scss';

const FlowCard = (props) => {
  const flowState = useSelector((state) => state.flow);

  const [title, setTitle] = useState('-');
  const [description, setDescription] = useState('-');
  const [visibility, setVisibility] = useState('just_me');
  const [tags, setTags] = useState('');
  const [thumbnail, setThumbnail] = useState(blankThumbnail);
  const [modalShow, setModalShow] = useState(false);
  const [socialShareModalShow, setSocialShareModalShow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setTitle(flowState.title);
    setDescription(flowState.description);
    setVisibility(flowState.visibility);
    setTags(flowState.tags);
    setThumbnail(flowState.thumbnail);
  }, [flowState]);

  const triggerFlowThumbnailInput = () => {
    props.editMode && document.querySelector('.flow-card-thumbnail-input').click();
  };

  const handleFlowThumbnailUpload = (e) => {
    if (e.target.files[0]) {
      setThumbnail(URL.createObjectURL(e.target.files[0]));

      const formData = new FormData();

      formData.append('thumbnail', e.target.files[0]);

      dispatch(editFlow(flowState.uuid, formData));
    }
  };

  const handleRemoveFlowThumbnail = (e) => {
    e.stopPropagation();

    setThumbnail('');
    document.querySelector('.flow-card-thumbnail-input').value = '';

    const formData = new FormData();

    formData.append('thumbnail', '');

    dispatch(editFlow(flowState.uuid, formData));
  };

  const handleTitleChange = (e) => {
    if (e.target.value !== flowState.title) {
      e.preventDefault();

      const formData = new FormData();

      formData.append('title', title);

      dispatch(editFlow(flowState.uuid, formData));
    }
  };

  const handleDescriptionChange = (e) => {
    if (e.target.value !== flowState.description) {
      e.preventDefault();

      const formData = new FormData();

      formData.append('description', description);

      dispatch(editFlow(flowState.uuid, formData));
    }
  };

  const handleVisibilityChange = (e) => {
    setVisibility(e.target.value);

    const formData = new FormData();

    formData.append('visibility', e.target.value);

    dispatch(editFlow(flowState.uuid, formData));
  };

  const handleTagsChange = (modalTags) => {
    setTags(modalTags);

    const formData = new FormData();

    formData.append('tags', JSON.stringify(modalTags));

    dispatch(editFlow(flowState.uuid, formData));
  };

  const removeTag = (tagId) => {
    const newTags = tags.filter((tag, index) => index !== tagId);

    setTags((prevState) => prevState.filter((tag, index) => index !== tagId));

    const formData = new FormData();

    formData.append('tags', JSON.stringify(newTags));

    dispatch(editFlow(flowState.uuid, formData));
  };

  return (
    <>
      <div className="flow-card-wrapper">
        <form className="flow-form" encType="multipart/form-data">
          <div
            className="flow-card-thumbnail"
            style={{
              backgroundImage: `url(${
                thumbnail ? thumbnail : props.editMode ? cardThumbnail : blankThumbnail
              })`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              cursor: props.editMode ? 'pointer' : 'unset',
            }}
            onClick={triggerFlowThumbnailInput}
          >
            <input
              className="flow-card-thumbnail-input"
              name="flow-card-thumbnail"
              type="file"
              accept="image/jpg, image/jpeg, image/png"
              style={{ display: 'none' }}
              onChange={handleFlowThumbnailUpload}
            />
            {thumbnail && props.editMode && (
              <div className="flow-card-thumbnail-overlay" onClick={handleRemoveFlowThumbnail}>
                <FontAwesomeIcon icon={faTimes} className="cross-icon" />
              </div>
            )}
          </div>
          <div className="flow-card-details-wrapper">
            <div className="flow-card-details-div-1">
              <div className="flow-card-title-and-share-button-wrapper">
                <div className="flow-card-title-textarea-and-stats-wrapper">
                  <textarea
                    className="flow-card-title-textarea"
                    maxLength={`${MAXIMUM_TITLE_LENGTH}`}
                    placeholder={props.editMode ? 'Flow Title' : ''}
                    disabled={props.editMode ? false : true}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    onBlur={handleTitleChange}
                  />
                  <CardStats
                    views={flowState.views}
                    likes={flowState.likes}
                    videos={flowState.topics
                      .map((topic) => topic.videos.length)
                      .reduce((a, b) => a + b, 0)}
                    quizzes={flowState.topics
                      .map((topic) => topic.quizzes.length)
                      .reduce((a, b) => a + b, 0)}
                    width="60%"
                    margin="6px 6px 15px"
                  />
                </div>
                <div
                  className="share-flow-button"
                  style={{
                    opacity: props.editMode ? '0.3' : '1',
                    pointerEvents: props.editMode ? 'none' : 'auto',
                  }}
                  onClick={() => setSocialShareModalShow(true)}
                >
                  <img src={shareFlow} alt="share-flow" />
                  <h3 className="flow-card-share-flow">Share Flow</h3>
                </div>
              </div>
              <textarea
                className="flow-card-description-textarea"
                maxLength={`${MAXIMUM_DESCRIPTION_LENGTH}`}
                placeholder={props.editMode ? 'Click here to start writing the description' : ''}
                disabled={props.editMode ? false : true}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onBlur={handleDescriptionChange}
              />
            </div>
            <div className="flow-card-details-div-2">
              <div className="flow-card-owner-wrapper">
                <FontAwesomeIcon icon={faUser} className="user-icon" />
                <p className="flow-card-owner-name">{flowState.creatorName}</p>
              </div>
              {props.editMode && (
                <div className="flow-card-visibility-wrapper">
                  <h3 className="flow-card-visibility-heading">Visibility</h3>
                  <RadioGroup
                    value={visibility}
                    aria-label="visibility"
                    name="styled-visibility-radios"
                    onChange={handleVisibilityChange}
                  >
                    <FormControlLabel value="just_me" control={<StyledRadio />} label="Me" />
                    <FormControlLabel
                      value="registered_users"
                      control={<StyledRadio />}
                      label="Registered Users"
                    />
                    <FormControlLabel value="everyone" control={<StyledRadio />} label="Everyone" />
                  </RadioGroup>
                </div>
              )}
              <div className="flow-card-tags-row">
                {tags.length ? (
                  tags.map((tag, index) => (
                    <Tag
                      key={index}
                      tagId={index}
                      tagText={tag}
                      tagType="flow"
                      editMode={props.editMode}
                      removeTag={removeTag}
                    />
                  ))
                ) : (
                  <p className="flow-card-no-tags">No Tags</p>
                )}
                {props.editMode && (
                  <div className="flow-card-add-tag-button" onClick={() => setModalShow(true)}>
                    <FontAwesomeIcon icon={faPlus} className="add-icon" />
                    <p className="flow-card-add-tag-button-text">Add Tag</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <TagModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        tagType="flow"
        tags={tags}
        setTags={handleTagsChange}
      />
      <SocialShareModal
        show={socialShareModalShow}
        onHide={() => setSocialShareModalShow(false)}
        url={`${window.location.origin}/flow/${flowState.uuid}`}
      />
    </>
  );
};

export default FlowCard;
