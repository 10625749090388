import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import blankThumbnail from '../../images/blank-thumbnail.png';

import { incrementQuizTry } from '../../actions/quizAction';

import CardStats from '../CardStats/CardStats';
import TakeQuizWindow from '../TakeQuizWindow/TakeQuizWindow';
import Tag from '../Tag/Tag';

import './QuizWindowModal.scss';

const QuizWindowModal = (props) => {
  const [isStartQuiz, setIsStartQuiz] = useState(false);
  const [customLeaveQuizModalShow, setCustomLeaveQuizModalShow] = useState(false);

  const dispatch = useDispatch();

  const handleCancelQuiz = () => {
    setIsStartQuiz(false);
    props.onHide();
  };

  const handleStartQuiz = () => {
    setIsStartQuiz(true);
    if (localStorage.getItem('accessToken')) {
      dispatch(incrementQuizTry(props.quiz.uuid));
    }
  };

  return (
    <Modal {...props} centered backdrop="static" className="quiz-window-modal">
      {!isStartQuiz ? (
        <div className="start-quiz-window">
          <div className="quiz-header">
            <div
              className="quiz-thumbnail"
              style={{
                backgroundImage: `url(${
                  props.quiz?.thumbnail ? props.quiz.thumbnail : blankThumbnail
                })`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            ></div>
            <div className="quiz-title-wrapper">
              <h3 className="quiz-title">{props.quiz?.title}</h3>
              <div className="quiz-tags-row">
                {props.quiz?.tags.length
                  ? props.quiz.tags.map((tag, index) => (
                      <Tag
                        key={index}
                        tagId={index}
                        tagText={tag}
                        tagType="topic"
                        editMode={false}
                      />
                    ))
                  : null}
              </div>
            </div>
            <div className="quiz-stats">
              <CardStats
                tries={props.quiz?.try_count}
                percentageOfTriesToPass={props.quiz?.average_try_count}
                questions={props.quiz?.questions.length}
                width="100%"
                margin="0"
              />
              {props.quiz?.average_time > 0 && (
                <CardStats averageTime={props.quiz.average_time} width="auto" margin="10px 0 0" />
              )}
              {props.quiz?.estimated_time > 0 && (
                <CardStats
                  estimatedTime={props.quiz.estimated_time}
                  width="auto"
                  margin="10px 0 0"
                />
              )}
            </div>
          </div>
          <div className="quiz-body">
            <p className="quiz-description">{props.quiz?.description}</p>
          </div>
          <div className="quiz-footer">
            <button className="cancel-quiz-button" type="button" onClick={handleCancelQuiz}>
              Cancel
            </button>
            <button className="start-quiz-button" type="button" onClick={handleStartQuiz}>
              Start Quiz
            </button>
          </div>
        </div>
      ) : (
        <TakeQuizWindow
          topicUuid={props.topicUuid}
          quiz={props.quiz}
          setIsStartQuiz={setIsStartQuiz}
          customLeaveQuizModalShow={customLeaveQuizModalShow}
          setCustomLeaveQuizModalShow={setCustomLeaveQuizModalShow}
          onHide={props.onHide}
        />
      )}
    </Modal>
  );
};

export default QuizWindowModal;
