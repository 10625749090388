import {
  CREATE_FLOW,
  CREATE_FLOW_ERROR,
  GET_FLOW,
  GET_FLOW_ERROR,
  GET_ALL_FLOWS,
  GET_ALL_FLOWS_ERROR,
  GET_FEATURED_FLOWS,
  GET_FEATURED_FLOWS_ERROR,
  APPEND_FLOWS,
  APPEND_FLOWS_ERROR,
  SEARCH_FLOWS,
  SEARCH_FLOWS_ERROR,
  CLEAR_SEARCHED_FLOWS,
  CLEAR_ALL_FLOWS,
  EDIT_FLOW,
  EDIT_FLOW_ERROR,
  DELETE_FLOW,
  DELETE_FLOW_ERROR,
  CLEAR_FLOW_STATE,
  SEARCH_FLOW_LOADING,
  FLOW_LOADING,
  CLEAR_LOADING,
  LOAD_SEARCH_RESULTS,
  INCREMENT_SEARCH_FLOWS_API_COUNT,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  uuid: '',
  title: '',
  description: '',
  views: 0,
  likes: 0,
  visibility: 'just_me',
  tags: [],
  thumbnail: '',
  creatorUuid: '',
  creatorName: '',
  topics: [],

  flowCreated: false,

  featuredFlows: [],
  allFlows: [],
  next: null,
  searchedFlows: [],
  loadSearchResults: false,
  searchFlowsAPICount: 0,

  editMode: false,
  errorMessage: '',
  flowLoading: false,
};

const flowReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_FLOW:
      return {
        ...state,
        uuid: action.payload.uuid,
        title: action.payload.title,
        description: action.payload.description,
        views: action.payload.views,
        likes: action.payload.likes,
        visibility: action.payload.visibility,
        tags: action.payload.tags,
        thumbnail: action.payload.thumbnail,
        creatorUuid: action.payload.creator,
        creatorName: action.payload.creator_name,
        topics: action.payload.topics,
        flowCreated: true,
        errorMessage: '',
        flowLoading: false,
      };
    case CREATE_FLOW_ERROR:
      return {
        ...state,
        uuid: '',
        title: '',
        description: '',
        views: 0,
        likes: 0,
        visibility: 'just_me',
        tags: [],
        thumbnail: '',
        creatorUuid: '',
        creatorName: '',
        topics: [],
        flowCreated: false,
        errorMessage: action.payload,
        flowLoading: false,
      };
    case GET_FLOW:
      return {
        ...state,
        uuid: action.payload.uuid,
        title: action.payload.title,
        description: action.payload.description,
        views: action.payload.views,
        likes: action.payload.likes,
        visibility: action.payload.visibility,
        tags: action.payload.tags,
        thumbnail: action.payload.thumbnail,
        creatorUuid: action.payload.creator,
        creatorName: action.payload.creator_name,
        topics: action.payload.topics,
        flowCreated: false,
        errorMessage: '',
        flowLoading: false,
      };
    case GET_FLOW_ERROR:
      return {
        ...state,
        uuid: '',
        title: '',
        description: '',
        views: 0,
        likes: 0,
        visibility: 'just_me',
        tags: [],
        thumbnail: '',
        creatorUuid: '',
        creatorName: '',
        topics: [],
        errorMessage: action.payload,
        flowCreated: false,
        flowLoading: false,
      };
    case GET_ALL_FLOWS:
      return {
        ...state,
        allFlows: action.payload.results,
        next: action.payload.next,
        errorMessage: '',
        flowLoading: false,
      };
    case GET_ALL_FLOWS_ERROR:
      return {
        ...state,
        allFlows: [],
        next: null,
        errorMessage: action.payload,
        flowLoading: false,
      };
    case GET_FEATURED_FLOWS:
      return {
        ...state,
        featuredFlows: action.payload,
        errorMessage: '',
      };
    case GET_FEATURED_FLOWS_ERROR:
      return {
        ...state,
        featuredFlows: [],
        errorMessage: action.payload,
      };
    case APPEND_FLOWS:
      return {
        ...state,
        allFlows: [...state.allFlows, ...action.payload.results],
        next: action.payload.next,
        errorMessage: '',
        flowLoading: false,
      };
    case APPEND_FLOWS_ERROR:
      return {
        ...state,
        allFlows: [],
        next: null,
        errorMessage: action.payload,
        flowLoading: false,
      };
    case SEARCH_FLOWS:
      return {
        ...state,
        searchedFlows: action.payload,
        errorMessage: '',
        searchFlowLoading: false,
        searchFlowsAPICount: state.searchFlowsAPICount - 1,
      };
    case SEARCH_FLOWS_ERROR:
      return {
        ...state,
        searchedFlows: [],
        errorMessage: action.payload,
        searchFlowLoading: false,
        searchFlowsAPICount: state.searchFlowsAPICount - 1,
      };
    case CLEAR_SEARCHED_FLOWS:
      return {
        ...state,
        searchedFlows: [],
        searchFlowLoading: false,
      };
    case CLEAR_ALL_FLOWS:
      return {
        ...state,
        allFlows: [],
        flowLoading: false,
      };
    case EDIT_FLOW:
      return {
        ...state,
        uuid: action.payload.uuid,
        title: action.payload.title,
        description: action.payload.description,
        views: action.payload.views,
        likes: action.payload.likes,
        visibility: action.payload.visibility,
        tags: action.payload.tags,
        thumbnail: action.payload.thumbnail,
        creatorUuid: action.payload.creator,
        creatorName: action.payload.creator_name,
        topics: action.payload.topics,
        errorMessage: '',
        flowLoading: false,
      };
    case EDIT_FLOW_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        flowLoading: false,
      };
    case DELETE_FLOW:
      return {
        ...state,
        uuid: '',
        title: '',
        description: '',
        views: 0,
        likes: 0,
        visibility: 'just_me',
        tags: [],
        thumbnail: '',
        creatorUuid: '',
        creatorName: '',
        topics: [],
        editMode: false,
        errorMessage: '',
        flowLoading: false,
      };
    case DELETE_FLOW_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        flowLoading: false,
      };
    case CLEAR_FLOW_STATE:
      return {
        ...state,
        uuid: '',
        title: '',
        description: '',
        views: 0,
        likes: 0,
        visibility: 'just_me',
        tags: [],
        thumbnail: '',
        creatorUuid: '',
        creatorName: '',
        topics: [],
        errorMessage: '',
        editMode: false,
        flowLoading: false,
      };
    case SEARCH_FLOW_LOADING:
      return {
        ...state,
        searchFlowLoading: true,
      };
    case FLOW_LOADING:
      return {
        ...state,
        flowLoading: true,
      };
    case CLEAR_LOADING:
      return {
        ...state,
        flowLoading: false,
      };
    case LOAD_SEARCH_RESULTS:
      return {
        ...state,
        loadSearchResults: action.payload,
      };
    case INCREMENT_SEARCH_FLOWS_API_COUNT:
      return {
        ...state,
        searchFlowsAPICount: state.searchFlowsAPICount + 1,
      };
    default:
      return state;
  }
};

export default flowReducer;
