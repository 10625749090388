import { combineReducers } from 'redux';

import authReducer from './authReducer';
import profileReducer from './profileReducer';
import navbarReducer from './navbarReducer.js';
import flowReducer from './flowReducer';
import topicReducer from './topicReducer';
import videoReducer from './videoReducer';
import quizReducer from './quizReducer';
import commentReducer from './commentReducer';
import certificateReducer from './certificateReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  navbar: navbarReducer,
  flow: flowReducer,
  topic: topicReducer,
  video: videoReducer,
  quiz: quizReducer,
  comment: commentReducer,
  certificate: certificateReducer,
});

export default rootReducer;
