import React from 'react';
import { Spinner } from 'react-bootstrap';

import './Loader.scss';

const Loader = (props) => {
  return (
    <div
      className="loader"
      style={{
        margin: props.margin ? props.margin : '84px 0 0',
        padding: props.padding ? props.padding : '0',
        height: props.height ? props.height : '70vh',
        width: props.width ? props.width : '100%',
      }}
    >
      <Spinner
        className="spinner"
        style={{
          width: props.svgWidth ? props.svgWidth : '2rem',
          height: props.svgHeight ? props.svgHeight : '2rem',
          borderWidth: props.svgBorderWidth ? props.svgBorderWidth : '0.25em',
        }}
        role="status"
        animation="border"
      />
      {props.customMessage && <p className="custom-loading-message">{props.customMessage}</p>}
    </div>
  );
};

export default Loader;
