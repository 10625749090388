import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbsUp,
  faEye,
  faStickyNote,
  faPlayCircle,
  faUser,
} from '@fortawesome/free-regular-svg-icons';
import { faShareSquare, faPlay } from '@fortawesome/free-solid-svg-icons';
import bookIcon from '../../images/book-icon.png';
import blankThumbnail from '../../images/blank-thumbnail.png';

import Tag from '../Tag/Tag';
import SocialShareModal from '../SocialShareModal/SocialShareModal';

import './HomeFlow.scss';

const HomeFlow = (props) => {
  const [socialShareModalShow, setSocialShareModalShow] = useState(false);

  const history = useHistory();

  return (
    <>
      <div className="home-flow-wrapper" key={props.id}>
        <h3 className="home-flow-title">{props.title}</h3>
        <div
          className="home-flow-thumbnail"
          style={{
            backgroundImage: `url(${props.thumbnail ? props.thumbnail : blankThumbnail})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div
            className="home-flow-thumbnail-overlay"
            onClick={() => history.push(`/flow/${props.flowId}`)}
          >
            <FontAwesomeIcon icon={faPlay} className="play-icon" />
            <h3 className="home-flow-play-flow">Play Flow</h3>
          </div>
        </div>
        <div className="home-flow-stats-row">
          <div className="home-flow-stats-wrapper">
            <FontAwesomeIcon icon={faEye} className="home-flow-stats-icon" />
            <p className="home-flow-stats">
              <NumberFormat
                value={props.views}
                displayType={'text'}
                thousandSeparator={true}
                suffix={props.views === 1 ? ' View' : ' Views'}
              />
            </p>
          </div>
          <div className="home-flow-stats-wrapper">
            <FontAwesomeIcon icon={faPlayCircle} className="home-flow-stats-icon" />
            <p className="home-flow-stats">
              <NumberFormat
                value={props.videos}
                displayType={'text'}
                thousandSeparator={true}
                suffix={props.videos === 1 ? ' Video' : ' Videos'}
              />
            </p>
          </div>
          <div className="home-flow-stats-wrapper">
            <FontAwesomeIcon icon={faThumbsUp} className="home-flow-stats-icon" />
            <p className="home-flow-stats">
              <NumberFormat
                value={props.likes}
                displayType={'text'}
                thousandSeparator={true}
                suffix={props.likes === 1 ? ' Like' : ' Likes'}
              />
            </p>
          </div>
        </div>
        <div className="home-flow-stats-row">
          <div className="home-flow-stats-wrapper">
            <img className="home-flow-topics-icon" src={bookIcon} alt="book-icon" />
            <p className="home-flow-stats">
              <NumberFormat
                value={props.topics}
                displayType={'text'}
                thousandSeparator={true}
                suffix={props.topics === 1 ? ' Topic' : ' Topics'}
              />
            </p>
          </div>
          <div className="home-flow-stats-wrapper">
            <FontAwesomeIcon icon={faStickyNote} className="home-flow-stats-icon" />
            <p className="home-flow-stats">
              <NumberFormat
                value={props.quizzes}
                displayType={'text'}
                thousandSeparator={true}
                suffix={props.quizzes === 1 ? ' Quiz' : ' Quizzes'}
              />
            </p>
          </div>
          <div className="home-flow-share-wrapper" onClick={() => setSocialShareModalShow(true)}>
            <FontAwesomeIcon icon={faShareSquare} className="home-flow-stats-icon" />
            <p className="home-flow-stats">Share</p>
          </div>
        </div>
        <div className="home-flow-tags-row">
          {props.tags.length
            ? props.tags.map((tag, index) => (
                <Tag key={index} tagId={index} tagText={tag} tagType="flow" editMode={false} />
              ))
            : null}
        </div>
        <p className="home-flow-description">{props.description}</p>
        <div className="home-flow-owner-wrapper">
          <FontAwesomeIcon icon={faUser} className="home-flow-user-icon" />
          <p className="home-flow-owner-name">{props.creatorName}</p>
        </div>
      </div>
      <SocialShareModal
        show={socialShareModalShow}
        onHide={() => setSocialShareModalShow(false)}
        url={`${window.location.origin}/flow/${props.flowId}`}
      />
    </>
  );
};

export default HomeFlow;
