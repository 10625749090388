import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import NumberFormat from 'react-number-format';

import HomeFlow from '../../HomeFlow/HomeFlow';
import Loader from '../../Loader/Loader';

import './SearchFlows.scss';

const SearchFlows = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(12);

  const flowState = useSelector((state) => state.flow);

  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = flowState.searchedFlows.slice(indexOfFirstResult, indexOfLastResult);

  const pageNumbers =
    flowState.searchFlowsAPICount === 0
      ? Math.ceil(flowState.searchedFlows.length / resultsPerPage)
      : 0;

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  return flowState.loadSearchResults && flowState.searchFlowsAPICount > 0 ? (
    <Loader />
  ) : (
    <div className="my-container search-flows-page-container">
      <div className="search-flows-page">
        <div className="search-flows-heading-and-results-wrapper">
          <div className="heading-row">
            <h1 className="search-results-heading">Search Results</h1>
            <p className="search-results-count-text">
              {flowState.searchFlowsAPICount === 0 ? (
                <NumberFormat
                  value={currentResults.length}
                  displayType={'text'}
                  thousandSeparator={true}
                  suffix={currentResults.length === 1 ? ' Result Found' : ' Results Found'}
                />
              ) : null}
            </p>
          </div>
          {currentResults.length && flowState.loadSearchResults ? (
            <div className="search-flows-list-grid">
              {currentResults.map((flow) => (
                <HomeFlow
                  key={flow.uuid}
                  flowId={flow.uuid}
                  title={flow.title}
                  thumbnail={flow.thumbnail}
                  views={flow.views}
                  videos={flow.topics
                    .map((topic) => topic.videos.length)
                    .reduce((a, b) => a + b, 0)}
                  likes={flow.likes}
                  topics={flow.topics.length}
                  quizzes={flow.topics
                    .map((topic) => topic.quizzes.length)
                    .reduce((a, b) => a + b, 0)}
                  tags={flow.tags}
                  description={flow.description}
                  creatorName={flow.creator_name}
                />
              ))}
            </div>
          ) : null}
        </div>
        <Pagination
          className="search-flows-pagination"
          count={pageNumbers}
          onChange={(e, page) => handlePageChange(page)}
        />
      </div>
    </div>
  );
};

export default SearchFlows;
