import axiosApiInstance from './axios';

import { url } from './constants';

import {
  CREATE_TOPIC,
  CREATE_TOPIC_ERROR,
  DELETE_TOPIC,
  DELETE_TOPIC_ERROR,
  EDIT_TOPIC,
  EDIT_TOPIC_ERROR,
  CLEAR_TOPIC_CREATED,
  TOPIC_LOADING,
  GET_FLOW,
} from './actionTypes';

export const createTopic = (formData, flowUuid) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.post(`${url}/flow/topic/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    dispatch({
      type: CREATE_TOPIC,
      payload: res.data,
    });

    const res2 = await axiosApiInstance.get(`${url}/flow/get_flows/${flowUuid}/`);

    dispatch({
      type: GET_FLOW,
      payload: res2.data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_TOPIC_ERROR,
      payload: err.response,
    });
  }
};

export const editTopic = (formData, topicUuid, flowUuid) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.patch(`${url}/flow/topic/${topicUuid}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
    });

    dispatch({
      type: EDIT_TOPIC,
      payload: res.data,
    });

    const res2 = await axiosApiInstance.get(`${url}/flow/get_flows/${flowUuid}/`);

    dispatch({
      type: GET_FLOW,
      payload: res2.data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_TOPIC_ERROR,
      payload: err.response,
    });
  }
};

export const deleteTopic = (topicUuid, flowUuid) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.delete(`${url}/flow/topic/${topicUuid}`);

    dispatch({
      type: DELETE_TOPIC,
      payload: res.data,
    });

    const res2 = await axiosApiInstance.get(`${url}/flow/get_flows/${flowUuid}/`);

    dispatch({
      type: GET_FLOW,
      payload: res2.data,
    });
  } catch (err) {
    dispatch({
      type: DELETE_TOPIC_ERROR,
      payload: err.response,
    });
  }
};

export const clearTopicCreated = () => (dispatch) => {
  dispatch({
    type: CLEAR_TOPIC_CREATED,
  });
};

export const setTopicLoading = () => (dispatch) => {
  dispatch({
    type: TOPIC_LOADING,
  });
};
