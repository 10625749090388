import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import blankPicture from '../../images/blank-image.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import SearchBar from 'material-ui-search-bar';

import { toggleProfileMenu } from '../../actions/navbarAction';
import {
  searchFlows,
  clearSearchedFlows,
  setSearchFlowLoading,
  setLoadSearchResults,
  incrementSearchFlowsAPICount,
} from '../../actions/flowAction';

import NavAuthButton from '../NavAuthButton/NavAuthButton';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import SearchSuggestions from '../SearchSuggestions/SearchSuggestions';

import './NavbarMenu.scss';

const NavbarMenu = () => {
  const [overlay, setOverlay] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebounce(searchText, 1000);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [enterPressed, setEnterPressed] = useState(false);

  const navbarState = useSelector((state) => state.navbar);
  const profileState = useSelector((state) => state.profile);
  const flowState = useSelector((state) => state.flow);

  const history = useHistory();

  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (debouncedSearchText && !enterPressed) {
      dispatch(setSearchFlowLoading());
      dispatch(setLoadSearchResults(false));
      dispatch(incrementSearchFlowsAPICount());
      dispatch(searchFlows(debouncedSearchText));
      setShowSuggestions(true);
    }
  }, [debouncedSearchText]);

  const handleNewFlow = () => {
    if (!flowState.flowLoading) {
      if (localStorage.getItem('accessToken')) {
        history.push('/create-flow');
      } else {
        history.push('/login');
      }
    }
  };

  const handleLogin = () => {
    history.push('/login');
  };

  const handleSignUp = () => {
    history.push('/register');
  };

  const handleCancelSearch = () => {
    setSearchText('');
    dispatch(clearSearchedFlows());
    dispatch(setLoadSearchResults(false));
    setShowSuggestions(false);

    if (location.pathname === '/search-flows') {
      setOverlay(false);
      history.go(-1);
    }
  };

  const handleSearchEnter = (e) => {
    if (e.keyCode == 13) {
      setEnterPressed(true);

      if (e.target.value) {
        dispatch(setSearchFlowLoading());
        dispatch(incrementSearchFlowsAPICount());
        dispatch(searchFlows(e.target.value));
        dispatch(setLoadSearchResults(true));

        setOverlay(false);
        setShowSuggestions(false);

        e.target.blur();

        if (location.pathname !== '/search-flows') {
          history.push('/search-flows');
        }
      } else {
        dispatch(clearSearchedFlows());
      }
    }
  };

  const handleSearchFocus = () => {
    setOverlay(true);
    setEnterPressed(false);
  };

  const handleOverlayClick = () => {
    setOverlay(false);
    setShowSuggestions(false);
  };

  const navbarProfileStyles = {
    backgroundColor: navbarState.showProfileMenu ? '#fff' : '#FAFAFA',
    zIndex: navbarState.showProfileMenu ? '999' : '0',
  };

  return (
    <div className="navbar-menu">
      {overlay ? <div id="overlay" onClick={handleOverlayClick}></div> : null}
      <div
        className="navbar-search-bar"
        style={{
          backgroundColor: overlay ? '#fff' : 'rgba(249, 249, 249, 0.9)',
          zIndex: overlay ? '999' : '0',
        }}
      >
        <SearchBar
          placeholder="Search"
          value={searchText}
          onChange={(newValue) => setSearchText(newValue)}
          onCancelSearch={handleCancelSearch}
          onFocus={handleSearchFocus}
          onKeyDown={(e) => handleSearchEnter(e)}
        />
        {flowState.searchedFlows.length > 0 && showSuggestions && (
          <SearchSuggestions
            suggestions={flowState.searchedFlows.slice(0, 6)}
            setSearchText={setSearchText}
            setOverlay={setOverlay}
            setShowSuggestions={setShowSuggestions}
            setEnterPressed={setEnterPressed}
          />
        )}
      </div>
      <div className="nav-menu-buttons-wrapper">
        <div className="new-flow-button-wrapper" onClick={handleNewFlow}>
          <FontAwesomeIcon icon={faPlus} className="new-flow-button-icon" />
          <h3>New Flow</h3>
        </div>
        {localStorage.getItem('accessToken') ? (
          <div
            className="navbar-profile-wrapper"
            style={navbarProfileStyles}
            onClick={() => dispatch(toggleProfileMenu())}
          >
            {profileState.firstName ? <p>{profileState.firstName}</p> : null}
            <div className="navbar-profile-image-wrapper">
              <img
                className="navbar-profile-image"
                src={profileState.avatar ? profileState.avatar : blankPicture}
                alt="profile-picture"
              />
            </div>
          </div>
        ) : (
          <div className="auth-buttons-wrapper">
            <NavAuthButton
              className="sign-up-button-wrapper"
              text="Sign Up"
              onClick={handleSignUp}
            />
            <NavAuthButton
              className="sign-in-button-wrapper"
              text="Sign In"
              onClick={handleLogin}
            />
          </div>
        )}
        {navbarState.showProfileMenu ? <ProfileMenu isMobile={false} /> : null}
      </div>
    </div>
  );
};

export default NavbarMenu;
