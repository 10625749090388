import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faExpandArrowsAlt,
  faTimesCircle,
  faPlay,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';
import { scroller } from 'react-scroll';
import blankThumbnail from '../../images/blank-thumbnail.png';
import cardThumbnail from '../../images/card-thumbnail.png';
import shareFlow from '../../images/share-flow-icon.png';
import videoIconWhite from '../../images/video-icon-white.png';

import { getMyVideos, setVideoLoading } from '../../actions/videoAction';
import { createTopic, deleteTopic, editTopic, clearTopicCreated } from '../../actions/topicAction';
import { MAXIMUM_TITLE_LENGTH, MAXIMUM_DESCRIPTION_LENGTH } from '../../actions/constants';

import CardStats from '../CardStats/CardStats';
import Tag from '../Tag/Tag';
import TagModal from '../TagModal/TagModal';
import TopicCardExpanded from '../TopicCardExpanded/TopicCardExpanded';
import CustomModal from '../CustomModal/CustomModal';
import SocialShareModal from '../SocialShareModal/SocialShareModal';
import AddTopicVideoModal from '../AddTopicVideoModal/AddTopicVideoModal';

import './TopicCardCollapsed.scss';

const TopicCardCollapsed = (props) => {
  const flowState = useSelector((state) => state.flow);

  const [title, setTitle] = useState(props.topic.title);
  const [description, setDescription] = useState(props.topic.description);
  const [tags, setTags] = useState(props.topic.tags);
  const [thumbnail, setThumbnail] = useState(props.topic.thumbnail);
  const [suggestion, setSuggestion] = useState(props.topic.suggestion);

  const [suggestTopicVideoModalShow, setSuggestTopicVideoModalShow] = useState(false);
  const [customModalShow, setCustomModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [socialShareModalShow, setSocialShareModalShow] = useState(false);
  const [expandCard, setExpandCard] = useState(false);

  const dispatch = useDispatch();

  const match = useRouteMatch();

  useEffect(() => {
    if (match.params.topicUuid && match.params.topicUuid === props.topic.uuid) {
      scroller.scrollTo(match.params.topicUuid, {
        duration: 500,
        smooth: true,
        offset: -75,
      });
      setExpandCard(true);
    } else if (
      !match.params.topicUuid &&
      props.topics[0]?.featured_video &&
      props.topics[0]?.uuid === props.topic.uuid
    ) {
      scroller.scrollTo(props.topic.uuid, {
        duration: 500,
        smooth: true,
        offset: -75,
      });
      setExpandCard(true);
    }
  }, []);

  const handleAddTopic = async (e) => {
    document.getElementById('addTopicButton').disabled = true;

    e.preventDefault();

    const formData = new FormData();

    formData.append('flow', flowState.uuid);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('tags', JSON.stringify(tags));
    formData.append('thumbnail', thumbnail);
    formData.append('suggestion', suggestion);

    dispatch(createTopic(formData, match.params.uuid));
    dispatch(clearTopicCreated());
    props.setIsAddTopic(false);
  };

  const triggerTopicThumbnailInput = (topicUuid) => {
    document.querySelector(`.topic-card-collapsed-thumbnail-input-${topicUuid}`).click();
  };

  const handleTopicThumbnailUpload = (e) => {
    if (e.target.files[0]) {
      setThumbnail(URL.createObjectURL(e.target.files[0]));

      const formData = new FormData();

      formData.append('thumbnail', e.target.files[0]);

      dispatch(editTopic(formData, props.topic.uuid, match.params.uuid));
    }
  };

  const handleRemoveTopicThumbnail = (e, topicUuid) => {
    e.stopPropagation();

    setThumbnail('');

    const formData = new FormData();

    formData.append('thumbnail', '');

    dispatch(editTopic(formData, props.topic.uuid, match.params.uuid));
  };

  const handleEditTopic = () => {};

  const handleDeleteTopic = () => {
    dispatch(deleteTopic(props.topic.uuid, match.params.uuid));
  };

  const handleTitleChange = (e) => {
    if (e.target.value !== props.topic.title) {
      e.preventDefault();

      const formData = new FormData();

      formData.append('title', title);

      dispatch(editTopic(formData, props.topic.uuid, match.params.uuid));
    }
  };

  const handleDescriptionChange = (e) => {
    if (e.target.value !== props.topic.description) {
      e.preventDefault();

      const formData = new FormData();

      formData.append('description', description);

      dispatch(editTopic(formData, props.topic.uuid, match.params.uuid));
    }
  };

  const handleSuggestionChange = (e) => {
    e.preventDefault();

    setSuggestion(!suggestion);

    const formData = new FormData();

    formData.append('suggestion', e.target.checked);

    dispatch(editTopic(formData, props.topic.uuid, match.params.uuid));
  };

  const handleTagsChange = (modalTags) => {
    setTags(modalTags);

    const formData = new FormData();

    formData.append('tags', JSON.stringify(modalTags));

    dispatch(editTopic(formData, props.topic.uuid, match.params.uuid));
  };

  const removeTag = (tagId) => {
    const newTags = tags.filter((tag, index) => index !== tagId);

    setTags((prevState) => prevState.filter((tag, index) => index !== tagId));

    const formData = new FormData();

    formData.append('tags', JSON.stringify(newTags));

    dispatch(editTopic(formData, props.topic.uuid, match.params.uuid));
  };

  const handleExpandTopicCard = (e) => {
    e.stopPropagation();

    setExpandCard(!expandCard);
  };

  const handleSuggestTopicVideoModalShow = () => {
    setSuggestTopicVideoModalShow(!suggestTopicVideoModalShow);
    dispatch(setVideoLoading());
    dispatch(getMyVideos());
  };

  const topicCardCollapsedPlayOverlayClass = classNames(
    { 'topic-card-collapsed-play-overlay': !props.editMode },
    { 'topic-card-collapsed-play-overlay-none': props.editMode },
  );

  return !expandCard ? (
    <>
      <div id={props.topic.uuid} className="topic-card-collapsed-wrapper">
        <form
          className="topic-form"
          id={props.isAddTopic ? 'topicForm-' : `topicForm-${props.topic.uuid}`}
          method="POST"
          encType="multipart/form-data"
          onSubmit={props.isAddTopic ? handleAddTopic : handleEditTopic}
        >
          {props.editMode && (
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="delete-topic-icon"
              onClick={() => setCustomModalShow(!customModalShow)}
            />
          )}
          <div className="topic-card-collapsed-row-1">
            <div className="topic-card-collapsed-title-stats-wrapper">
              <textarea
                className="topic-card-collapsed-title-textarea"
                maxLength={`${MAXIMUM_TITLE_LENGTH}`}
                placeholder={props.editMode ? 'Topic Title' : ''}
                disabled={props.editMode ? false : true}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                onBlur={handleTitleChange}
              />
              <CardStats
                views={props.topic.videos.map((video) => video.views).reduce((a, b) => a + b, 0)}
                likes={props.topic.videos.map((video) => video.likes).reduce((a, b) => a + b, 0)}
                videos={props.topic.videos.length}
                quizzes={props.topic.quizzes.length}
                width="60%"
                margin="6px 6px 15px"
              />
            </div>
            <div className="topic-expand-button" onClick={() => setExpandCard(!expandCard)}>
              <FontAwesomeIcon icon={faExpandArrowsAlt} className="topic-expand-icon" />
              <h3 className="topic-expand-button-text">Expand</h3>
            </div>
          </div>
          <div className="topic-card-collapsed-row-2">
            <div
              className="topic-card-collapsed-thumbnail"
              style={{
                backgroundImage: `url(${
                  thumbnail ? thumbnail : props.editMode ? cardThumbnail : blankThumbnail
                })`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                cursor: props.editMode ? 'pointer' : 'unset',
              }}
              onClick={() => triggerTopicThumbnailInput(props.topic.uuid)}
            >
              <div className={topicCardCollapsedPlayOverlayClass} onClick={handleExpandTopicCard}>
                <FontAwesomeIcon icon={faPlay} className="play-icon" />
              </div>
              <input
                className={`topic-card-collapsed-thumbnail-input-${props.topic.uuid}`}
                name="topic-card-collapsed-thumbnail"
                type="file"
                accept="image/jpg, image/jpeg, image/png"
                style={{ display: 'none' }}
                onChange={handleTopicThumbnailUpload}
              />
              {thumbnail && props.editMode && (
                <div
                  className="topic-card-collapsed-thumbnail-overlay"
                  onClick={(e) => handleRemoveTopicThumbnail(e, props.topic.uuid)}
                >
                  <FontAwesomeIcon icon={faTimes} className="cross-icon" />
                </div>
              )}
            </div>
            <div className="topic-card-collapsed-details-wrapper">
              <div className="topic-card-collapsed-details-div-1">
                <textarea
                  className="topic-card-collapsed-description-textarea"
                  maxLength={`${MAXIMUM_DESCRIPTION_LENGTH}`}
                  placeholder={props.editMode ? 'Click here to start writing the description' : ''}
                  disabled={props.editMode ? false : true}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  onBlur={handleDescriptionChange}
                />
              </div>
              {props.editMode && (
                <div className="topic-card-collapsed-details-div-2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={suggestion}
                        onClick={handleSuggestionChange}
                        value="suggest-video"
                        color="primary"
                      />
                    }
                    label="Others can suggest a video for this topic"
                  />
                </div>
              )}
              <div className="topic-card-collapsed-details-div-3">
                <div className="topic-card-collapsed-owner-wrapper">
                  <FontAwesomeIcon icon={faUser} className="user-icon" />
                  <p className="topic-card-collapsed-owner-name">{flowState.creatorName}</p>
                </div>
                <div className="topic-card-collapsed-tags-and-buttons-wrapper">
                  <div className="topic-card-collapsed-tags-row">
                    {tags.length ? (
                      tags.map((tag, index) => (
                        <Tag
                          key={index}
                          tagId={index}
                          tagText={tag}
                          tagType="topic"
                          editMode={props.editMode}
                          removeTag={removeTag}
                        />
                      ))
                    ) : (
                      <p className="topic-card-collapsed-no-tags">No Tags</p>
                    )}
                    {props.editMode && (
                      <div
                        className="topic-card-collapsed-add-tag-button"
                        onClick={() => setModalShow(true)}
                      >
                        <FontAwesomeIcon icon={faPlus} className="add-icon" />
                        <p className="topic-card-collapsed-add-tag-button-text">Add Tag</p>
                      </div>
                    )}
                  </div>
                  <div className="topic-card-buttons-div">
                    <div
                      className="share-topic-button"
                      style={{
                        opacity: props.editMode ? '0.3' : '1',
                        pointerEvents: props.editMode ? 'none' : 'auto',
                      }}
                      onClick={() => setSocialShareModalShow(true)}
                    >
                      <img src={shareFlow} alt="share-topic" />
                      <h3 className="share-topic-button-text">Share Topic</h3>
                    </div>
                    {localStorage.getItem('accessToken') && suggestion && !props.isOwner && (
                      <div
                        className="suggest-video-button"
                        style={{
                          opacity: props.editMode ? '0.3' : '1',
                          pointerEvents: props.editMode ? 'none' : 'auto',
                        }}
                        onClick={handleSuggestTopicVideoModalShow}
                      >
                        <img src={videoIconWhite} alt="suggest-video" />
                        <h3 className="suggest-video-button-text">Suggest Video</h3>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <TagModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        tagType="topic"
        tags={tags}
        setTags={handleTagsChange}
      />
      <CustomModal
        show={customModalShow}
        onHide={() => setCustomModalShow(false)}
        modalTitle="Delete Topic"
        modalBody="Are you sure you want to delete this topic?"
        submitButtonText="Delete"
        handleSubmit={handleDeleteTopic}
      />
      <SocialShareModal
        show={socialShareModalShow}
        onHide={() => setSocialShareModalShow(false)}
        url={`${window.location.origin}/flow/${flowState.uuid}/${props.topic.uuid}`}
      />
      <AddTopicVideoModal
        show={suggestTopicVideoModalShow}
        onHide={() => setSuggestTopicVideoModalShow(false)}
        topicUuid={props.topic.uuid}
        suggest={true}
        topicVideos={props.topic.videos}
        setOpenSuggestVideoSnackbar={props.setOpenSuggestVideoSnackbar}
      />
    </>
  ) : (
    <TopicCardExpanded
      expandCard={expandCard}
      setExpandCard={setExpandCard}
      topic={props.topic}
      title={title}
      description={description}
      tags={tags}
      suggestion={suggestion}
      setTitle={setTitle}
      setDescription={setDescription}
      handleTitleChange={handleTitleChange}
      handleDescriptionChange={handleDescriptionChange}
      setTags={handleTagsChange}
      setSuggestion={handleSuggestionChange}
      removeTag={removeTag}
      handleDeleteTopic={handleDeleteTopic}
      editMode={props.editMode}
      isOwner={props.isOwner}
      setOpenSuggestVideoSnackbar={props.setOpenSuggestVideoSnackbar}
    />
  );
};

export default TopicCardCollapsed;
