import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { setLoadSearchResults } from '../../actions/flowAction';

import './SearchSuggestions.scss';

const SearchSuggestions = (props) => {
  const flowState = useSelector((state) => state.flow);

  const history = useHistory();

  const location = useLocation();

  const dispatch = useDispatch();

  const handleSuggestionClick = (title) => {
    props.setEnterPressed(true);
    props.setSearchText(title);
    props.setOverlay(false);
    props.setShowSuggestions(false);
    dispatch(setLoadSearchResults(true));

    if (location.pathname !== '/search-flows') {
      history.push('/search-flows');
    }
  };

  return (
    <>
      <div className="search-arrow-up"></div>
      <div className="search-suggestions-wrapper">
        {props.suggestions.map((suggestion, index) => (
          <div
            className="search-suggestion"
            key={index}
            onClick={() => handleSuggestionClick(suggestion.title)}
          >
            <FontAwesomeIcon icon={faSearch} className="search-icon" />
            <p className="search-suggestion-text">{suggestion.title}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default SearchSuggestions;
