import axiosApiInstance from './axios';

import { url } from './constants';

import {
  GET_PROFILE,
  GET_PROFILE_ERROR,
  GET_MY_DATA,
  GET_MY_DATA_ERROR,
  GET_MY_PROFILE_VIDEOS,
  GET_MY_PROFILE_VIDEOS_ERROR,
  GET_MY_PROFILE_QUIZZES,
  GET_MY_PROFILE_QUIZZES_ERROR,
  GET_MY_PROFILE_SUGGESTIONS,
  GET_MY_PROFILE_SUGGESTIONS_ERROR,
  UPDATE_USER_INFO,
  UPDATE_USER_INFO_ERROR,
  EDIT_VIDEO,
  EDIT_VIDEO_ERROR,
  DELETE_VIDEO,
  DELETE_VIDEO_ERROR,
  APPROVE_SUGGESTED_VIDEO,
  APPROVE_SUGGESTED_VIDEO_ERROR,
  EDIT_QUIZ,
  EDIT_QUIZ_ERROR,
  DELETE_QUIZ,
  DELETE_QUIZ_ERROR,
  CLEAR_PROFILE,
  PROFILE_LOADING,
  PROFILE_DASHBOARD_LOADING,
} from './actionTypes';

export const getProfile = () => async (dispatch) => {
  try {
    const uuid = localStorage.getItem('uuid');

    const res = await axiosApiInstance.get(`${url}/user/user/${uuid}/`);

    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PROFILE_ERROR,
      payload: err.response,
    });
  }
};

export const getMyData = () => async (dispatch) => {
  try {
    const uuid = localStorage.getItem('uuid');

    const res = await axiosApiInstance.get(`${url}/user/personal-area/${uuid}/`);

    dispatch({
      type: GET_MY_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_MY_DATA_ERROR,
      payload: err.response,
    });
  }
};

export const getMyProfileVideos = () => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(`${url}/video/my_videos`);

    dispatch({
      type: GET_MY_PROFILE_VIDEOS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_MY_PROFILE_VIDEOS_ERROR,
      payload: err.response,
    });
  }
};

export const getMyProfileQuizzes = () => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(`${url}/quiz/my_quizzes`);

    dispatch({
      type: GET_MY_PROFILE_QUIZZES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_MY_PROFILE_QUIZZES_ERROR,
      payload: err.response,
    });
  }
};

export const getMyProfileSuggestions = () => async (dispatch) => {
  try {
    const uuid = localStorage.getItem('uuid');

    const res = await axiosApiInstance.get(`${url}/user/personal-area-suggestions/${uuid}/`);

    dispatch({
      type: GET_MY_PROFILE_SUGGESTIONS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_MY_PROFILE_SUGGESTIONS_ERROR,
      payload: err.response,
    });
  }
};

export const updateUserInfo = (uuid, formData) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.patch(`${url}/user/user/${uuid}/`, formData, {
      headers: {
        'Content-type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
    });

    dispatch({
      type: UPDATE_USER_INFO,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_USER_INFO_ERROR,
      payload: err.response,
    });
  }
};

export const editVideo = (videoUuid, formData) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.patch(`${url}/video/video/${videoUuid}/`, formData, {
      headers: {
        'Content-type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
    });

    dispatch({
      type: EDIT_VIDEO,
      payload: res.data,
    });

    const res2 = await axiosApiInstance.get(`${url}/video/my_videos`);

    dispatch({
      type: GET_MY_PROFILE_VIDEOS,
      payload: res2.data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_VIDEO_ERROR,
      payload: err.response,
    });
  }
};

export const deleteVideo = (videoUuid) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.delete(`${url}/video/video/${videoUuid}/`);

    dispatch({
      type: DELETE_VIDEO,
      payload: res.data,
    });

    const res2 = await axiosApiInstance.get(`${url}/video/my_videos`);

    dispatch({
      type: GET_MY_PROFILE_VIDEOS,
      payload: res2.data,
    });
  } catch (err) {
    dispatch({
      type: DELETE_VIDEO_ERROR,
      payload: err.response,
    });
  }
};

export const approveSuggestedVideo = (formData, suggestionUuid) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.patch(
      `${url}/video/suggestions/${suggestionUuid}/`,
      formData,
    );

    dispatch({
      type: APPROVE_SUGGESTED_VIDEO,
      payload: res.data,
    });

    const uuid = localStorage.getItem('uuid');

    const res2 = await axiosApiInstance.get(`${url}/user/personal-area-suggestions/${uuid}/`);

    dispatch({
      type: GET_MY_PROFILE_SUGGESTIONS,
      payload: res2.data,
    });
  } catch (err) {
    dispatch({
      type: APPROVE_SUGGESTED_VIDEO_ERROR,
      payload: err.response,
    });
  }
};

export const editQuiz = (uuid, formData) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.patch(`${url}/quiz/quiz/${uuid}/`, formData, {
      headers: {
        'Content-type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
    });

    dispatch({
      type: EDIT_QUIZ,
      payload: res.data,
    });

    const res2 = await axiosApiInstance.get(`${url}/quiz/my_quizzes`);

    dispatch({
      type: GET_MY_PROFILE_QUIZZES,
      payload: res2.data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_QUIZ_ERROR,
      payload: err.response,
    });
  }
};

export const deleteQuiz = (uuid) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.delete(`${url}/quiz/quiz/${uuid}/`);

    dispatch({
      type: DELETE_QUIZ,
      payload: res.data,
    });

    const res2 = await axiosApiInstance.get(`${url}/quiz/my_quizzes`);

    dispatch({
      type: GET_MY_PROFILE_QUIZZES,
      payload: res2.data,
    });
  } catch (err) {
    dispatch({
      type: DELETE_QUIZ_ERROR,
      payload: err.response,
    });
  }
};

export const clearProfile = () => (dispatch) => {
  dispatch({
    type: CLEAR_PROFILE,
  });
};

export const setProfileLoading = () => (dispatch) => {
  dispatch({
    type: PROFILE_LOADING,
  });
};

export const setProfileDashboardLoading = () => (dispatch) => {
  dispatch({
    type: PROFILE_DASHBOARD_LOADING,
  });
};
