import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPlay } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faUser, faEdit, faTrashAlt, faEye } from '@fortawesome/free-regular-svg-icons';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import classNames from 'classnames';
import blankThumbnail from '../../images/blank-thumbnail.png';

import {
  setProfileDashboardLoading,
  deleteVideo,
  approveSuggestedVideo,
} from '../../actions/profileAction';
import { MAXIMUM_SELECTED_CONTENT } from '../../actions/constants';

import CardStats from '../CardStats/CardStats';
import CustomModal from '../CustomModal/CustomModal';
import AddTopicVideoModal from '../AddTopicVideoModal/AddTopicVideoModal';

import './MyVideoCard.scss';

const MyVideoCard = (props) => {
  const [selected, setSelected] = useState(false);
  const [customModalShow, setCustomModalShow] = useState(false);
  const [playerModalShow, setPlayerModalShow] = useState(false);
  const [addTopicVideoModalShow, setAddTopicVideoModalShow] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [isExists, setIsExists] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.showSelect) {
      setIsExists(props.topicVideos.find((topicVideo) => topicVideo.uuid === props.video.uuid));
    }
  }, []);

  const selectVideo = () => {
    if (props.selectedVideos.length < MAXIMUM_SELECTED_CONTENT) {
      setSelected(!selected);
      !selected
        ? props.addVideoToSelectedVideos(props.video.uuid)
        : props.removeVideoFromSelectedVideos(props.video.uuid);
    } else {
      if (selected) {
        setSelected(!selected);
        !selected
          ? props.addVideoToSelectedVideos(props.video.uuid)
          : props.removeVideoFromSelectedVideos(props.video.uuid);
      }
    }
  };

  const handleDeleteVideo = () => {
    dispatch(setProfileDashboardLoading());
    dispatch(deleteVideo(props.video.uuid));
  };

  const handleApproveVideo = async () => {
    dispatch(setProfileDashboardLoading());
    await dispatch(approveSuggestedVideo({ status: 'approved' }, props.video.uuid));
    props.setSuggestionApproved(true);
    props.setOpenSnackbar(true);
  };

  const handleRejectVideo = async () => {
    dispatch(setProfileDashboardLoading());
    await dispatch(approveSuggestedVideo({ status: 'declined' }, props.video.uuid));
    props.setSuggestionApproved(false);
    props.setOpenSnackbar(true);
  };

  const onHide = () => {
    if (playVideo) {
      setPlayVideo(false);
    } else {
      setAddTopicVideoModalShow(false);
    }
  };

  const handleMyVideoCardClick = () => {
    if (isExists) {
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const checkboxDivClass = classNames(
    { 'my-video-checkbox': !props.searchCard },
    { 'search-checkbox': props.searchCard },
  );

  const checkboxClass = classNames(
    { 'my-video-check-icon': !props.searchCard },
    { 'search-check-icon': props.searchCard },
  );

  const videoCardThumbnailClass = classNames(
    { 'my-video-card-thumbnail': !props.searchCard },
    { 'search-card-thumbnail': props.searchCard },
  );

  return (
    <>
      <div
        className="my-video-card"
        style={{ opacity: isExists ? '0.3' : '1' }}
        onClick={handleMyVideoCardClick}
      >
        {props.showSelect && (
          <div className={checkboxDivClass}>
            <Checkbox
              checked={selected}
              onChange={selectVideo}
              value="suggest-video"
              color="primary"
              icon={<FontAwesomeIcon icon={faCircle} className={checkboxClass} />}
              checkedIcon={<FontAwesomeIcon icon={faCheckCircle} className={checkboxClass} />}
              disabled={isExists}
            />
          </div>
        )}
        <div
          className={videoCardThumbnailClass}
          style={{
            backgroundImage: `url(${
              props.video.thumbnail ? props.video.thumbnail : blankThumbnail
            })`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            pointerEvents: isExists ? 'none' : 'auto',
          }}
          onClick={props.showSelect ? selectVideo : (e) => e.preventDefault()}
        >
          {(props.searchCard || props.suggestion) && (
            <div className="content-visibility">
              <FontAwesomeIcon icon={faEye} className="content-visibility-icon" />
              <p className="content-visibility-text">
                {props.video.visibility === 'everyone' ? 'Everyone' : 'Registered Users'}
              </p>
            </div>
          )}
          {props.edit && (
            <div className="edit-content-overlay">
              <FontAwesomeIcon
                icon={faTrashAlt}
                className="trash-icon"
                onClick={() => setCustomModalShow(true)}
              />
              <div className="edit-button-wrapper" onClick={() => setAddTopicVideoModalShow(true)}>
                <FontAwesomeIcon icon={faEdit} className="edit-icon" />
                <p className="edit-content-text">Edit</p>
              </div>
            </div>
          )}
          {props.suggestion && (
            <div className="edit-content-overlay">
              <div className="edit-button-wrapper" onClick={() => setPlayerModalShow(true)}>
                <FontAwesomeIcon icon={faPlay} className="edit-icon" />
                <p className="edit-content-text">Play Video</p>
              </div>
            </div>
          )}
        </div>
        <div className="my-video-card-details">
          <h3 className="my-video-card-title">{props.video.title}</h3>
          <div className="my-video-card-video-stats">
            {!props.owner && (
              <div className="video-owner-wrapper">
                <FontAwesomeIcon icon={faUser} className="card-stats-icon" />
                <p className="owner-name">{props.video.creator_name}</p>
              </div>
            )}
            {!props.suggestion && (
              <CardStats
                views={props.video.views}
                likes={props.video.likes}
                width="100%"
                margin={props.owner ? '0' : '8px 0 0'}
              />
            )}
            {props.suggestion && (
              <div className="suggestion-buttons-wrapper">
                <button className="reject-button" type="button" onClick={handleRejectVideo}>
                  Reject
                </button>
                <button className="approve-button" type="button" onClick={handleApproveVideo}>
                  Approve
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <CustomModal
        show={customModalShow}
        onHide={() => setCustomModalShow(false)}
        modalTitle="Delete Video"
        modalBody="Are you sure you want to delete this video permanently?"
        submitButtonText="Delete"
        handleSubmit={handleDeleteVideo}
      />
      <CustomModal
        show={playerModalShow}
        onHide={() => setPlayerModalShow(false)}
        player={true}
        videoUrl={props.video.url}
      />
      <AddTopicVideoModal
        show={addTopicVideoModalShow}
        onHide={onHide}
        topicUuid={props.topicUuid}
        edit={props.edit}
        myVideo={props.video}
        playVideo={playVideo}
        setPlayVideo={setPlayVideo}
      />
      <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
        <MuiAlert elevation={6} variant="filled" severity="error" {...props}>
          Video already exists in topic.
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default MyVideoCard;
