import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { TextField, RadioGroup, FormControlLabel } from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';
import { useDebounce } from 'use-debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimesCircle,
  faCheck,
  faExclamationCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import blankThumbnail from '../../images/blank-thumbnail.png';

import {
  uploadVideo,
  addVideoToTopic,
  suggestVideo,
  getMyVideos,
  searchVideos,
  incrementSearchVideosAPICount,
  clearVideoState,
  setVideoLoading,
  setSearchVideoLoading,
  clearSearchVideosState,
} from '../../actions/videoAction';

import {
  setProfileDashboardLoading,
  getMyProfileVideos,
  editVideo,
} from '../../actions/profileAction';

import {
  MAXIMUM_TAGS,
  MAXIMUM_TITLE_LENGTH,
  MAXIMUM_DESCRIPTION_LENGTH,
  MAXIMUM_TAG_LENGTH,
} from '../../actions/constants';

import Tag from '../Tag/Tag';
import StyledRadio from '../StyledRadio/StyledRadio';
import MyVideoCard from '../MyVideoCard/MyVideoCard';
import VideoNote from '../VideoNote/VideoNote';
import ResponsivePlayer from '../ResponsivePlayer/ResponsivePlayer';
import Loader from '../Loader/Loader';

import './AddTopicVideoModal.scss';

const AddTopicVideoModal = (props) => {
  const [video, setVideo] = useState(props.edit ? props.myVideo.url : '');
  const [thumbnail, setThumbnail] = useState(props.edit ? props.myVideo.thumbnail : '');
  const [thumbnailImage, setThumbnailImage] = useState(
    props.edit
      ? props.myVideo.thumbnail
        ? props.myVideo.thumbnail
        : blankThumbnail
      : blankThumbnail,
  );
  const [title, setTitle] = useState(props.edit ? props.myVideo.title : '');
  const [description, setDescription] = useState(props.edit ? props.myVideo.description : '');
  const [tags, setTags] = useState(props.edit ? props.myVideo.tags : []);
  const [notesList, setNotesList] = useState(props.edit ? props.myVideo.notes : []);
  const [newNotesList, setNewNotesList] = useState([]);
  const [deletedNotesList, setDeletedNotesList] = useState([]);
  const [visibility, setVisibility] = useState(
    props.edit ? props.myVideo.visibility : 'registered_users',
  );
  const [inputTag, setInputTag] = useState('');
  const [tagLimit, setTagLimit] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebounce(searchText, 1000);

  const [selectedVideos, setSelectedVideos] = useState([]);

  const [selectedTab, setSelectedTab] = useState(
    props.edit || props.newVideo ? 'upload' : 'my-videos',
  );
  const [previousSelectedTab, setPreviousSelectedTab] = useState(
    props.edit || props.newVideo ? 'upload' : 'my-videos',
  );

  const THUMBNAIL_INPUT = document.querySelector('.upload-panel-thumbnail-input');

  const videoState = useSelector((state) => state.video);

  const dispatch = useDispatch();

  const match = useRouteMatch();

  useEffect(() => {
    return () => {
      setVideo('');
      setThumbnailImage(blankThumbnail);
      setTitle('');
      setDescription('');
      setTags([]);
      setNotesList([]);
      setNewNotesList([]);
      setDeletedNotesList([]);
      setVisibility('registered_users');
      setInputTag('');
      setTagLimit(false);
      setSearchText('');

      if (THUMBNAIL_INPUT) {
        THUMBNAIL_INPUT.value = '';
      }
    };
  }, []);

  useEffect(() => {
    setVideo(props.edit ? props.myVideo.url : '');
    setThumbnail(props.edit ? props.myVideo.thumbnail : '');
    setThumbnailImage(
      props.edit
        ? props.myVideo.thumbnail
          ? props.myVideo.thumbnail
          : blankThumbnail
        : blankThumbnail,
    );
    setTitle(props.edit ? props.myVideo.title : '');
    setDescription(props.edit ? props.myVideo.description : '');
    setTags(props.edit ? props.myVideo.tags : []);
    setNotesList(props.edit ? props.myVideo.notes : []);
    setNewNotesList([]);
    setDeletedNotesList([]);
    setVisibility(props.edit ? props.myVideo.visibility : 'registered_users');
    setInputTag('');
    setTagLimit(false);

    if (videoState.errorMessage === '') {
      if (props.edit || props.newVideo) {
        setSelectedTab('upload');
        setPreviousSelectedTab('upload');
      } else if (selectedTab === 'upload') {
        document.getElementById('my-videos')?.classList.add('add-topic-video-modal-tab-selected');
        document.getElementById('upload')?.classList.remove('add-topic-video-modal-tab-selected');
        setSelectedTab('my-videos');
        setPreviousSelectedTab('my-videos');
      }
    }

    if (THUMBNAIL_INPUT) {
      THUMBNAIL_INPUT.value = '';
    }
  }, [videoState.video]);

  useEffect(() => {
    if (tags.length < MAXIMUM_TAGS) {
      setTagLimit(false);
    }

    if (tags.length === 0) {
      setShowTags(false);
    } else {
      setShowTags(true);
    }
  }, [tags]);

  useEffect(() => {
    if (debouncedSearchText) {
      dispatch(setSearchVideoLoading());
      dispatch(incrementSearchVideosAPICount());
      dispatch(searchVideos(debouncedSearchText));
    }
  }, [debouncedSearchText]);

  const handleTabSelect = (e) => {
    if (!videoState.videoLoading) {
      document
        .getElementById(previousSelectedTab)
        .classList.remove('add-topic-video-modal-tab-selected');
      document.getElementById(e.target.id).classList.add('add-topic-video-modal-tab-selected');
      setPreviousSelectedTab(e.target.id);
      setSelectedTab(e.target.id);
    }
  };

  const handleModalClose = () => {
    setVideo(props.edit ? props.myVideo.url : '');
    setThumbnail(props.edit ? props.myVideo.thumbnail : '');
    setThumbnailImage(
      props.edit
        ? props.myVideo.thumbnail
          ? props.myVideo.thumbnail
          : blankThumbnail
        : blankThumbnail,
    );
    setTitle(props.edit ? props.myVideo.title : '');
    setDescription(props.edit ? props.myVideo.description : '');
    setTags(props.edit ? props.myVideo.tags : []);
    setNotesList(props.edit ? props.myVideo.notes : []);
    setNewNotesList([]);
    setDeletedNotesList([]);
    setVisibility(props.edit ? props.myVideo.visibility : 'registered_users');
    setInputTag('');
    setTagLimit(false);
    setSearchText('');
    setSelectedTab(props.edit || props.newVideo ? 'upload' : 'my-videos');
    setPreviousSelectedTab(props.edit || props.newVideo ? 'upload' : 'my-videos');

    if (THUMBNAIL_INPUT) {
      THUMBNAIL_INPUT.value = '';
    }

    dispatch(clearVideoState());
    dispatch(clearSearchVideosState());

    if (props.edit) {
      props.setPlayVideo(false);
    }

    props.onHide();
  };

  const triggerVideoInput = () => {
    document.querySelector('.upload-panel-video-input').click();
  };

  const handleVideoUpload = (e) => {
    if (e.target.files[0]) {
      if (e.target.files[0].size > 104857600) {
        alert('File too big! Maximum video size could be 100MB.');
        e.target.value = '';
      } else {
        setVideo(e.target.files[0]);
      }
    }
  };

  const triggerVideoThumbnailInput = () => {
    document.querySelector('.upload-panel-thumbnail-input').click();
  };

  const triggerVideoPlay = () => {
    props.setPlayVideo(true);
  };

  const handleVideoThumbnailUpload = (e) => {
    if (e.target.files[0]) {
      setThumbnail(e.target.files[0]);
      setThumbnailImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleRemoveVideoThumbnail = () => {
    setThumbnail('');
    setThumbnailImage(blankThumbnail);
    document.querySelector('.upload-panel-thumbnail-input').value = '';
  };

  const triggerNotesInput = () => {
    document.querySelector('.video-notes-input').click();
  };

  const handleNotesUpload = (e) => {
    if (e.target.files[0]) {
      for (var i = 0; i < document.getElementById('videoNotesFiles')?.files.length; i++) {
        const noteFile = document.getElementById('videoNotesFiles')?.files[i];
        setNotesList((prevState) => [...prevState, noteFile]);
        setNewNotesList((prevState) => [...prevState, noteFile]);
      }
    }
  };

  const removeNote = (noteId) => {
    setNotesList((prevState) => prevState.filter((note, index) => index !== noteId));
    setNewNotesList((prevState) => prevState.filter((note, index) => index !== noteId));

    if (props.edit) {
      const deletedNoteUuid = notesList.find(
        (note, index) => index === noteId && note.hasOwnProperty('uuid'),
      )?.uuid;
      if (deletedNoteUuid) {
        setDeletedNotesList((prevState) => [...prevState, deletedNoteUuid]);
      }
    }
  };

  const addTag = (e) => {
    if (e.key === 'Enter') {
      if (tags.length < MAXIMUM_TAGS) {
        if (inputTag !== '' && !tags.includes(inputTag)) {
          setTags((prevState) => [...prevState, inputTag]);
        }
      } else {
        setTagLimit(true);
      }

      setInputTag('');
    }
  };

  const addTagButtonClick = () => {
    if (tags.length < MAXIMUM_TAGS) {
      if (inputTag !== '' && !tags.includes(inputTag)) {
        setTags((prevState) => [...prevState, inputTag]);
      }
    } else {
      setTagLimit(true);
    }

    setInputTag('');
  };

  const removeTag = (tagId) => {
    setTags((prevState) => prevState.filter((tag, index) => index !== tagId));
  };

  const addVideoToSelectedVideos = (videoUuid) => {
    setSelectedVideos((prevState) => [...prevState, videoUuid]);
  };

  const removeVideoFromSelectedVideos = (videoUuid) => {
    setSelectedVideos((prevState) => prevState.filter((video) => video !== videoUuid));
  };

  const handleUploadSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (video && typeof video == 'object') {
      formData.append('file', video);
    }
    if (thumbnail !== null && (typeof thumbnail == 'object' || thumbnail === '')) {
      formData.append('thumbnail', thumbnail);
    }
    formData.append('title', title);
    formData.append('description', description);
    formData.append('tags', JSON.stringify(tags));
    formData.append('visibility', visibility);

    if (props.edit) {
      newNotesList.forEach((note) => formData.append('notes', note));
      formData.append('deleted_notes', JSON.stringify(deletedNotesList));

      dispatch(setProfileDashboardLoading());
      dispatch(editVideo(props.myVideo.uuid, formData));
    } else if (props.newVideo) {
      dispatch(setVideoLoading());
      await dispatch(uploadVideo(formData));
      dispatch(setProfileDashboardLoading());
      dispatch(getMyProfileVideos());
      handleModalClose();
    } else {
      notesList.forEach((note) => formData.append('notes', note));

      dispatch(setVideoLoading());
      await dispatch(uploadVideo(formData));
      dispatch(getMyVideos());
    }
  };

  const handleAddSelectedVideo = async (e) => {
    e.preventDefault();

    if (props.suggest) {
      await dispatch(suggestVideo({ videos_list: selectedVideos, topic_uuid: props.topicUuid }));
      props.setOpenSuggestVideoSnackbar(true);
    } else {
      dispatch(
        addVideoToTopic({ videos_list: selectedVideos }, props.topicUuid, match.params.uuid),
      );
    }

    handleModalClose();
  };

  const handleCancelSearch = () => {
    setSearchText('');
    dispatch(clearSearchVideosState());
  };

  return (
    <Modal
      {...props}
      centered
      scrollable={props.playVideo ? false : true}
      backdrop={props.playVideo ? true : 'static'}
      className="add-video-modal"
    >
      {typeof video === 'string' && props.playVideo ? (
        <div className="edit-video-player">
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="close-modal-icon"
            onClick={handleModalClose}
          />
          <ResponsivePlayer url={video} playerPlaying={true} edit={props.edit} />
        </div>
      ) : (
        <div className="add-topic-video-modal">
          <div
            className="add-topic-video-modal-header"
            style={{ marginBottom: props.edit ? '0' : '22px' }}
          >
            <h3
              className="add-topic-video-modal-heading"
              style={{ marginBottom: props.edit ? '50px' : '32px' }}
            >
              {props.edit ? 'Edit' : props.suggest ? 'Suggest' : 'Add'} Video
            </h3>
            {!props.edit && !props.newVideo && (
              <div className="add-topic-video-modal-tab-labels-wrapper">
                <div
                  id="my-videos"
                  className="add-topic-video-modal-tab-label add-topic-video-modal-tab-selected"
                  onClick={handleTabSelect}
                >
                  My Videos
                </div>
                <div
                  id="upload"
                  className="add-topic-video-modal-tab-label add-topic-video-modal-tab-label-center"
                  onClick={handleTabSelect}
                >
                  Upload
                </div>
                <div
                  id="search"
                  className="add-topic-video-modal-tab-label"
                  onClick={handleTabSelect}
                >
                  Search
                </div>
              </div>
            )}
            {selectedTab === 'search' && !props.edit && (
              <SearchBar
                placeholder="Search"
                value={searchText}
                onChange={(newValue) => setSearchText(newValue)}
                // onRequestSearch={() => doSomethingWith(this.state.value)}
                onCancelSearch={handleCancelSearch}
              />
            )}
          </div>
          <Modal.Body
            style={{
              maxHeight:
                props.edit || props.newVideo
                  ? 'calc(100vh - 289px)'
                  : selectedTab === 'search'
                  ? 'calc(100vh - 408px)'
                  : 'calc(100vh - 321px)',
              overflowY: 'auto',
            }}
          >
            {selectedTab === 'my-videos' && (
              <div className="add-topic-video-modal-my-videos-panel">
                {videoState.videoLoading ? (
                  <Loader height="65vh" margin="0" />
                ) : videoState.myVideos.length ? (
                  <div className="my-videos-panel-grid">
                    {videoState.myVideos.map((video) => (
                      <MyVideoCard
                        key={video.uuid}
                        owner={true}
                        video={video}
                        showSelect={true}
                        searchCard={false}
                        topicVideos={props.topicVideos}
                        selectedVideos={selectedVideos}
                        addVideoToSelectedVideos={addVideoToSelectedVideos}
                        removeVideoFromSelectedVideos={removeVideoFromSelectedVideos}
                      />
                    ))}
                  </div>
                ) : (
                  <p className="no-videos">No Videos</p>
                )}
              </div>
            )}
            {selectedTab === 'upload' && (
              <div className="add-topic-video-modal-upload-panel">
                {videoState.videoLoading ? (
                  <Loader
                    height="65vh"
                    margin="0"
                    customMessage="Please wait… we are uploading your video. This can take up to a few minutes. Do not close this window."
                  />
                ) : (
                  <form
                    className="upload-panel-form"
                    id="uploadTopicVideoForm"
                    encType="multipart/form-data"
                    onSubmit={handleUploadSubmit}
                    onKeyPress={(event) => {
                      if (event.which === 13 /* Enter */) {
                        event.preventDefault();
                      }
                    }}
                  >
                    <div className="upload-panel-video-input-wrapper">
                      <div className="upload-panel-video-input-button" onClick={triggerVideoInput}>
                        Choose File
                        <input
                          className="upload-panel-video-input"
                          placeholder="Choose File"
                          name="upload-panel-file-input"
                          type="file"
                          accept="video/*"
                          style={{ display: 'none' }}
                          onChange={handleVideoUpload}
                          required={props.edit ? false : true}
                        />
                      </div>
                      <p className="upload-panel-video-input-text">
                        {video.name}
                        {video !== '' && typeof video !== 'string' && (
                          <span>
                            <FontAwesomeIcon icon={faCheck} className="upload-check-icon" />
                          </span>
                        )}
                      </p>
                    </div>
                    <div className="upload-panel-thumbnail-input-wrapper">
                      <div
                        className="upload-panel-thumbnail"
                        style={{
                          backgroundImage: `url(${thumbnailImage})`,
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                        }}
                      >
                        <input
                          className="upload-panel-thumbnail-input"
                          name="upload-panel-thumbnail"
                          type="file"
                          accept="image/jpg, image/jpeg, image/png"
                          style={{ display: 'none' }}
                          onChange={handleVideoThumbnailUpload}
                        />
                        {thumbnail && (
                          <div
                            className="upload-panel-thumbnail-overlay"
                            onClick={handleRemoveVideoThumbnail}
                          >
                            <FontAwesomeIcon icon={faTimes} className="cross-icon" />
                          </div>
                        )}
                      </div>
                      <div className="upload-panel-thumbnail-buttons">
                        <div
                          className="upload-panel-thumbnail-edit"
                          onClick={triggerVideoThumbnailInput}
                        >
                          Edit Thumbnail
                        </div>
                        {props.edit && (
                          <div className="upload-panel-thumbnail-play" onClick={triggerVideoPlay}>
                            Play Video
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="upload-panel-video-details-wrapper">
                      <TextField
                        className="upload-panel-video-input"
                        label="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        inputProps={{
                          maxLength: MAXIMUM_TITLE_LENGTH,
                        }}
                        required
                      />
                      <div className="upload-panel-tags-input-wrapper">
                        <TextField
                          className="upload-panel-video-input"
                          label="Tags"
                          value={inputTag}
                          onChange={(e) => setInputTag(e.target.value)}
                          onKeyDown={addTag}
                          inputProps={{
                            maxLength: MAXIMUM_TAG_LENGTH,
                          }}
                        />
                        <div className="upload-panel-add-tag-wrapper">
                          <button
                            className="upload-panel-add-tag-button"
                            type="button"
                            onClick={addTagButtonClick}
                          >
                            Add Tag
                          </button>
                        </div>
                      </div>
                      {showTags && (
                        <div className="upload-panel-tags-row">
                          {tags.length
                            ? tags.map((tag, index) => (
                                <Tag
                                  key={index}
                                  tagId={index}
                                  tagText={tag}
                                  tagType="topic"
                                  editMode={true}
                                  removeTag={removeTag}
                                />
                              ))
                            : null}
                        </div>
                      )}
                      {tagLimit ? (
                        <span className="tag-limit-warning">
                          {`Maximum ${MAXIMUM_TAGS} tags can be added`}{' '}
                          <FontAwesomeIcon icon={faExclamationCircle} />
                        </span>
                      ) : null}
                      <TextField
                        className="upload-panel-video-input"
                        label="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        multiline={true}
                        inputProps={{
                          maxLength: MAXIMUM_DESCRIPTION_LENGTH,
                        }}
                      />
                    </div>
                    <div className="upload-panel-video-notes-grid">
                      <h3 className="video-notes-heading">Notes</h3>
                      <input
                        id="videoNotesFiles"
                        className="video-notes-input"
                        name="video-notes-input"
                        type="file"
                        accept="application/pdf"
                        multiple
                        style={{ display: 'none' }}
                        onChange={handleNotesUpload}
                      />
                      {notesList.length > 0 &&
                        notesList.map((note, index) => (
                          <VideoNote
                            key={index}
                            noteId={index}
                            note={note}
                            removeNote={removeNote}
                          />
                        ))}
                      <div className="upload-notes-input" onClick={triggerNotesInput}>
                        Upload Notes
                      </div>
                    </div>
                    <div className="upload-panel-video-visibility-wrapper">
                      <h3 className="video-visibility-heading">Visibility</h3>
                      <RadioGroup
                        defaultValue={visibility}
                        aria-label="visibility"
                        name="styled-visibility-radios"
                        onChange={(e) => setVisibility(e.target.value)}
                      >
                        <FormControlLabel
                          value="just_me"
                          control={<StyledRadio />}
                          label="Just Me"
                        />
                        <FormControlLabel
                          value="registered_users"
                          control={<StyledRadio />}
                          label="Registered Users"
                        />
                        <FormControlLabel
                          value="everyone"
                          control={<StyledRadio />}
                          label="Everyone"
                        />
                      </RadioGroup>
                    </div>
                  </form>
                )}
              </div>
            )}
            {selectedTab === 'search' && (
              <div className="add-topic-video-modal-search-panel">
                <h3 className="search-panel-grid-heading">Search Results</h3>
                {videoState.searchVidoesAPICount > 0 ? (
                  <Loader margin="0" height="50vh" />
                ) : (
                  <div className="search-panel-grid">
                    {videoState.searchedVideos.map((searchedVideo) => (
                      <MyVideoCard
                        key={searchedVideo.uuid}
                        video={searchedVideo}
                        showSelect={true}
                        searchCard={true}
                        topicVideos={props.topicVideos}
                        selectedVideos={selectedVideos}
                        addVideoToSelectedVideos={addVideoToSelectedVideos}
                        removeVideoFromSelectedVideos={removeVideoFromSelectedVideos}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {selectedTab === 'my-videos' && !props.edit && (
              <div className="video-modal-footer-buttons-wrapper">
                <button
                  className="my-videos-add-button"
                  type="button"
                  onClick={
                    selectedVideos.length ? handleAddSelectedVideo : (e) => e.preventDefault()
                  }
                >
                  {props.suggest ? 'Suggest Selected Videos' : 'Add Selected Videos'}
                </button>
                <button className="cancel-button" type="button" onClick={handleModalClose}>
                  Cancel
                </button>
              </div>
            )}
            {selectedTab === 'upload' && !props.edit && (
              <div className="video-modal-footer-buttons-wrapper">
                <button className="upload-video-button" type="submit" form="uploadTopicVideoForm">
                  Upload
                </button>
                <button className="cancel-button" type="button" onClick={handleModalClose}>
                  Cancel
                </button>
              </div>
            )}
            {props.edit && (
              <div className="video-modal-footer-buttons-wrapper">
                <button className="upload-video-button" type="submit" form="uploadTopicVideoForm">
                  Save
                </button>
                <button className="cancel-button" type="button" onClick={handleModalClose}>
                  Cancel
                </button>
              </div>
            )}
            {selectedTab === 'search' && !props.edit && (
              <div className="video-modal-footer-buttons-wrapper">
                <button
                  className="search-add-button"
                  type="button"
                  onClick={
                    selectedVideos.length ? handleAddSelectedVideo : (e) => e.preventDefault()
                  }
                >
                  {props.suggest ? 'Suggest Videos' : 'Add Videos'}
                </button>
                <button className="cancel-button" type="button" onClick={handleModalClose}>
                  Cancel
                </button>
              </div>
            )}
          </Modal.Footer>
        </div>
      )}
    </Modal>
  );
};

export default AddTopicVideoModal;
