import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { MAXIMUM_TAGS, MAXIMUM_TAG_LENGTH } from '../../actions/constants';

import Tag from '../Tag/Tag';

import './TagModal.scss';

const TagModal = (props) => {
  const [modalTags, setModalTags] = useState(props.tags);
  const [inputTag, setInputTag] = useState('');
  const [tagLimit, setTagLimit] = useState(false);

  useEffect(() => {
    setModalTags(props.tags);
  }, [props.tags]);

  useEffect(() => {
    if (modalTags.length < MAXIMUM_TAGS) {
      setTagLimit(false);
    }
  }, [modalTags]);

  const addTag = (e) => {
    e.preventDefault();

    if (modalTags.length < MAXIMUM_TAGS) {
      if (inputTag !== '' && !modalTags.includes(inputTag)) {
        setModalTags((prevState) => [...prevState, inputTag]);
      }
    } else {
      setTagLimit(true);
    }

    setInputTag('');
  };

  const removeTag = (tagId) => {
    setModalTags((prevState) => prevState.filter((tag, index) => index !== tagId));
  };

  const handleModalFinish = () => {
    props.setTags(modalTags);
    setTagLimit(false);
    props.onHide();
  };

  const handleModalCancel = () => {
    setModalTags(props.tags);
    setTagLimit(false);
    props.onHide();
  };

  const tagModalClass = classNames(
    'tag-modal',
    { 'flow-modal': props.tagType === 'flow' },
    { 'topic-modal': props.tagType === 'topic' || props.tagType === 'video' },
  );

  const tagModalFinishButtonClass = classNames(
    'tag-modal-finish-button',
    { 'flow-button': props.tagType === 'flow' },
    { 'topic-button': props.tagType === 'topic' || props.tagType === 'video' },
  );

  const tagModalAddButtonClass = classNames(
    'tag-modal-add-button',
    { 'flow-button': props.tagType === 'flow' },
    { 'topic-button': props.tagType === 'topic' || props.tagType === 'video' },
  );

  return (
    <Modal {...props} centered>
      <div className={tagModalClass}>
        <h3 className="tag-modal-title">
          {props.tagType === 'flow'
            ? 'Flow Tags'
            : props.tagType === 'video'
            ? 'Video Tags'
            : 'Topic Tags'}
        </h3>
        <div className="tag-modal-body">
          <div className="tag-modal-tags-row">
            {modalTags.length ? (
              modalTags.map((modalTag, index) => (
                <Tag
                  key={index}
                  tagId={index}
                  tagText={modalTag}
                  tagType={props.tagType}
                  editMode={true}
                  removeTag={removeTag}
                />
              ))
            ) : (
              <p className="tag-modal-no-tags">No Tags</p>
            )}
          </div>
          <form className="tag-modal-tags-form" onSubmit={addTag}>
            <h3 className="tag-modal-tags-form-heading">Add Tags</h3>
            <div className="tag-modal-tags-form-input-wrapper">
              <input
                className="tag-modal-tags-form-input"
                placeholder="Tag"
                name="tag"
                type="text"
                value={inputTag}
                maxLength={`${MAXIMUM_TAG_LENGTH}`}
                onChange={(e) => setInputTag(e.target.value)}
              />
              <button className={tagModalAddButtonClass} type="submit">
                Add Tag
              </button>
            </div>
            {tagLimit ? (
              <span className="tag-limit-warning">
                {`Maximum ${MAXIMUM_TAGS} tags can be added`}{' '}
                <FontAwesomeIcon icon={faExclamationCircle} />
              </span>
            ) : null}
          </form>
        </div>
        <div className="tag-modal-footer">
          <div className={tagModalFinishButtonClass} onClick={handleModalFinish}>
            <p className="tag-modal-finish-button-text">Finish</p>
          </div>
          <p className="tag-modal-cancel-button-text" onClick={handleModalCancel}>
            Cancel
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default TagModal;
