import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RadioGroup, FormControlLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import cardThumbnail from '../../images/card-thumbnail.png';
import { createFlow, setFlowLoading } from '../../actions/flowAction';

import CardStats from '../CardStats/CardStats';
import Tag from '../Tag/Tag';
import StyledRadio from '../StyledRadio/StyledRadio';
import TagModal from '../TagModal/TagModal';

import './CreateFlowCard.scss';

import { MAXIMUM_TITLE_LENGTH, MAXIMUM_DESCRIPTION_LENGTH } from '../../actions/constants';

const CreateFlowCard = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [visibility, setVisibility] = useState('registered_users');
  const [tags, setTags] = useState([]);
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailImage, setThumbnailImage] = useState(cardThumbnail);
  const [modalShow, setModalShow] = useState(false);

  const profileState = useSelector((state) => state.profile);

  const dispatch = useDispatch();

  const triggerFlowThumbnailInput = () => {
    document.querySelector('.create-flow-card-thumbnail-input').click();
  };

  const handleFlowThumbnailUpload = (e) => {
    if (e.target.files[0]) {
      setThumbnail(e.target.files[0]);
      setThumbnailImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleRemoveFlowThumbnail = (e) => {
    e.stopPropagation();

    setThumbnail('');
    setThumbnailImage(cardThumbnail);
    document.querySelector('.create-flow-card-thumbnail-input').value = '';
  };

  const handleCreateFlow = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append('title', title);
    formData.append('description', description);
    formData.append('visibility', visibility);
    formData.append('tags', JSON.stringify(tags));
    formData.append('thumbnail', thumbnail);

    dispatch(setFlowLoading());
    dispatch(createFlow(formData));
  };

  const removeTag = (tagId) => {
    setTags((prevState) => prevState.filter((tag, index) => index !== tagId));
  };

  return (
    <>
      <div className="create-flow-card-wrapper">
        <form
          className="create-flow-form"
          id="createFlowForm"
          method="POST"
          encType="multipart/form-data"
          onSubmit={handleCreateFlow}
        >
          <div
            className="create-flow-card-thumbnail"
            style={{
              backgroundImage: `url(${thumbnailImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
            onClick={triggerFlowThumbnailInput}
          >
            <input
              className="create-flow-card-thumbnail-input"
              name="create-flow-card-thumbnail"
              type="file"
              accept="image/jpg, image/jpeg, image/png"
              style={{ display: 'none' }}
              onChange={handleFlowThumbnailUpload}
            />
            {thumbnail && (
              <div
                className="create-flow-card-thumbnail-overlay"
                onClick={handleRemoveFlowThumbnail}
              >
                <FontAwesomeIcon icon={faTimes} className="cross-icon" />
              </div>
            )}
          </div>
          <div className="create-flow-card-details-wrapper">
            <div className="create-flow-card-details-div-1">
              <textarea
                className="create-flow-card-title-textarea"
                maxLength={`${MAXIMUM_TITLE_LENGTH}`}
                placeholder="Flow Title"
                onChange={(e) => setTitle(e.target.value)}
                required
              />
              <CardStats
                views={0}
                likes={0}
                videos={0}
                quizzes={0}
                width="60%"
                margin="6px 6px 15px"
              />
              <textarea
                className="create-flow-card-description-textarea"
                maxLength={`${MAXIMUM_DESCRIPTION_LENGTH}`}
                placeholder="Click here to start writing the description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="create-flow-card-details-div-2">
              <div className="create-flow-card-owner-wrapper">
                <FontAwesomeIcon icon={faUser} className="user-icon" />
                <p className="create-flow-card-owner-name">
                  {profileState.firstName + ' ' + profileState.lastName}
                </p>
              </div>
              <div className="create-flow-card-visibility-wrapper">
                <h3 className="create-flow-card-visibility-heading">Visibility</h3>
                <RadioGroup
                  defaultValue="registered_users"
                  aria-label="visibility"
                  name="styled-visibility-radios"
                  onChange={(e) => setVisibility(e.target.value)}
                >
                  <FormControlLabel value="just_me" control={<StyledRadio />} label="Me" />
                  <FormControlLabel
                    value="registered_users"
                    control={<StyledRadio />}
                    label="Registered Users"
                  />
                  <FormControlLabel value="everyone" control={<StyledRadio />} label="Everyone" />
                </RadioGroup>
              </div>
              <div className="create-flow-card-tags-row">
                {tags.length
                  ? tags.map((tag, index) => (
                      <Tag
                        key={index}
                        tagId={index}
                        tagText={tag}
                        tagType="flow"
                        editMode={true}
                        removeTag={removeTag}
                      />
                    ))
                  : null}
                <div className="create-flow-card-add-tag-button" onClick={() => setModalShow(true)}>
                  <FontAwesomeIcon icon={faPlus} className="add-icon" />
                  <p className="create-flow-card-add-tag-button-text">Add Tag</p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <TagModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        tagType="flow"
        tags={tags}
        setTags={setTags}
      />
    </>
  );
};

export default CreateFlowCard;
