import React from 'react';
import { useSelector } from 'react-redux';
import Carousel from 'react-material-ui-carousel';

import HomeBannerItem from '../HomeBannerItem/HomeBannerItem';

import './HomeBanner.scss';

const HomeBanner = () => {
  const flowState = useSelector((state) => state.flow);

  return (
    <div className="home-banner-container">
      <Carousel
        className="home-banner-carousel"
        autoPlay={true}
        swipe={false}
        indicators={false}
        animation={'slide'}
        timeout={550}
        navButtonsAlwaysVisible={true}
      >
        {flowState.featuredFlows.map((flow) => (
          <HomeBannerItem key={flow.uuid} flow={flow} />
        ))}
      </Carousel>
    </div>
  );
};

export default HomeBanner;
