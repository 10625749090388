import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { getMyData, setProfileLoading } from '../../../actions/profileAction';

import Home from '../Home/Home';
import MyProfileDashboard from '../../MyProfileDashboard/MyProfileDashboard';
import MyProfileSideMenu from '../../MyProfileSideMenu/MyProfileSideMenu';
import Loader from '../../Loader/Loader';

import './MyProfile.scss';

const MyProfile = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState('my-flows');
  const [previousSelectedMenuItem, setPreviousSelectedMenuItem] = useState('my-flows');
  const [showProfileSettings, setShowProfileSettings] = useState(false);

  const profileState = useSelector((state) => state.profile);

  const dispatch = useDispatch();

  const match = useRouteMatch();

  useEffect(() => {
    dispatch(setProfileLoading());
    dispatch(getMyData());
  }, []);

  useEffect(() => {
    if (match.path === '/my-profile/account-settings') {
      setSelectedMenuItem('');
    } else {
      setSelectedMenuItem('my-flows');
    }
  }, [match.path]);

  return !localStorage.getItem('accessToken') ? (
    <Home />
  ) : profileState.profileLoading ? (
    <Loader height="70vh" />
  ) : (
    <>
      <div className="my-container my-profile-container">
        <MyProfileSideMenu
          selectedMenuItem={selectedMenuItem}
          setSelectedMenuItem={setSelectedMenuItem}
          previousSelectedMenuItem={previousSelectedMenuItem}
          setPreviousSelectedMenuItem={setPreviousSelectedMenuItem}
          setShowProfileSettings={setShowProfileSettings}
        />
        <MyProfileDashboard
          selectedMenuItem={selectedMenuItem}
          showProfileSettings={showProfileSettings}
        />
      </div>
    </>
  );
};

export default MyProfile;
