import React, { useState, useEffect } from 'react';
import className from 'classnames';
import useElementSize from 'react-element-size';
import { format } from 'date-fns';
import { PDFDownloadLink } from '@react-pdf/renderer';

import certificateConfetti from '../../images/certificate-confetti.png';
import topicBadge from '../../images/topic-certificate-badge.png';
import flowBadge from '../../images/flow-certificate-badge.png';

import MyPDFDocument from '../MyPDFDocument/MyPDFDocument';

import './MyCertificate.scss';

const MyCertificate = (props) => {
  const [documentGenerated, setDocumentGenerated] = useState(false);

  const certificateWrapper = useElementSize();
  const certificateHeader = useElementSize();

  useEffect(() => {
    return () => {
      setDocumentGenerated(false);
    };
  }, []);

  const certificateClass = className(
    'my-certificate-wrapper',
    { topic: props.type === 'topic' },
    { flow: props.type === 'flow' },
  );

  return (
    <div
      className={certificateClass}
      style={{
        backgroundImage: `url(${certificateConfetti})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
      ref={certificateWrapper.setRef}
    >
      {props.type === 'topic' && (
        <h3
          className="flow-associated"
          style={{
            fontSize: `${(certificateWrapper.size.width / 1000) * 1.45}rem`,
            top: `${(certificateWrapper.size.width / 1000) * 40}px`,
            left: `${(certificateWrapper.size.width / 1000) * 40}px`,
          }}
        >
          {props.certificate.flow_title}
        </h3>
      )}
      <div
        className="my-certificate"
        style={{
          padding: `${(certificateWrapper.size.width / 1000) * 100}px 0 ${
            (certificateWrapper.size.width / 1000) * 40
          }px`,
        }}
      >
        <div
          className="certificate-header"
          style={{ height: `${(certificateWrapper.size.width / 1000) * 68}px` }}
          ref={certificateHeader.setRef}
        >
          <div className="certificate-badge-wrapper">
            <img
              className="certificate-badge"
              src={props.type === 'topic' ? topicBadge : flowBadge}
              style={{ width: `${certificateHeader.size.height * 2.7}px` }}
            />
          </div>
        </div>
        <div
          className="certificate-body"
          style={{
            padding: `${(certificateWrapper.size.width / 1000) * 40}px ${
              (certificateWrapper.size.width / 1000) * 100
            }px 0`,
          }}
        >
          <h3
            className="title"
            style={{ fontSize: `${(certificateWrapper.size.width / 1000) * 2.4}rem` }}
          >
            {props.type === 'topic' ? props.certificate.topic_title : props.certificate.flow_title}
          </h3>
          <h1
            className="score"
            style={{
              fontSize: `${(certificateWrapper.size.width / 1000) * 1.8}rem`,
              margin: `${(certificateWrapper.size.width / 1000) * 10.9}px 0`,
            }}
          >
            Obtained Score: {props.certificate.percentage}%
          </h1>
          <p
            className="detail"
            style={{ fontSize: `${(certificateWrapper.size.width / 1000) * 1.45}rem` }}
          >
            This is to certify that <span className="name">{props.certificate.username}</span> has
            successfully completed the {props.type}{' '}
            {props.type === 'topic' ? props.certificate.topic_title : props.certificate.flow_title}.
          </p>
        </div>
        <div
          className="certificate-footer"
          style={{
            padding: `0 ${(certificateWrapper.size.width / 1000) * 40}px`,
          }}
        >
          <div className="issue-date-wrapper">
            <h1
              className="certifications-footer-heading"
              style={{
                fontSize: `${(certificateWrapper.size.width / 1000) * 1.35}rem`,
                margin: `0 0 ${(certificateWrapper.size.width / 1000) * 2.8}px `,
              }}
            >
              Issue Date
            </h1>
            <p
              className="date"
              style={{
                fontSize: `${(certificateWrapper.size.width / 1000) * 1.45}rem`,
              }}
            >
              {format(new Date(props.certificate.date_obtained), 'dd/MM/yy')}
            </p>
          </div>
          {!props.verified &&
            (!documentGenerated ? (
              <button
                className="download-certificate-button"
                type="button"
                style={{
                  fontSize: `${(certificateWrapper.size.width / 1000) * 1.3}rem`,
                  padding: `${(certificateWrapper.size.width / 1000) * 10}px ${
                    (certificateWrapper.size.width / 1000) * 32
                  }px`,
                }}
                onClick={() => setDocumentGenerated(true)}
              >
                Generate PDF
              </button>
            ) : (
              <PDFDownloadLink
                className="download-certificate-button"
                style={{
                  fontSize: `${(certificateWrapper.size.width / 1000) * 1.3}rem`,
                  padding: `${(certificateWrapper.size.width / 1000) * 10}px ${
                    (certificateWrapper.size.width / 1000) * 32
                  }px`,
                }}
                document={
                  <MyPDFDocument
                    type={props.type}
                    myProfile={true}
                    pdfData={props.type === 'topic' ? props.certificate : props.selectedCertificate}
                  />
                }
                fileName={'kooledge_certificate.pdf'}
              >
                {({ loading }) => (loading ? 'Loading document...' : 'Download Certificate')}
              </PDFDownloadLink>
            ))}
          <div className="kooledge-link-wrapper">
            <h1
              className="certifications-footer-heading"
              style={{
                fontSize: `${(certificateWrapper.size.width / 1000) * 1.45}rem`,
                margin: `0 0 ${(certificateWrapper.size.width / 1000) * 2.8}px `,
              }}
            >
              Kooledge
            </h1>
            <p
              className="link"
              style={{
                fontSize: `${(certificateWrapper.size.width / 1000) * 1.35}rem`,
              }}
            >
              www.kooledge.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCertificate;
