import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import authBg from '../../images/auth-bg.png';

import { signIn, clearError, setAuthLoading } from '../../actions/authAction';
import { getProfile, clearProfile } from '../../actions/profileAction';
import { clearAllFlows, clearFlowState } from '../../actions/flowAction';

import Loader from '../Loader/Loader';

import './Login.scss';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const authState = useSelector((state) => state.auth);
  const flowState = useSelector((state) => state.flow);

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    if (flowState.errorMessage?.data?.detail === 'Not found.') {
      setOpenSnackbar(true);
    }
    dispatch(clearProfile());

    return () => {
      setIsRegistered(false);
      setOpenSnackbar(false);
      dispatch(clearFlowState());
      dispatch(clearError());
    };
  }, []);

  useEffect(() => {
    if (authState.accessToken) {
      setIsRegistered(true);
      setOpenSnackbar(true);
      dispatch(clearError());
    }
  }, [authState.accessToken]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    dispatch(setAuthLoading());
    dispatch(clearAllFlows());
    await dispatch(signIn({ email, password }));
    dispatch(getProfile());
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  return authState.authLoading ? (
    <Loader />
  ) : localStorage.getItem('accessToken') ? (
    <Redirect to="/" />
  ) : (
    <>
      <div
        className="login-container"
        style={{
          backgroundImage: `url(${authBg})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="auth-card">
          <div className="auth-card-header">
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="auth-card-hearder-icon"
              onClick={() => history.push('/')}
            />
            <h3 className="auth-card-header-text">Sign In</h3>
            <FontAwesomeIcon
              icon={faTimes}
              className="auth-card-hearder-icon"
              onClick={() => history.push('/')}
            />
          </div>
          <div className="auth-card-body">
            <form className="auth-form" id="loginForm" onSubmit={handleSignIn}>
              <TextField
                className="auth-card-input"
                label="Email"
                variant="filled"
                type="email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              {authState.errorMessage?.detail && (
                <p className="error-message" style={{ margin: '8px 16px 0' }}>
                  {authState.errorMessage.detail}
                </p>
              )}
              <TextField
                className="auth-card-input"
                label="Password"
                variant="filled"
                type={showPassword ? 'text' : 'password'}
                required
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {!showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {authState.errorMessage?.non_field_errors && (
                <p className="error-message" style={{ margin: '8px 16px 0' }}>
                  {authState.errorMessage.non_field_errors[0]}
                </p>
              )}
              <Link to="/forgot-password" className="forgot-password-link">
                Forgot Password
              </Link>
            </form>
          </div>
          <div className="auth-card-footer">
            <button className="auth-card-button-dark" type="submit" form="loginForm">
              Sign In
            </button>
            <button
              className="auth-card-button-light"
              type="button"
              onClick={() => history.push('/')}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <MuiAlert elevation={6} variant="filled" severity={isRegistered ? 'success' : 'error'}>
          {isRegistered
            ? 'Registration successful. Please verify your account and login.'
            : 'Login to view flow.'}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default Login;
