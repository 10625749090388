import React from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faPlay } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt, faStickyNote, faUser } from '@fortawesome/free-regular-svg-icons';
import blankThumbnail from '../../images/blank-thumbnail.png';

import { removeFeaturedVideo } from '../../actions/videoAction';
import { removeOfficialQuiz } from '../../actions/quizAction';

import CardStats from '../CardStats/CardStats';

import './TopicFeaturedContentCard.scss';

const TopicFeaturedContentCard = (props) => {
  const dispatch = useDispatch();

  const match = useRouteMatch();

  const handleRemoveFeatured = () => {
    if (props.quizView) {
      dispatch(removeOfficialQuiz(props.topicUuid, match.params.uuid));
    } else {
      dispatch(removeFeaturedVideo(props.topicUuid, match.params.uuid));
      props.setCurrentVideo('');
      props.setCurrentVideoTitle('');
    }
  };

  const handlePlayVideo = () => {
    props.setCurrentVideo(props.featuredVideo);
  };

  return (
    <>
      {props.officialQuiz || props.featuredVideo ? (
        <div className="topic-featured-content-card">
          <div
            className="topic-featured-content-card-thumbnail"
            style={
              props.quizView
                ? {
                    backgroundImage: `url(${
                      props.officialQuiz
                        ? props.officialQuiz.thumbnail
                          ? props.officialQuiz.thumbnail
                          : blankThumbnail
                        : blankThumbnail
                    })`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }
                : {
                    backgroundImage: `url(${
                      props.featuredVideo
                        ? props.featuredVideo.thumbnail
                          ? props.featuredVideo.thumbnail
                          : blankThumbnail
                        : blankThumbnail
                    })`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }
            }
          >
            {props.editMode ? (
              <div
                className="featured-content-wrapper remove-featured-content"
                onClick={handleRemoveFeatured}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="featured-content-icon" />
                <p className="featured-content-text">{`Remove ${
                  props.quizView ? 'Official Quiz' : 'Featured Video'
                }`}</p>
              </div>
            ) : (
              <div className="featured-content-wrapper">
                <FontAwesomeIcon icon={faStar} className="featured-content-icon" />
                <p className="featured-content-text">{`${
                  props.quizView ? 'Official Quiz' : 'Featured Video'
                }`}</p>
                {!props.editMode && props.quizView ? (
                  <div
                    className="topic-content-card-thumbnail-overlay"
                    onClick={() => props.setQuizWindowModalShow(true)}
                  >
                    <FontAwesomeIcon icon={faStickyNote} className="take-quiz-icon" />
                    <p className="topic-content-card-take-quiz-text">Take quiz</p>
                  </div>
                ) : (
                  <div className="topic-content-card-thumbnail-overlay" onClick={handlePlayVideo}>
                    <FontAwesomeIcon icon={faPlay} className="play-icon" />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="topic-featured-content-card-title-and-stats-wrapper">
            {props.quizView ? (
              <div className="topic-featured-content-card-title-row">
                <h3 className="topic-featured-content-card-title">
                  {props.officialQuiz ? props.officialQuiz.title : ''}
                </h3>
              </div>
            ) : (
              <div className="topic-featured-content-card-title-row">
                <h3 className="topic-featured-content-card-title">
                  {props.featuredVideo ? props.featuredVideo.title : ''}
                </h3>
              </div>
            )}
            {props.quizView ? (
              <div className="topic-content-card-quiz-stats">
                <CardStats
                  tries={props.officialQuiz.try_count}
                  percentageOfTriesToPass={props.officialQuiz.average_try_count}
                  questions={props.officialQuiz.questions.length}
                  width="auto"
                  margin="15px 0 0"
                />
                {props.officialQuiz.average_time > 0 && (
                  <CardStats
                    averageTime={props.officialQuiz.average_time}
                    width="auto"
                    margin="8px 0 0"
                  />
                )}
                {props.officialQuiz.estimated_time > 0 && (
                  <CardStats
                    estimatedTime={props.officialQuiz.estimated_time}
                    width="auto"
                    margin="8px 0 0"
                  />
                )}
              </div>
            ) : (
              <div className="topic-content-card-video-stats">
                <div className="video-owner-wrapper">
                  <FontAwesomeIcon icon={faUser} className="card-stats-icon" />
                  <p className="owner-name">{props.featuredVideo.creator_name}</p>
                </div>
                <CardStats
                  views={props.featuredVideo.views}
                  likes={props.featuredVideo.likes}
                  width="auto"
                  margin="8px 0 0"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="topic-featured-content-card-empty">
          <p className="no-featured-content-text">{`No ${
            props.quizView ? 'official quiz' : 'featured video'
          }`}</p>
        </div>
      )}
    </>
  );
};

export default TopicFeaturedContentCard;
