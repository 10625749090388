import React from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import ResponsivePlayer from '../ResponsivePlayer/ResponsivePlayer';

import './CustomModal.scss';

const CustomModal = (props) => {
  const handleModalSubmit = () => {
    props.handleSubmit();
    props.onHide();
  };

  const handleModalCancel = () => {
    props.onHide();
  };

  const customModalClass = classNames(
    'custom-modal',
    { topic: props.type === 'topic' },
    { flow: props.type === 'flow' },
  );

  const customModalButtonClass = classNames(
    'custom-modal-custom-button',
    { topic: props.type === 'topic' },
    { flow: props.type === 'flow' },
  );

  return (
    <Modal {...props} centered className="custom-modal">
      {props.player ? (
        typeof props.videoUrl === 'string' && (
          <div className="suggest-video-player">
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="close-modal-icon"
              onClick={handleModalCancel}
            />
            <ResponsivePlayer url={props.videoUrl} playerPlaying={true} edit={true} />
          </div>
        )
      ) : (
        <div className={customModalClass}>
          <h3 className="custom-modal-title">{props.modalTitle}</h3>
          <p className="custom-modal-body">{props.modalBody}</p>
          <div className="custom-modal-footer">
            <div className={customModalButtonClass} onClick={handleModalSubmit}>
              <p className="custom-modal-custom-button-text">{props.submitButtonText}</p>
            </div>
            <p className="custom-modal-cancel-button-text" onClick={handleModalCancel}>
              Cancel
            </p>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default CustomModal;
