import { url } from './constants';

import axios from 'axios';
import axiosApiInstance from './axios';

import {
  REGISTER,
  REGISTER_ERROR,
  VERIFY_EMAIL,
  VERIFY_EMAIL_ERROR,
  LOGIN,
  LOGIN_ERROR,
  LOGOUT,
  PASSWORD_RESET_EMAIL,
  PASSWORD_RESET_EMAIL_ERROR,
  CLEAR_PASSWORD_RESET_STATE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_ERROR,
  CLEAR_ERROR,
  AUTH_LOADING,
} from './actionTypes';

export const signUp = (formData) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/user/register/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    dispatch({
      type: REGISTER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: REGISTER_ERROR,
      payload: err.response,
    });
  }
};

export const verifyEmail = (formData) => async (dispatch) => {
  try {
    const res = await axios.patch(`${url}/user/verify-account/`, formData);

    dispatch({
      type: VERIFY_EMAIL,
    });
  } catch (err) {
    dispatch({
      type: VERIFY_EMAIL_ERROR,
      payload: err.response,
    });
  }
};

export const signIn = (formData) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/user/login/`, formData);

    localStorage.setItem('uuid', res.data.uuid);
    localStorage.setItem('accessToken', res.data.access_token);
    localStorage.setItem('refreshToken', res.data.refresh_token);

    dispatch({
      type: LOGIN,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LOGIN_ERROR,
      payload: err.response,
    });
  }
};

export const signOut = () => (dispatch) => {
  localStorage.removeItem('uuid');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');

  dispatch({
    type: LOGOUT,
  });
};

export const requestPasswordResetEmail = (formData) => async (dispatch) => {
  try {
    const res = await axios.post(`${url}/user/password-reset-email/`, formData);

    dispatch({
      type: PASSWORD_RESET_EMAIL,
      payload: formData,
    });
  } catch (err) {
    dispatch({
      type: PASSWORD_RESET_EMAIL_ERROR,
      payload: err.response,
    });
  }
};

export const clearPasswordResetState = () => (dispatch) => {
  dispatch({
    type: CLEAR_PASSWORD_RESET_STATE,
  });
};

export const changePassword = (formData) => async (dispatch) => {
  try {
    const res = await axios.patch(`${url}/user/password-reset/`, formData);

    dispatch({
      type: CHANGE_PASSWORD,
    });
  } catch (err) {
    dispatch({
      type: CHANGE_PASSWORD_ERROR,
      payload: err.response,
    });
  }
};

export const updatePassword = (formData) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.patch(`${url}/user/change-password/`, formData);

    dispatch({
      type: UPDATE_PASSWORD,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_PASSWORD_ERROR,
      payload: err.response,
    });
  }
};

export const clearError = () => (dispatch) => {
  dispatch({
    type: CLEAR_ERROR,
  });
};

export const setAuthLoading = () => (dispatch) => {
  dispatch({
    type: AUTH_LOADING,
  });
};
