import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import addTopicContentIcon from '../../images/add-topic-content.png';

import { getMyVideos, setVideoLoading } from '../../actions/videoAction';
import { getMyQuizzes, setQuizLoading } from '../../actions/quizAction';

import AddTopicVideoModal from '../AddTopicVideoModal/AddTopicVideoModal';
import AddTopicQuizModal from '../AddTopicQuizModal/AddTopicQuizModal';

import './AddTopicContentCard.scss';

const AddTopicContentCard = (props) => {
  const [addTopicVideoModalShow, setAddTopicVideoModalShow] = useState(false);
  const [addTopicQuizModalShow, setAddTopicQuizModalShow] = useState(false);

  const flowState = useSelector((state) => state.flow);

  const dispatch = useDispatch();

  const handleAddTopicVideoModalShow = () => {
    setAddTopicVideoModalShow(!addTopicVideoModalShow);
    dispatch(setVideoLoading());
    dispatch(getMyVideos());
  };

  const handleAddTopicQuizModalShow = () => {
    setAddTopicQuizModalShow(!addTopicQuizModalShow);
    dispatch(setQuizLoading());
    dispatch(getMyQuizzes());
  };

  const addTopicContentCardClass = classNames('add-topic-content-card', {
    'add-topic-content-card-show': props.editMode,
  });

  return (
    <>
      <div
        className={addTopicContentCardClass}
        onClick={props.quizView ? handleAddTopicQuizModalShow : handleAddTopicVideoModalShow}
      >
        <img src={addTopicContentIcon} />
        <p className="add-topic-content-card-text">{`Add ${props.quizView ? 'Quiz' : 'Video'}`}</p>
      </div>
      {props.quizView ? (
        <AddTopicQuizModal
          show={addTopicQuizModalShow}
          onHide={() => setAddTopicQuizModalShow(false)}
          topicUuid={props.topicUuid}
          topicQuizzes={props.topicQuizzes}
        />
      ) : (
        <AddTopicVideoModal
          show={addTopicVideoModalShow}
          onHide={() => setAddTopicVideoModalShow(false)}
          topicUuid={props.topicUuid}
          topicVideos={props.topicVideos}
        />
      )}
    </>
  );
};

export default AddTopicContentCard;
