// AUTHENTICATION
export const REGISTER = 'REGISTER';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR';
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const PASSWORD_RESET_EMAIL = 'PASSWORD_RESET_EMAIL';
export const PASSWORD_RESET_EMAIL_ERROR = 'PASSWORD_RESET_EMAIL_ERROR';
export const CLEAR_PASSWORD_RESET_STATE = 'CLEAR_PASSWORD_RESET_STATE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const AUTH_LOADING = 'AUTH_LOADING';

// PROFILE
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';
export const GET_MY_DATA = 'GET_MY_DATA';
export const GET_MY_DATA_ERROR = 'GET_MY_DATA_ERROR';
export const GET_MY_PROFILE_VIDEOS = 'GET_MY_PROFILE_VIDEOS';
export const GET_MY_PROFILE_VIDEOS_ERROR = 'GET_MY_PROFILE_VIDEOS_ERROR';
export const GET_MY_PROFILE_QUIZZES = 'GET_MY_PROFILE_QUIZZES';
export const GET_MY_PROFILE_QUIZZES_ERROR = 'GET_MY_PROFILE_QUIZZES_ERROR';
export const GET_MY_PROFILE_SUGGESTIONS = 'GET_MY_PROFILE_SUGGESTIONS';
export const GET_MY_PROFILE_SUGGESTIONS_ERROR = 'GET_MY_PROFILE_SUGGESTIONS_ERROR';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_USER_INFO_ERROR = 'UPDATE_USER_INFO_ERROR';
export const EDIT_VIDEO = 'EDIT_VIDEO';
export const EDIT_VIDEO_ERROR = 'EDIT_VIDEO_ERROR';
export const DELETE_VIDEO = 'DELETE_VIDEO';
export const DELETE_VIDEO_ERROR = 'DELETE_VIDEO_ERROR';
export const APPROVE_SUGGESTED_VIDEO = 'APPROVE_SUGGESTED_VIDEO';
export const APPROVE_SUGGESTED_VIDEO_ERROR = 'APPROVE_SUGGESTED_VIDEO_ERROR';
export const EDIT_QUIZ = 'EDIT_QUIZ';
export const EDIT_QUIZ_ERROR = 'EDIT_QUIZ_ERROR';
export const DELETE_QUIZ = 'DELETE_QUIZ';
export const DELETE_QUIZ_ERROR = 'DELETE_QUIZ_ERROR';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_DASHBOARD_LOADING = 'PROFILE_DASHBOARD_LOADING';

// NAVBAR
export const TOGGLE_PROFILE_MENU = 'TOGGLE_PROFILE_MENU';
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';

// FLOW
export const CREATE_FLOW = 'CREATE_FLOW';
export const CREATE_FLOW_ERROR = 'CREATE_FLOW_ERROR';
export const GET_FLOW = 'GET_FLOW';
export const GET_FLOW_ERROR = 'GET_FLOW_ERROR';
export const GET_ALL_FLOWS = 'GET_ALL_FLOWS';
export const GET_ALL_FLOWS_ERROR = 'GET_ALL_FLOWS_ERROR';
export const GET_FEATURED_FLOWS = 'GET_FEATURED_FLOWS';
export const GET_FEATURED_FLOWS_ERROR = 'GET_FEATURED_FLOWS_ERROR';
export const APPEND_FLOWS = 'APPEND_FLOWS';
export const APPEND_FLOWS_ERROR = 'APPEND_FLOWS_ERROR';
export const SEARCH_FLOWS = 'SEARCH_FLOWS';
export const SEARCH_FLOWS_ERROR = 'SEARCH_FLOWS_ERROR';
export const CLEAR_SEARCHED_FLOWS = 'CLEAR_SEARCHED_FLOWS';
export const CLEAR_ALL_FLOWS = 'CLEAR_ALL_FLOWS';
export const EDIT_FLOW = 'EDIT_FLOW';
export const EDIT_FLOW_ERROR = 'EDIT_FLOW_ERROR';
export const DELETE_FLOW = 'DELETE_FLOW';
export const DELETE_FLOW_ERROR = 'DELETE_FLOW_ERROR';
export const CLEAR_FLOW_STATE = 'CLEAR_FLOW_STATE';
export const SEARCH_FLOW_LOADING = 'SEARCH_FLOW_LOADING';
export const FLOW_LOADING = 'FLOW_LOADING';
export const CLEAR_LOADING = 'CLEAR_LOADING';
export const LOAD_SEARCH_RESULTS = 'LOAD_SEARCH_RESULTS';
export const INCREMENT_SEARCH_FLOWS_API_COUNT = 'INCREMENT_SEARCH_FLOWS_API_COUNT';

// TOPIC
export const CREATE_TOPIC = 'CREATE_TOPIC';
export const CREATE_TOPIC_ERROR = 'CREATE_TOPIC_ERROR';
export const DELETE_TOPIC = 'DELETE_TOPIC';
export const DELETE_TOPIC_ERROR = 'DELETE_TOPIC_ERROR';
export const EDIT_TOPIC = 'EDIT_TOPIC';
export const EDIT_TOPIC_ERROR = 'EDIT_TOPIC_ERROR';
export const CLEAR_TOPIC_CREATED = 'CLEAR_TOPIC_CREATED';
export const TOPIC_LOADING = 'TOPIC_LOADING';

// VIDEO
export const UPLOAD_VIDEO = 'UPLOAD_VIDEO';
export const UPLOAD_VIDEO_ERROR = 'UPLOAD_VIDEO_ERROR';
export const GET_MY_VIDEOS = 'GET_MY_VIDEOS';
export const GET_MY_VIDEOS_ERROR = 'GET_MY_VIDEOS_ERROR';
export const ADD_VIDEO_TO_TOPIC = 'ADD_VIDEO_TO_TOPIC';
export const ADD_VIDEO_TO_TOPIC_ERROR = 'ADD_VIDEO_TO_TOPIC_ERROR';
export const REMOVE_VIDEO_FROM_TOPIC = 'REMOVE_VIDEO_FROM_TOPIC';
export const REMOVE_VIDEO_FROM_TOPIC_ERROR = 'REMOVE_VIDEO_FROM_TOPIC_ERROR';
export const REMOVE_FEATURED_VIDEO = 'REMOVE_FEATURED_VIDEO';
export const REMOVE_FEATURED_VIDEO_ERROR = 'REMOVE_FEATURED_VIDEO_ERROR';
export const SET_FEATURED_VIDEO = 'SET_FEATURED_VIDEO';
export const SET_FEATURED_VIDEO_ERROR = 'SET_FEATURED_VIDEO_ERROR';
export const SEARCH_VIDEOS = 'SEARCH_VIDEOS';
export const SEARCH_VIDEOS_ERROR = 'SEARCH_VIDEOS_ERROR';
export const CLEAR_VIDEO_STATE = 'CLEAR_VIDEO_STATE';
export const CLEAR_SEARCH_VIDEOS_STATE = 'CLEAR_SEARCH_VIDEOS_STATE';
export const VIDEO_LOADING = 'VIDEO_LOADING';
export const LIKE_VIDEO = 'LIKE_VIDEO';
export const LIKE_VIDEO_ERROR = 'LIKE_VIDEO_ERROR';
export const DISLIKE_VIDEO = 'DISLIKE_VIDEO';
export const DISLIKE_VIDEO_ERROR = 'DISLIKE_VIDEO_ERROR';
export const VIEW_VIDEO = 'VIEW_VIDEO';
export const VIEW_VIDEO_ERROR = 'VIEW_VIDEO_ERROR';
export const SUGGEST_VIDEO = 'SUGGEST_VIDEO';
export const SUGGEST_VIDEO_ERROR = 'SUGGEST_VIDEO_ERROR';
export const SEARCH_VIDEO_LOADING = 'SEARCH_VIDEO_LOADING';
export const INCREMENT_SEARCH_VIDEOS_API_COUNT = 'INCREMENT_SEARCH_VIDEOS_API_COUNT';

// QUIZ
export const CREATE_QUIZ = 'CREATE_QUIZ';
export const CREATE_QUIZ_ERROR = 'CREATE_QUIZ_ERROR';
export const GET_MY_QUIZZES = 'GET_MY_QUIZZES';
export const GET_MY_QUIZZES_ERROR = 'GET_MY_QUIZZES_ERROR';
export const ADD_QUIZ_TO_TOPIC = 'ADD_QUIZ_TO_TOPIC';
export const ADD_QUIZ_TO_TOPIC_ERROR = 'ADD_QUIZ_TO_TOPIC_ERROR';
export const REMOVE_QUIZ_FROM_TOPIC = 'REMOVE_QUIZ_FROM_TOPIC';
export const REMOVE_QUIZ_FROM_TOPIC_ERROR = 'REMOVE_QUIZ_FROM_TOPIC_ERROR';
export const SET_OFFICIAL_QUIZ = 'SET_OFFICIAL_QUIZ';
export const SET_OFFICIAL_QUIZ_ERROR = 'SET_OFFICIAL_QUIZ_ERROR';
export const REMOVE_OFFICIAL_QUIZ = 'REMOVE_OFFICIAL_QUIZ';
export const REMOVE_OFFICIAL_QUIZ_ERROR = 'REMOVE_OFFICIAL_QUIZ_ERROR';
export const INCREMENT_QUIZ_TRY = 'INCREMENT_QUIZ_TRY';
export const INCREMENT_QUIZ_TRY_ERROR = 'INCREMENT_QUIZ_TRY_ERROR';
export const SUBMIT_QUIZ = 'SUBMIT_QUIZ';
export const SUBMIT_QUIZ_ERROR = 'SUBMIT_QUIZ_ERROR';
export const SET_QUIZ_SUBMITTED = 'SET_QUIZ_SUBMITTED';
export const CLEAR_SUBMISSION_RESPONSE = 'CLEAR_SUBMISSION_RESPONSE';
export const CLEAR_QUIZ_STATE = 'CLEAR_QUIZ_STATE';
export const QUIZ_LOADING = 'QUIZ_LOADING';

// COMMENT
export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_COMMENT_ERROR = 'ADD_COMMENT_ERROR';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const DELETE_COMMENT_ERROR = 'DELETE_COMMENT_ERROR';
export const ADD_REPLY = 'ADD_REPLY';
export const ADD_REPLY_ERROR = 'ADD_REPLY_ERROR';

// CERTIFICATE
export const CREATE_TOPIC_CERTIFICATE = 'CREATE_TOPIC_CERTIFICATE';
export const CREATE_TOPIC_CERTIFICATE_ERROR = 'CREATE_TOPIC_CERTIFICATE_ERROR';
export const CREATE_PDF = 'CREATE_PDF';
export const CREATE_PDF_ERROR = 'CREATE_PDF_ERROR';
export const VERIFY_CERTIFICATE = 'VERIFY_CERTIFICATE';
export const VERIFY_CERTIFICATE_ERROR = 'VERIFY_CERTIFICATE_ERROR';
export const CLEAR_CERTIFICATE_STATE = 'CLEAR_CERTIFICATE_STATE';
