import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import blankImage from '../../images/blank-image.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import {
  TextField,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { withStyles } from '@material-ui/core/styles';

import authBg from '../../images/auth-bg.png';

import { signUp, setAuthLoading, clearError } from '../../actions/authAction';
import { MINIMUM_PASSWORD_LENGTH, COUNTRIES } from '../../actions/constants';

import Loader from '../Loader/Loader';

import './Register.scss';

const styles = (theme) => ({
  icon: {
    fill: '#ABABAB',
    fontSize: '2rem',
    marginRight: '13px',
  },
});

const Register = ({ classes }) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [gender, setGender] = useState('');
  const [country, setCountry] = useState('');
  const [birthday, setBirthday] = useState('');
  const [referenceEmail, setReferenceEmail] = useState('');
  const [avatar, setAvatar] = useState('');
  const [avatarImage, setAvatarImage] = useState(blankImage);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const authState = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    return () => {
      dispatch(clearError());
    };
  }, []);

  useEffect(() => {
    if (authState.accessToken) {
      history.push('/login');
    }
  }, [authState.accessToken]);

  useEffect(() => {
    if (authState.errorMessage) {
    }
  }, [authState.errorMessage]);

  const handleSignUp = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append('email', email);
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('password', password);
    formData.append('gender', gender);
    formData.append('country', country);
    if (birthday) {
      formData.append('birthday', format(new Date(birthday), 'yyyy-MM-dd'));
    }
    formData.append('inviter_email', referenceEmail);
    formData.append('avatar', avatar);

    if (password === confirmPassword) {
      dispatch(setAuthLoading());
      dispatch(signUp(formData));
    }
  };

  const triggerAvatarInput = () => {
    document.querySelector('.avatar-input').click();
  };

  const handleAvatarUpload = (e) => {
    if (e.target.files[0]) {
      setAvatarImage(URL.createObjectURL(e.target.files[0]));
      setAvatar(e.target.files[0]);
    }
  };

  const handleRemoveAvatar = () => {
    setAvatarImage('');
    setAvatar('');
    document.querySelector('.avatar-input').value = '';
  };

  const handleDateButtonClick = () => {
    document.getElementById('dateLabel').classList.add('MuiInputLabel-shrink');
    document
      .querySelector('.hidden-date-picker .MuiInputAdornment-root .MuiIconButton-root')
      .click();
  };

  const handleDateInputClick = () => {
    document.getElementById('dateLabel').classList.add('MuiInputLabel-shrink');
    document
      .querySelector('.hidden-date-picker .MuiInputAdornment-root .MuiIconButton-root')
      .click();
  };

  return authState.authLoading ? (
    <Loader />
  ) : (
    <>
      <div
        className="register-container"
        style={{
          backgroundImage: `url(${authBg})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="auth-card">
          <div className="auth-card-header">
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="auth-card-hearder-icon"
              onClick={() => history.push('/')}
            />
            <h3 className="auth-card-header-text">Sign Up</h3>
            <FontAwesomeIcon
              icon={faTimes}
              className="auth-card-hearder-icon"
              onClick={() => history.push('/')}
            />
          </div>
          <div className="auth-card-body">
            <form className="auth-form" id="registerForm" onSubmit={handleSignUp}>
              <div className="register-form-avatar-input-wrapper">
                <div className="register-form-avatar-image">
                  {avatar ? (
                    <div
                      className="avatar-image"
                      style={{
                        backgroundImage: `url(${avatarImage})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                    >
                      <div className="avatar-image-overlay" onClick={handleRemoveAvatar}>
                        <FontAwesomeIcon icon={faTimes} className="delete-avatar-icon" />
                      </div>
                    </div>
                  ) : (
                    <div className="avatar-image-none">
                      <FontAwesomeIcon icon={faUser} className="blank-avatar-icon" />
                    </div>
                  )}
                </div>
                <div className="register-form-avatar-input-text">
                  <p className="register-form-set-avatar">Profile Picture</p>
                  <div className="register-form-avatar-input" onClick={triggerAvatarInput}>
                    Upload
                    <input
                      className="avatar-input"
                      name="register-form-avatar-input"
                      type="file"
                      accept="image/jpg, image/jpeg, image/png"
                      style={{ display: 'none' }}
                      onChange={handleAvatarUpload}
                    />
                  </div>
                </div>
              </div>
              <div className="register-form-name-wrapper">
                <TextField
                  className="auth-card-input"
                  label="First Name"
                  variant="filled"
                  required
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                  className="auth-card-input"
                  label="Last Name"
                  variant="filled"
                  required
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <TextField
                className="auth-card-input"
                label="Email Address"
                variant="filled"
                type="email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              {authState.errorMessage?.data?.email && (
                <p className="error-message" style={{ margin: '8px 16px 0' }}>
                  {authState.errorMessage.data.email[0]}
                </p>
              )}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="MuiFormControl-root MuiTextField-root auth-card-input">
                  <label
                    id="dateLabel"
                    className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-filled"
                    data-shrink="fals"
                  >
                    Date Of Birth
                  </label>
                  <div className="MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-formControl MuiInputBase-adornedEnd MuiFilledInput-adornedEnd">
                    <input
                      id="birthdayPicker"
                      aria-invalid="false"
                      type="text"
                      className="MuiInputBase-input MuiFilledInput-input MuiInputBase-inputAdornedEnd"
                      value={birthday ? format(new Date(birthday), 'yyyy-MM-dd') : ''}
                      onClick={handleDateInputClick}
                    />
                    <div className="MuiInputAdornment-root MuiInputAdornment-positionEnd">
                      <button
                        className="MuiButtonBase-root MuiIconButton-root"
                        tabIndex="0"
                        type="button"
                        aria-label="change date"
                        onClick={handleDateButtonClick}
                      >
                        <span className="MuiIconButton-label">
                          <svg
                            className="MuiSvgIcon-root"
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"></path>
                            <path fill="none" d="M0 0h24v24H0z"></path>
                          </svg>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="hidden-date-picker"
                  label="Date Of Birth"
                  format="yyyy-mm-dd"
                  value={birthday}
                  onChange={setBirthday}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  style={{
                    display: 'none',
                  }}
                  maxDate={
                    new Date(
                      `${new Date().getFullYear() - 18}-${
                        new Date().getMonth() + 1 < 10 ? '0' : ''
                      }${new Date().getMonth() + 1}-${new Date().getDate()}`,
                    )
                  }
                />
              </MuiPickersUtilsProvider>
              <FormControl variant="filled" className="auth-card-input">
                <InputLabel>Gender</InputLabel>
                <Select
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                  defaultValue=""
                  IconComponent={KeyboardArrowDownIcon}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="non_binary">Non Binary</MenuItem>
                  <MenuItem value="prefer_not_to_say">Prefer Not To Say</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="filled" className="auth-card-input">
                <InputLabel>Country</InputLabel>
                <Select
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                  defaultValue=""
                  IconComponent={KeyboardArrowDownIcon}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <MenuItem value=""></MenuItem>
                  {COUNTRIES.map((country) => (
                    <MenuItem key={country.key} value={country.value}>
                      {country.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                className="auth-card-input"
                label="Password"
                variant="filled"
                type={showPassword ? 'text' : 'password'}
                required
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {!showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  inputProps: {
                    minLength: MINIMUM_PASSWORD_LENGTH,
                  },
                }}
              />
              <TextField
                className="auth-card-input"
                style={{
                  height:
                    confirmPassword !== '' && password !== '' && confirmPassword !== password
                      ? '91px'
                      : '64px',
                }}
                label="Confirm Password"
                variant="filled"
                type={showConfirmPassword ? 'text' : 'password'}
                required
                onChange={(e) => setConfirmPassword(e.target.value)}
                helperText={
                  confirmPassword !== '' && password !== '' && confirmPassword !== password
                    ? 'Passwords do not match'
                    : ''
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                      >
                        {!showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  inputProps: {
                    minLength: MINIMUM_PASSWORD_LENGTH,
                  },
                }}
              />
              <TextField
                className="auth-card-input"
                label="Email of person who referred you"
                variant="filled"
                type="email"
                onChange={(e) => setReferenceEmail(e.target.value)}
              />
            </form>
          </div>
          <div className="auth-card-footer">
            <button className="auth-card-button-dark" type="submit" form="registerForm">
              Sign Up
            </button>
            <button
              className="auth-card-button-light"
              type="button"
              onClick={() => history.push('/')}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(Register);
