import React from 'react';

import './PostVideoOption.scss';

const PostVideoOption = (props) => {
  return (
    <div className="post-video-option" onClick={props.handleOnClick}>
      {props.icon}
      <p className="option-text">{props.optionText}</p>
    </div>
  );
};

export default PostVideoOption;
