import { url } from './constants';

const axios = require('axios');
const axiosApiInstance = axios.create();

const refreshAccessToken = async () => {
  const refresh = localStorage.getItem('refreshToken');

  const res = await axios.post(`${url}/user/refresh/`, { refresh });

  return res.data.access;
};

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('accessToken');
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    const currentPath = window.location.pathname;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      var accessToken = '';
      accessToken = await refreshAccessToken();
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  },
);

export default axiosApiInstance;
