import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import authBg from '../../images/auth-bg.png';

import { changePassword, clearPasswordResetState, setAuthLoading } from '../../actions/authAction';
import { MINIMUM_PASSWORD_LENGTH } from '../../actions/constants';

import Loader from '../Loader/Loader';

import './ChangePassword.scss';

const ChangePassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const authState = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const history = useHistory();

  const match = useRouteMatch();

  useEffect(() => {
    return () => {
      dispatch(clearPasswordResetState());
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password === confirmPassword) {
      dispatch(setAuthLoading());
      dispatch(
        changePassword({ password, uidb64: match.params.uidb64, token: match.params.token }),
      );
    }
  };

  return authState.authLoading ? (
    <Loader />
  ) : localStorage.getItem('accessToken') ? (
    <Redirect to="/" />
  ) : authState.successMessage === 'Password changed' ? (
    <div
      className="change-password-container"
      style={{
        backgroundImage: `url(${authBg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="auth-card">
        <div className="auth-card-header change-password-header-success">
          <h3 className="auth-card-header-text">You're good to go!</h3>
        </div>
        <div className="auth-card-body">
          <p className="change-password-body-success-text">
            You have successfully reset your password.
          </p>
        </div>
        <div className="auth-card-footer">
          <button
            className="auth-card-button-dark back-to-sign-in-button"
            onClick={() => history.push('/login')}
          >
            Back to Sign In
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className="change-password-container">
      <div className="auth-card">
        <div className="auth-card-header auth-card-header-multiline">
          <div className="auth-card-header-multiline-heading-wrapper">
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="auth-card-hearder-icon"
              onClick={() => history.push('/login')}
            />
            <h3 className="auth-card-header-text">Set New Password</h3>
            <FontAwesomeIcon
              icon={faTimes}
              className="auth-card-hearder-icon"
              onClick={() => history.push('/')}
            />
          </div>
          <p className="auth-card-header-multiline-text">
            Create password with minimum {MINIMUM_PASSWORD_LENGTH} characters
          </p>
        </div>
        <div className="auth-card-body">
          <form className="auth-form" id="changePasswordForm" onSubmit={handleSubmit}>
            <TextField
              className="auth-card-input"
              label="Password"
              variant="filled"
              type={showPassword ? 'text' : 'password'}
              required
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {!showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                inputProps: {
                  minLength: MINIMUM_PASSWORD_LENGTH,
                },
              }}
            />
            <TextField
              className="auth-card-input"
              style={{
                height:
                  confirmPassword !== '' && password !== '' && confirmPassword !== password
                    ? '91px'
                    : '64px',
              }}
              label="Confirm Password"
              variant="filled"
              type={showConfirmPassword ? 'text' : 'password'}
              required
              onChange={(e) => setConfirmPassword(e.target.value)}
              helperText={
                confirmPassword !== '' && password !== '' && confirmPassword !== password
                  ? 'Passwords do not match'
                  : ''
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                    >
                      {!showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
                inputProps: {
                  minLength: MINIMUM_PASSWORD_LENGTH,
                },
              }}
            />
          </form>
        </div>
        <div className="auth-card-footer">
          <button className="auth-card-button-dark" type="submit" form="changePasswordForm">
            Confirm New Password
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
