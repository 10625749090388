import React, { useState } from 'react';
import { TextField } from '@material-ui/core';

import { MAXIMUM_QUIZ_QUESTION_LENGTH } from '../../actions/constants';

import QuizAnswer from '../QuizAnswer/QuizAnswer';

import './QuizQuestion.scss';

const QuizQuestion = (props) => {
  const [questionInput, setQuestionInput] = useState(props.question.statement);
  const [correctAnswerPoints, setCorrectAnswerPoints] = useState(
    props.question.points_for_correct ? props.question.points_for_correct : 1,
  );
  const [incorrectAnswerPenalty, setIncorrectAnswerPenalty] = useState(
    props.question.penalty_for_wrong ? props.question.penalty_for_wrong : 0,
  );
  const [answers, setAnswers] = useState(props.question.answers);
  const [isEmptyQuestionError, setIsEmptyQuestionError] = useState(false);
  const [isEmptyAnswerError, setIsEmptyAnswerError] = useState(false);
  const [isCorrectCountError, setIsCorrectCountError] = useState(false);

  const handleSetPointsForCorrect = (e) => {
    setCorrectAnswerPoints(e.target.value);
  };

  const handleSetPenaltyForWrong = (e) => {
    setIncorrectAnswerPenalty(e.target.value);
  };

  const appendAnswer = () => {
    if (answers.length < 6) {
      setAnswers((prevState) => [...prevState, { statement: '', is_correct: false }]);
    }
  };

  const removeAnswer = (answerId) => {
    if (answers.length > 2) {
      if (props.edit) {
        const deletedAnswerUuid = answers.find(
          (answer, index) => index === answerId && answer.hasOwnProperty('uuid'),
        )?.uuid;
        if (deletedAnswerUuid) {
          props.setDeletedAnswers((prevState) => [...prevState, deletedAnswerUuid]);
        }
      }

      setAnswers((prevState) => prevState.filter((answer, index) => index !== answerId));
    }
  };

  const updateAnswer = (updatedAnswer, updatedAnswerIndex) => {
    const newAnswers = answers;
    newAnswers.splice(updatedAnswerIndex, 1, updatedAnswer);
    setAnswers(newAnswers);
  };

  const handleAddQuestionClick = () => {
    var isCorrectCount = 0;
    var isEmptyAns = false;

    if (!document.getElementById('questionStatement').validity.valid) {
      setIsEmptyQuestionError(true);
      setIsEmptyAnswerError(false);
      setIsCorrectCountError(false);
      return;
    } else {
      setIsEmptyQuestionError(false);
    }

    answers.forEach((answer) => {
      if (answer.statement === '') {
        setIsEmptyAnswerError(true);
        setIsEmptyQuestionError(false);
        setIsCorrectCountError(false);
        isEmptyAns = true;
      } else {
        setIsEmptyAnswerError(false);
        isEmptyAns = false;
      }
    });

    if (isEmptyAns) {
      return;
    }

    answers.forEach((answer) => {
      if (answer.is_correct) {
        isCorrectCount++;
      }
    });

    if (isCorrectCount > 0) {
      props.addQuestion({
        statement: questionInput,
        points_for_correct: correctAnswerPoints,
        penalty_for_wrong: incorrectAnswerPenalty,
        answers,
      });
      setQuestionInput('');
      setAnswers([
        { statement: '', is_correct: false },
        { statement: '', is_correct: false },
      ]);
      setIsEmptyQuestionError(false);
      setIsEmptyAnswerError(false);
      setIsCorrectCountError(false);
    } else {
      setIsCorrectCountError(true);
    }
  };

  const handleUpdateQuestionClick = () => {
    var isCorrectCount = 0;
    var isEmptyAns = false;

    if (!document.getElementById('questionStatement').validity.valid) {
      setIsEmptyQuestionError(true);
      setIsEmptyAnswerError(false);
      setIsCorrectCountError(false);
      return;
    } else {
      setIsEmptyQuestionError(false);
    }

    answers.forEach((answer) => {
      if (answer.statement === '') {
        setIsEmptyAnswerError(true);
        setIsEmptyQuestionError(false);
        setIsCorrectCountError(false);
        isEmptyAns = true;
      } else {
        setIsEmptyAnswerError(false);
        isEmptyAns = false;
      }
    });

    if (isEmptyAns) {
      return;
    }

    answers.forEach((answer) => {
      if (answer.is_correct) {
        isCorrectCount++;
      }
    });

    if (isCorrectCount > 0) {
      var updatedQuestion = '';
      if (props.question.hasOwnProperty('uuid')) {
        updatedQuestion = {
          uuid: props.question.uuid,
          statement: questionInput,
          points_for_correct: correctAnswerPoints,
          penalty_for_wrong: incorrectAnswerPenalty,
          answers,
        };
      } else {
        updatedQuestion = {
          statement: questionInput,
          points_for_correct: correctAnswerPoints,
          penalty_for_wrong: incorrectAnswerPenalty,
          answers,
        };
      }
      props.updateQuestion(updatedQuestion, props.questionId);
      props.setExpandQuestion(false);
      setIsEmptyQuestionError(false);
      setIsEmptyAnswerError(false);
      setIsCorrectCountError(false);
    } else {
      setIsCorrectCountError(true);
    }
  };

  return (
    <div className="quiz-question">
      <form id="quizQuestionForm" className="quiz-question-form">
        <TextField
          id="questionStatement"
          className="create-panel-quiz-input"
          label={`Question`}
          value={questionInput}
          onChange={(e) => setQuestionInput(e.target.value)}
          inputProps={{
            maxLength: MAXIMUM_QUIZ_QUESTION_LENGTH,
          }}
          multiline={true}
          required
        />
        {isEmptyQuestionError && (
          <p className="error-message" style={{ marginBottom: '0' }}>
            Question is required.
          </p>
        )}
        <TextField
          className="create-panel-quiz-input"
          label="Points for correct answer"
          value={correctAnswerPoints}
          type="number"
          onChange={handleSetPointsForCorrect}
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
        />
        <TextField
          className="create-panel-quiz-input"
          label="Penalty for wrong answer"
          value={incorrectAnswerPenalty}
          type="number"
          onChange={handleSetPenaltyForWrong}
          InputProps={{
            inputProps: {
              max: 0,
            },
          }}
        />
        <h3 className="quiz-answer-heading">Answers</h3>
        {answers.map((answer, index) => (
          <QuizAnswer
            key={index}
            answerId={index}
            answer={answer}
            removeAnswer={removeAnswer}
            updateAnswer={updateAnswer}
            answers={answers}
          />
        ))}
        {isEmptyAnswerError && <p className="error-message">Answers are required.</p>}
        {isCorrectCountError && (
          <p className="error-message">There must be at least 1 correct answer</p>
        )}
        <p className="add-answer" onClick={appendAnswer}>
          + Add Answer
        </p>
        {!props.expanded ? (
          <button
            className="add-question-button"
            type="button"
            form="quizQuestionForm"
            onClick={handleAddQuestionClick}
          >
            Add Question
          </button>
        ) : (
          <button
            className="update-question-button"
            type="button"
            form="quizQuestionForm"
            onClick={handleUpdateQuestionClick}
          >
            Update Question
          </button>
        )}
      </form>
    </div>
  );
};

export default QuizQuestion;
