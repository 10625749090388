import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';

import CreateFlowCard from '../../CreateFlowCard/CreateFlowCard';
import Loader from '../../Loader/Loader';

import './CreateFlow.scss';

const CreateFlow = () => {
  const flowState = useSelector((state) => state.flow);

  const history = useHistory();

  return flowState.flowLoading ? (
    <Loader />
  ) : flowState.flowCreated ? (
    <Redirect to={`/flow/${flowState.uuid}`} />
  ) : (
    <div className="my-container create-flow-page-container">
      <CreateFlowCard />
      <div className="create-flow-buttons-wrapper">
        <button className="create-flow-button" type="submit" form="createFlowForm">
          Create Flow
        </button>
        <button className="cancel-flow-button" onClick={() => history.go(-1)}>
          Cancel Flow
        </button>
      </div>
    </div>
  );
};

export default CreateFlow;
