import {
  ADD_COMMENT,
  ADD_COMMENT_ERROR,
  DELETE_COMMENT,
  DELETE_COMMENT_ERROR,
  ADD_REPLY,
  ADD_REPLY_ERROR,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  comments: [],
  next: '',
  commentLoading: false,
};

const videoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_COMMENT:
      return {
        ...state,
      };
    case ADD_COMMENT_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case DELETE_COMMENT:
      return {
        ...state,
      };
    case DELETE_COMMENT_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case ADD_REPLY:
      return {
        ...state,
      };
    case ADD_REPLY_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default videoReducer;
