import {
  CREATE_QUIZ,
  CREATE_QUIZ_ERROR,
  GET_MY_QUIZZES,
  GET_MY_QUIZZES_ERROR,
  ADD_QUIZ_TO_TOPIC,
  ADD_QUIZ_TO_TOPIC_ERROR,
  REMOVE_QUIZ_FROM_TOPIC,
  REMOVE_QUIZ_FROM_TOPIC_ERROR,
  SET_OFFICIAL_QUIZ,
  SET_OFFICIAL_QUIZ_ERROR,
  REMOVE_OFFICIAL_QUIZ,
  REMOVE_OFFICIAL_QUIZ_ERROR,
  INCREMENT_QUIZ_TRY,
  INCREMENT_QUIZ_TRY_ERROR,
  SUBMIT_QUIZ,
  SUBMIT_QUIZ_ERROR,
  SET_QUIZ_SUBMITTED,
  CLEAR_SUBMISSION_RESPONSE,
  CLEAR_QUIZ_STATE,
  QUIZ_LOADING,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  quiz: '',
  myQuizzes: [],
  quizSubmitted: false,
  submissionResponse: '',
  errorMessage: '',
  quizLoading: false,
};

const quizReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_QUIZ:
      return {
        ...state,
        quiz: action.payload,
        errorMessage: '',
        quizLoading: false,
      };
    case CREATE_QUIZ_ERROR:
      return {
        ...state,
        quiz: '',
        errorMessage: action.payload,
        quizLoading: false,
      };
    case GET_MY_QUIZZES:
      return {
        ...state,
        myQuizzes: action.payload,
        errorMessage: '',
        quizLoading: false,
      };
    case GET_MY_QUIZZES_ERROR:
      return {
        ...state,
        myQuizzes: [],
        errorMessage: action.payload,
        quizLoading: false,
      };
    case ADD_QUIZ_TO_TOPIC:
      return {
        ...state,
        myQuizzes: [],
        errorMessage: '',
        quizLoading: false,
      };
    case ADD_QUIZ_TO_TOPIC_ERROR:
      return {
        ...state,
        myQuizzes: [],
        errorMessage: action.payload,
        quizLoading: false,
      };
    case REMOVE_QUIZ_FROM_TOPIC:
      return {
        ...state,
        myQuizzes: [],
        errorMessage: '',
        quizLoading: false,
      };
    case REMOVE_QUIZ_FROM_TOPIC_ERROR:
      return {
        ...state,
        myQuizzes: [],
        errorMessage: action.payload,
        quizLoading: false,
      };
    case SET_OFFICIAL_QUIZ:
      return {
        ...state,
        errorMessage: '',
        quizLoading: false,
      };
    case SET_OFFICIAL_QUIZ_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        quizLoading: false,
      };
    case REMOVE_OFFICIAL_QUIZ:
      return {
        ...state,
        myQuizzes: [],
        errorMessage: '',
        quizLoading: false,
      };
    case REMOVE_OFFICIAL_QUIZ_ERROR:
      return {
        ...state,
        myQuizzes: [],
        errorMessage: action.payload,
        quizLoading: false,
      };
    case INCREMENT_QUIZ_TRY:
      return {
        ...state,
        errorMessage: '',
        quizLoading: false,
      };
    case INCREMENT_QUIZ_TRY_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        quizLoading: false,
      };
    case SUBMIT_QUIZ:
      return {
        ...state,
        quizSubmitted: true,
        submissionResponse: action.payload,
        errorMessage: '',
        quizLoading: false,
      };
    case SUBMIT_QUIZ_ERROR:
      return {
        ...state,
        quizSubmitted: false,
        submissionResponse: '',
        errorMessage: action.payload,
        quizLoading: false,
      };
    case SET_QUIZ_SUBMITTED:
      return {
        ...state,
        quizSubmitted: action.payload,
      };
    case CLEAR_SUBMISSION_RESPONSE:
      return {
        ...state,
        submissionResponse: '',
      };
    case CLEAR_QUIZ_STATE:
      return {
        ...state,
        quiz: '',
        myQuizzes: [],
        quizSubmitted: false,
        submissionResponse: '',
        errorMessage: '',
        quizLoading: false,
      };
    case QUIZ_LOADING:
      return {
        ...state,
        quizLoading: true,
      };
    default:
      return state;
  }
};

export default quizReducer;
