import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router-dom';
import { getProfile } from './actions/profileAction';

import Navbar from './components/Navbar/Navbar';
import Home from './components/pages/Home/Home';
import VerifyEmail from './components/pages/VerifyEmail/VerifyEmail';
import Register from './components/Register/Register';
import Login from './components/Login/Login';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ChangePassword from './components/ChangePassword/ChangePassword';
import Flow from './components/pages/Flow/Flow';
import CreateFlow from './components/pages/CreateFlow/CreateFlow';
import MyProfile from './components/pages/MyProfile/MyProfile';
import SearchFlows from './components/pages/SearchFlows/SearchFlows';
import VerifyCertificate from './components/pages/VerifyCertificate/VerifyCertificate';
import Footer from './components/Footer/Footer';

import './App.css';

function App() {
  const dispatch = useDispatch();

  const history = useHistory();

  history.listen((location, action) => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      dispatch(getProfile());
    }
  }, []);

  return (
    <div className="App">
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/verify-email/:uidb64/:token" component={VerifyEmail} />
        <Route path="/register" component={Register} />
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/change-password/:uidb64/:token" component={ChangePassword} />
        <Route exact path="/flow/:uuid" component={Flow} />
        <Route exact path="/flow/:uuid/:topicUuid" component={Flow} />
        <Route path="/flow/:uuid/:topicUuid/:videoUuid" component={Flow} />
        <Route path="/create-flow" component={CreateFlow} />
        <Route exact path="/my-profile" component={MyProfile} />
        <Route path="/my-profile/account-settings" component={MyProfile} />
        <Route path="/search-flows" component={SearchFlows} />
        <Route path="/certificate/:type/:uuid" component={VerifyCertificate} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
