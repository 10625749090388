import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { TextField, RadioGroup, FormControlLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import blankThumbnail from '../../images/blank-thumbnail.png';

import {
  createQuiz,
  getMyQuizzes,
  addQuizToTopic,
  clearQuizState,
  setQuizLoading,
} from '../../actions/quizAction';

import {
  editQuiz,
  getMyProfileQuizzes,
  setProfileDashboardLoading,
} from '../../actions/profileAction';

import {
  MAXIMUM_TAGS,
  MAXIMUM_TITLE_LENGTH,
  MAXIMUM_DESCRIPTION_LENGTH,
  MAXIMUM_TAG_LENGTH,
  MINUTES,
  SECONDS,
  PERCENTAGES,
} from '../../actions/constants';

import Tag from '../Tag/Tag';
import StyledRadio from '../StyledRadio/StyledRadio';
import Loader from '../Loader/Loader';
import QuizQuestion from '../QuizQuestion/QuizQuestion';
import QuizQuestionCollapsed from '../QuizQuestionCollapsed/QuizQuestionCollapsed';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import MyQuizCard from '../MyQuizCard/MyQuizCard';

import './AddTopicQuizModal.scss';

const AddTopicQuizModal = (props) => {
  const [thumbnail, setThumbnail] = useState(props.edit ? props.myQuiz.thumbnail : '');
  const [thumbnailImage, setThumbnailImage] = useState(
    props.edit
      ? props.myQuiz.thumbnail
        ? props.myQuiz.thumbnail
        : blankThumbnail
      : blankThumbnail,
  );
  const [title, setTitle] = useState(props.edit ? props.myQuiz.title : '');
  const [description, setDescription] = useState(props.edit ? props.myQuiz.description : '');
  const [tags, setTags] = useState(props.edit ? props.myQuiz.tags : []);
  const [passPercentage, setPassPercentage] = useState(
    props.edit ? props.myQuiz.pass_percentage : 50,
  );
  const [visibility, setVisibility] = useState(
    props.edit ? props.myQuiz.visibility : 'registered_users',
  );
  const [allowPreviousOption, setAllowPreviousOption] = useState(
    props.edit ? props.myQuiz.allow_previous_question : true,
  );
  const [isTimed, setIsTimed] = useState(props.edit ? props.myQuiz.is_timed : false);
  const [isEstimatedTimeError, setIsEstimatedTimeError] = useState(false);
  const [minutes, setMinutes] = useState(
    props.edit ? (props.myQuiz.estimated_time ? ~~(props.myQuiz.estimated_time / 60) : 0) : 0,
  );
  const [seconds, setSeconds] = useState(
    props.edit
      ? props.myQuiz.estimated_time
        ? (props.myQuiz.estimated_time / 60 - ~~(props.myQuiz.estimated_time / 60)) * 60
        : 0
      : 0,
  );
  const [estimatedTime, setEstimatedTime] = useState(0);
  const [inputTag, setInputTag] = useState('');
  const [tagLimit, setTagLimit] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [questions, setQuestions] = useState(props.edit ? props.myQuiz.questions : []);
  const [deletedQuestions, setDeletedQuestions] = useState([]);
  const [deletedAnswers, setDeletedAnswers] = useState([]);

  const [selectedQuizzes, setSelectedQuizzes] = useState([]);

  const [selectedTab, setSelectedTab] = useState(props.edit ? 'create-quiz' : 'my-quizzes');
  const [previousSelectedTab, setPreviousSelectedTab] = useState(
    props.edit ? 'create-quiz' : 'my-quizzes',
  );

  const quizState = useSelector((state) => state.quiz);

  const THUMBNAIL_INPUT = document.querySelector('.create-panel-thumbnail-input');

  const dispatch = useDispatch();

  const match = useRouteMatch();

  useEffect(() => {
    return () => {
      setThumbnail('');
      setThumbnailImage(blankThumbnail);
      setTitle('');
      setDescription('');
      setTags([]);
      setInputTag('');
      setTagLimit(false);
      setVisibility('registered_users');
      setAllowPreviousOption(true);
      setIsTimed(false);
      setIsEstimatedTimeError(false);
      setMinutes(0);
      setSeconds(0);
      setEstimatedTime(0);
      setPassPercentage(50);
      setQuestions([]);
      setSelectedTab(props.edit ? 'create-quiz' : 'my-quizzes');
      setPreviousSelectedTab(props.edit ? 'create-quiz' : 'my-quizzes');

      if (THUMBNAIL_INPUT) {
        THUMBNAIL_INPUT.value = '';
      }
    };
  }, []);

  useEffect(() => {
    setThumbnail(props.edit ? props.myQuiz.thumbnail : '');
    setThumbnailImage(
      props.edit
        ? props.myQuiz.thumbnail
          ? props.myQuiz.thumbnail
          : blankThumbnail
        : blankThumbnail,
    );
    setTitle(props.edit ? props.myQuiz.title : '');
    setDescription(props.edit ? props.myQuiz.description : '');
    setTags(props.edit ? props.myQuiz.tags : []);
    setInputTag('');
    setTagLimit(false);
    setVisibility(props.edit ? props.myQuiz.visibility : 'registered_users');
    setAllowPreviousOption(props.edit ? props.myQuiz.allow_previous_question : true);
    setIsTimed(props.edit ? props.myQuiz.is_timed : false);
    setIsEstimatedTimeError(false);
    setMinutes(
      props.edit ? (props.myQuiz.estimated_time ? ~~(props.myQuiz.estimated_time / 60) : 0) : 0,
    );
    setSeconds(
      props.edit
        ? props.myQuiz.estimated_time
          ? (props.myQuiz.estimated_time / 60 - ~~(props.myQuiz.estimated_time / 60)) * 60
          : 0
        : 0,
    );
    setEstimatedTime(0);
    setPassPercentage(props.edit ? props.myQuiz.pass_percentage : 50);
    setQuestions(props.edit ? props.myQuiz.questions : []);
    setSelectedTab(props.edit || props.newQuiz ? 'create-quiz' : 'my-quizzes');
    setPreviousSelectedTab(props.edit || props.newQuiz ? 'create-quiz' : 'my-quizzes');

    if (THUMBNAIL_INPUT) {
      THUMBNAIL_INPUT.value = '';
    }
  }, [quizState.quiz]);

  useEffect(() => {
    if (tags.length < MAXIMUM_TAGS) {
      setTagLimit(false);
    }

    if (tags.length === 0) {
      setShowTags(false);
    } else {
      setShowTags(true);
    }
  }, [tags]);

  useEffect(() => {
    setEstimatedTime(minutes * 60 + seconds);
    setIsEstimatedTimeError(false);
  }, [minutes, seconds]);

  const handleTabSelect = (e) => {
    document
      .getElementById(previousSelectedTab)
      .classList.remove('add-topic-quiz-modal-tab-selected');
    document.getElementById(e.target.id).classList.add('add-topic-quiz-modal-tab-selected');
    setPreviousSelectedTab(e.target.id);
    setSelectedTab(e.target.id);
  };

  const handleModalClose = () => {
    setThumbnail(props.edit ? props.myQuiz.thumbnail : '');
    setThumbnailImage(
      props.edit
        ? props.myQuiz.thumbnail
          ? props.myQuiz.thumbnail
          : blankThumbnail
        : blankThumbnail,
    );
    setTitle(props.edit ? props.myQuiz.title : '');
    setDescription(props.edit ? props.myQuiz.description : '');
    setTags(props.edit ? props.myQuiz.tags : []);
    setInputTag('');
    setTagLimit(false);
    setVisibility(props.edit ? props.myQuiz.visibility : 'registered_users');
    setAllowPreviousOption(props.edit ? props.myQuiz.allow_previous_question : true);
    setIsTimed(props.edit ? props.myQuiz.is_timed : false);
    setIsEstimatedTimeError(false);
    setMinutes(
      props.edit ? (props.myQuiz.estimated_time ? ~~(props.myQuiz.estimated_time / 60) : 0) : 0,
    );
    setSeconds(
      props.edit
        ? props.myQuiz.estimated_time
          ? (props.myQuiz.estimated_time / 60 - ~~(props.myQuiz.estimated_time / 60)) * 60
          : 0
        : 0,
    );
    setEstimatedTime(0);
    setPassPercentage(props.edit ? props.myQuiz.pass_percentage : 50);
    setQuestions(props.edit ? props.myQuiz.questions : []);
    setSelectedTab(props.edit || props.newQuiz ? 'create-quiz' : 'my-quizzes');
    setPreviousSelectedTab(props.edit || props.newQuiz ? 'create-quiz' : 'my-quizzes');

    if (THUMBNAIL_INPUT) {
      THUMBNAIL_INPUT.value = '';
    }

    dispatch(clearQuizState());

    props.onHide();
  };

  const triggerQuizThumbnailInput = () => {
    document.querySelector('.create-panel-thumbnail-input').click();
  };

  const handleQuizThumbnailUpload = (e) => {
    if (e.target.files[0]) {
      setThumbnail(e.target.files[0]);
      setThumbnailImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleRemoveQuizThumbnail = () => {
    setThumbnail('');
    setThumbnailImage(blankThumbnail);
    document.querySelector('.create-panel-thumbnail-input').value = '';
  };

  const addTag = (e) => {
    if (e.key === 'Enter') {
      if (tags.length < MAXIMUM_TAGS) {
        if (inputTag !== '' && !tags.includes(inputTag)) {
          setTags((prevState) => [...prevState, inputTag]);
        }
      } else {
        setTagLimit(true);
      }

      setInputTag('');
    }
  };

  const addTagButtonClick = () => {
    if (tags.length < MAXIMUM_TAGS) {
      if (inputTag !== '' && !tags.includes(inputTag)) {
        setTags((prevState) => [...prevState, inputTag]);
      }
    } else {
      setTagLimit(true);
    }

    setInputTag('');
  };

  const removeTag = (tagId) => {
    setTags((prevState) => prevState.filter((tag, index) => index !== tagId));
  };

  const addQuizToSelectedQuizzes = (quizUuid) => {
    setSelectedQuizzes((prevState) => [...prevState, quizUuid]);
  };

  const removeQuizFromSelectedQuizzes = (quizUuid) => {
    setSelectedQuizzes((prevState) => prevState.filter((quiz) => quiz !== quizUuid));
  };

  const handleAddSelectedQuiz = async (e) => {
    e.preventDefault();

    dispatch(addQuizToTopic({ quizzes_list: selectedQuizzes }, props.topicUuid, match.params.uuid));

    handleModalClose();
  };

  const handleAllowPreviousOptionChange = (e) => {
    if (e.target.value === 'yes') {
      setAllowPreviousOption(true);
    } else {
      setAllowPreviousOption(false);
    }
  };

  const handleQuizTimeChange = (e) => {
    if (e.target.value === 'timed') {
      setIsTimed(true);
    } else {
      setIsTimed(false);
    }
  };

  const handleMinutesDropdownChange = (filter) => {
    setMinutes(filter);
  };

  const handleSecondsDropdownChange = (filter) => {
    setSeconds(filter);
  };

  const handlePassPercentageDropdownChange = (filter) => {
    setPassPercentage(filter);
  };

  const addQuestion = (question) => {
    setQuestions((prevState) => [...prevState, question]);
  };

  const deleteQuestion = (questionId) => {
    if (props.edit) {
      const deletedQuestionUuid = questions.find(
        (question, index) => index === questionId && question.hasOwnProperty('uuid'),
      )?.uuid;
      if (deletedQuestionUuid) {
        setDeletedQuestions((prevState) => [...prevState, deletedQuestionUuid]);
      }
    }

    setQuestions((prevState) => prevState.filter((question, index) => index !== questionId));
  };

  const updateQuestion = (updatedQuestion, updatedQuetionIndex) => {
    const newQuestions = questions;
    newQuestions.splice(updatedQuetionIndex, 1, updatedQuestion);
    setQuestions([...newQuestions]);
  };

  const handleCreateSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (
      thumbnail !== null &&
      typeof thumbnail !== 'undefined' &&
      (typeof thumbnail === 'object' || thumbnail === '')
    ) {
      formData.append('thumbnail', thumbnail);
    }
    formData.append('title', title);
    formData.append('description', description);
    formData.append('tags', JSON.stringify(tags));
    formData.append('visibility', visibility);
    formData.append('allow_previous_question', allowPreviousOption);
    formData.append('is_timed', isTimed);
    formData.append('pass_percentage', passPercentage);
    formData.append('questions', JSON.stringify(questions));

    if (isTimed) {
      if (minutes > 0 || seconds > 0) {
        formData.append('estimated_time', estimatedTime);

        if (props.edit) {
          formData.append('deleted_questions', JSON.stringify(deletedQuestions));
          formData.append('deleted_answers', JSON.stringify(deletedAnswers));

          dispatch(setProfileDashboardLoading());
          dispatch(editQuiz(props.myQuiz.uuid, formData));
        } else if (props.newQuiz) {
          dispatch(setQuizLoading());
          await dispatch(createQuiz(formData));
          dispatch(setProfileDashboardLoading());
          dispatch(getMyProfileQuizzes());
          handleModalClose();
        } else {
          dispatch(setQuizLoading());
          await dispatch(createQuiz(formData));
          dispatch(getMyQuizzes());

          document.getElementById('my-quizzes').classList.add('add-topic-quiz-modal-tab-selected');
          document
            .getElementById('create-quiz')
            .classList.remove('add-topic-quiz-modal-tab-selected');
          setSelectedTab('my-quizzes');
          setPreviousSelectedTab('my-quizzes');
        }
      } else {
        setIsEstimatedTimeError(true);
      }
    } else {
      if (props.edit) {
        formData.append('deleted_questions', JSON.stringify(deletedQuestions));
        formData.append('deleted_answers', JSON.stringify(deletedAnswers));

        dispatch(setProfileDashboardLoading());
        dispatch(editQuiz(props.myQuiz.uuid, formData));
      } else if (props.newQuiz) {
        dispatch(setQuizLoading());
        await dispatch(createQuiz(formData));
        dispatch(setProfileDashboardLoading());
        dispatch(getMyProfileQuizzes());
        handleModalClose();
      } else {
        dispatch(setQuizLoading());
        await dispatch(createQuiz(formData));
        dispatch(getMyQuizzes());

        document.getElementById('my-quizzes').classList.add('add-topic-quiz-modal-tab-selected');
        document
          .getElementById('create-quiz')
          .classList.remove('add-topic-quiz-modal-tab-selected');
        setSelectedTab('my-quizzes');
        setPreviousSelectedTab('my-quizzes');
      }
    }
  };

  return (
    <Modal {...props} centered scrollable backdrop="static" className="add-quiz-modal">
      <div className="add-topic-quiz-modal">
        <div
          className="add-topic-quiz-modal-header"
          style={{ marginBottom: props.edit ? '0' : '22px' }}
        >
          <h3
            className="add-topic-quiz-modal-heading"
            style={{ marginBottom: props.edit ? '50px' : '32px' }}
          >
            {props.edit ? 'Edit' : 'Add'} Quiz
          </h3>
          {!props.edit && !props.newQuiz && (
            <div className="add-topic-quiz-modal-tab-labels-wrapper">
              <div
                id="my-quizzes"
                className="add-topic-quiz-modal-tab-label add-topic-quiz-modal-tab-selected"
                onClick={handleTabSelect}
              >
                My Quizzes
              </div>
              <div
                id="create-quiz"
                className="add-topic-quiz-modal-tab-label"
                onClick={handleTabSelect}
              >
                Create Quiz
              </div>
            </div>
          )}
        </div>
        <Modal.Body
          style={{
            maxHeight: props.edit || props.newQuiz ? 'calc(100vh - 289px)' : 'calc(100vh - 321px)',
            overflowY: 'auto',
          }}
        >
          {selectedTab === 'my-quizzes' && (
            <div className="add-topic-quiz-modal-my-quizzes-panel">
              {quizState.quizLoading ? (
                <Loader height="65vh" margin="0" />
              ) : quizState.myQuizzes.length ? (
                <div className="my-quizzes-panel-grid">
                  {quizState.myQuizzes.map((quiz) => (
                    <MyQuizCard
                      key={quiz.uuid}
                      quiz={quiz}
                      showSelect={true}
                      searchCard={false}
                      topicQuizzes={props.topicQuizzes}
                      selectedQuizzes={selectedQuizzes}
                      addQuizToSelectedQuizzes={addQuizToSelectedQuizzes}
                      removeQuizFromSelectedQuizzes={removeQuizFromSelectedQuizzes}
                    />
                  ))}
                </div>
              ) : (
                <p className="no-quizzes">No Quizzes</p>
              )}
            </div>
          )}
          {selectedTab === 'create-quiz' && (
            <div className="add-topic-quiz-modal-create-panel">
              {quizState.quizLoading ? (
                <Loader height="65vh" margin="0" />
              ) : (
                <form
                  className="create-panel-form"
                  id="createTopicQuizForm"
                  onSubmit={handleCreateSubmit}
                  onKeyPress={(event) => {
                    if (event.which === 13 /* Enter */) {
                      event.preventDefault();
                    }
                  }}
                  encType="multipart/form-data"
                >
                  <div className="create-panel-thumbnail-input-wrapper">
                    <div
                      className="create-panel-thumbnail"
                      style={{
                        backgroundImage: `url(${thumbnailImage})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                    >
                      <input
                        className="create-panel-thumbnail-input"
                        name="create-panel-thumbnail"
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        style={{ display: 'none' }}
                        onChange={handleQuizThumbnailUpload}
                      />
                      {thumbnail && (
                        <div
                          className="create-panel-thumbnail-overlay"
                          onClick={handleRemoveQuizThumbnail}
                        >
                          <FontAwesomeIcon icon={faTimes} className="cross-icon" />
                        </div>
                      )}
                    </div>
                    <div
                      className="create-panel-thumbnail-text"
                      onClick={triggerQuizThumbnailInput}
                    >
                      Edit Thumbnail
                    </div>
                  </div>
                  <div className="create-panel-quiz-details-wrapper">
                    <TextField
                      className="create-panel-quiz-input"
                      label="Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      inputProps={{
                        maxLength: MAXIMUM_TITLE_LENGTH,
                      }}
                      required
                    />
                    <div className="create-panel-tags-input-wrapper">
                      <TextField
                        className="create-panel-quiz-input"
                        label="Tags"
                        value={inputTag}
                        onChange={(e) => setInputTag(e.target.value)}
                        onKeyDown={addTag}
                        inputProps={{
                          maxLength: MAXIMUM_TAG_LENGTH,
                        }}
                      />
                      <div className="create-panel-add-tag-wrapper">
                        <button
                          className="create-panel-add-tag-button"
                          type="button"
                          onClick={addTagButtonClick}
                        >
                          Add Tag
                        </button>
                      </div>
                    </div>
                    {showTags && (
                      <div className="create-panel-tags-row">
                        {tags.length
                          ? tags.map((tag, index) => (
                              <Tag
                                key={index}
                                tagId={index}
                                tagText={tag}
                                tagType="flow"
                                editMode={true}
                                removeTag={removeTag}
                              />
                            ))
                          : null}
                      </div>
                    )}

                    {tagLimit ? (
                      <span className="tag-limit-warning">
                        {`Maximum ${MAXIMUM_TAGS} tags can be added`}{' '}
                        <FontAwesomeIcon icon={faExclamationCircle} />
                      </span>
                    ) : null}
                    <TextField
                      className="create-panel-quiz-input"
                      label="Description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      multiline={true}
                      inputProps={{
                        maxLength: MAXIMUM_DESCRIPTION_LENGTH,
                      }}
                    />
                  </div>
                  <div className="create-panel-quiz-radio-wrapper">
                    <h3 className="quiz-radio-heading">Visibility</h3>
                    <RadioGroup
                      defaultValue={visibility}
                      aria-label="visibility"
                      name="styled-visibility-radios"
                      onChange={(e) => setVisibility(e.target.value)}
                    >
                      <FormControlLabel value="just_me" control={<StyledRadio />} label="Just Me" />
                      <FormControlLabel
                        value="registered_users"
                        control={<StyledRadio />}
                        label="Registered Users"
                      />
                      <FormControlLabel
                        value="everyone"
                        control={<StyledRadio />}
                        label="Everyone"
                      />
                    </RadioGroup>
                  </div>
                  <div className="create-panel-quiz-radio-wrapper">
                    <h3 className="quiz-radio-heading">Previous Option</h3>
                    <RadioGroup
                      defaultValue={allowPreviousOption ? 'yes' : 'no'}
                      aria-label="previous-option"
                      name="styled-previous-option-radios"
                      onChange={handleAllowPreviousOptionChange}
                    >
                      <FormControlLabel value="yes" control={<StyledRadio />} label="Yes" />
                      <FormControlLabel value="no" control={<StyledRadio />} label="No" />
                    </RadioGroup>
                    <p className="quiz-time-text">
                      {allowPreviousOption
                        ? 'Users will be allowed to go back to previous questions.'
                        : 'Users will not be allowed to go back to previous questions.'}
                    </p>
                  </div>
                  <div className="create-panel-quiz-radio-wrapper">
                    <h3 className="quiz-radio-heading">Quiz Time</h3>
                    <RadioGroup
                      defaultValue={isTimed ? 'timed' : 'untimed'}
                      aria-label="time"
                      name="styled-time-radios"
                      onChange={handleQuizTimeChange}
                    >
                      <FormControlLabel value="timed" control={<StyledRadio />} label="Timed" />
                      <FormControlLabel value="untimed" control={<StyledRadio />} label="Untimed" />
                    </RadioGroup>
                    <p className="quiz-time-text">
                      {isTimed ? 'Maximum time allowed.' : 'This will be an untimed quiz.'}
                    </p>
                    {isTimed && (
                      <div className="quiz-time-wrapper">
                        <div className="quiz-time-dropdown">
                          <CustomDropdown
                            handleDropdownChange={handleMinutesDropdownChange}
                            type="underlined"
                            items={MINUTES}
                            value={minutes}
                          />
                          <p className="quiz-time-dropdown-text">Minutes</p>
                        </div>
                        <div className="quiz-time-dropdown">
                          <CustomDropdown
                            handleDropdownChange={handleSecondsDropdownChange}
                            type="underlined"
                            items={SECONDS}
                            value={seconds}
                          />
                          <p className="quiz-time-dropdown-text">Seconds</p>
                        </div>
                      </div>
                    )}
                    {isEstimatedTimeError && (
                      <p className="error-message">Maximum time is required</p>
                    )}
                  </div>
                  <div className="create-panel-quiz-radio-wrapper">
                    <h3 className="quiz-radio-heading">Pass Percentage</h3>
                    <CustomDropdown
                      handleDropdownChange={handlePassPercentageDropdownChange}
                      type="underlined"
                      items={PERCENTAGES}
                      value={passPercentage}
                    />
                  </div>
                  <div className="create-panel-quiz-questions-wrapper">
                    <h3 className="questions-heading">Questions</h3>
                    {questions.map((question, index) => (
                      <QuizQuestionCollapsed
                        key={index}
                        questionId={index}
                        question={question}
                        updateQuestion={updateQuestion}
                        deleteQuestion={deleteQuestion}
                        setDeletedAnswers={setDeletedAnswers}
                        edit={props.edit}
                      />
                    ))}
                    <QuizQuestion
                      question={{
                        statement: '',
                        points_for_correct: 1,
                        penalty_for_wrong: 0,
                        answers: [
                          { statement: '', is_correct: false },
                          { statement: '', is_correct: false },
                        ],
                      }}
                      addQuestion={addQuestion}
                    />
                  </div>
                </form>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {selectedTab === 'my-quizzes' && !props.edit && (
            <div className="quiz-modal-footer-buttons-wrapper">
              <button
                className="my-quizzes-add-button"
                type="button"
                onClick={selectedQuizzes.length ? handleAddSelectedQuiz : (e) => e.preventDefault()}
              >
                Add Selected Quiz
              </button>
              <button className="cancel-button" type="button" onClick={handleModalClose}>
                Cancel
              </button>
            </div>
          )}
          {selectedTab === 'create-quiz' && !props.edit && (
            <div className="quiz-modal-footer-buttons-wrapper">
              <button className="create-quiz-button" type="submit" form="createTopicQuizForm">
                Create
              </button>
              <button className="cancel-button" type="button" onClick={handleModalClose}>
                Cancel
              </button>
            </div>
          )}
          {props.edit && (
            <div className="quiz-modal-footer-buttons-wrapper">
              <button className="create-quiz-button" type="submit" form="createTopicQuizForm">
                Save
              </button>
              <button className="cancel-button" type="button" onClick={handleModalClose}>
                Cancel
              </button>
            </div>
          )}
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default AddTopicQuizModal;
