import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import classNames from 'classnames';
import blankThumbnail from '../../images/blank-thumbnail.png';

import { deleteQuiz, setProfileDashboardLoading } from '../../actions/profileAction';
import { MAXIMUM_SELECTED_CONTENT } from '../../actions/constants';

import CardStats from '../CardStats/CardStats';
import CustomModal from '../CustomModal/CustomModal';
import AddTopicQuizModal from '../AddTopicQuizModal/AddTopicQuizModal';

import './MyQuizCard.scss';

const MyQuizCard = (props) => {
  const [selected, setSelected] = useState(false);
  const [customModalShow, setCustomModalShow] = useState(false);
  const [addTopicQuizModalShow, setAddTopicQuizModalShow] = useState(false);
  const [isExists, setIsExists] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.showSelect) {
      setIsExists(props.topicQuizzes.find((topicQuiz) => topicQuiz.uuid === props.quiz.uuid));
    }
  }, []);

  const selectQuiz = () => {
    if (!props.edit && props.selectedQuizzes.length < MAXIMUM_SELECTED_CONTENT) {
      setSelected(!selected);
      !selected
        ? props.addQuizToSelectedQuizzes(props.quiz.uuid)
        : props.removeQuizFromSelectedQuizzes(props.quiz.uuid);
    } else {
      if (selected) {
        setSelected(!selected);
        !selected
          ? props.addQuizToSelectedQuizzes(props.quiz.uuid)
          : props.removeQuizFromSelectedQuizzes(props.quiz.uuid);
      }
    }
  };

  const handleMyQuizCardClick = () => {
    if (isExists) {
      setOpenSnackbar(true);
    }
  };

  const handleDeleteQuiz = () => {
    dispatch(setProfileDashboardLoading());
    dispatch(deleteQuiz(props.quiz.uuid));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const checkboxDivClass = classNames(
    { 'my-quiz-checkbox': !props.searchCard },
    { 'search-checkbox': props.searchCard },
  );

  const checkboxClass = classNames(
    { 'my-quiz-check-icon': !props.searchCard },
    { 'search-check-icon': props.searchCard },
  );

  const quizCardThumbnailClass = classNames(
    { 'my-quiz-card-thumbnail': !props.searchCard },
    { 'search-card-thumbnail': props.searchCard },
  );

  return (
    <>
      <div
        className="my-quiz-card"
        style={{ opacity: isExists ? '0.3' : '1' }}
        onClick={handleMyQuizCardClick}
      >
        {props.showSelect && (
          <div className={checkboxDivClass}>
            <Checkbox
              checked={selected}
              onChange={selectQuiz}
              value="suggest-quiz"
              color="primary"
              icon={<FontAwesomeIcon icon={faCircle} className={checkboxClass} />}
              checkedIcon={<FontAwesomeIcon icon={faCheckCircle} className={checkboxClass} />}
              disabled={isExists}
            />
          </div>
        )}
        <div
          className={quizCardThumbnailClass}
          style={{
            backgroundImage: `url(${props.quiz.thumbnail ? props.quiz.thumbnail : blankThumbnail})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            pointerEvents: isExists ? 'none' : 'auto',
          }}
          onClick={selectQuiz}
        >
          {props.edit && (
            <div className="edit-content-overlay">
              <FontAwesomeIcon
                icon={faTrashAlt}
                className="trash-icon"
                onClick={() => setCustomModalShow(true)}
              />
              <div className="edit-button-wrapper" onClick={() => setAddTopicQuizModalShow(true)}>
                <FontAwesomeIcon icon={faEdit} className="edit-icon" />
                <p className="edit-content-text">Edit</p>
              </div>
            </div>
          )}
        </div>
        <div className="my-quiz-card-details">
          <div className="my-quiz-card-title-row">
            <h3 className="my-quiz-card-title">{props.quiz.title}</h3>
          </div>
          <CardStats
            tries={props.quiz.try_count}
            percentageOfTriesToPass={props.quiz.average_try_count}
            questions={props.quiz.questions.length}
            width="100%"
            margin="15px 0 0"
          />
          {props.quiz.average_time > 0 && (
            <CardStats averageTime={props.quiz.average_time} width="auto" margin="10px 0 0" />
          )}
          {props.quiz.estimated_time > 0 && (
            <CardStats estimatedTime={props.quiz.estimated_time} width="auto" margin="10px 0 0" />
          )}
        </div>
      </div>
      <CustomModal
        show={customModalShow}
        onHide={() => setCustomModalShow(false)}
        modalTitle="Delete Quiz"
        modalBody="Are you sure you want to delete this quiz permanently?"
        submitButtonText="Delete"
        handleSubmit={handleDeleteQuiz}
      />
      <AddTopicQuizModal
        show={addTopicQuizModalShow}
        onHide={() => setAddTopicQuizModalShow(false)}
        topicUuid={props.topicUuid}
        edit={props.edit}
        myQuiz={props.quiz}
      />
      <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
        <MuiAlert elevation={6} variant="filled" severity="error" {...props}>
          Quiz already exists in topic.
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default MyQuizCard;
