import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimes, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import classNames from 'classnames';

import { incrementQuizTry, clearSubmissionResponse } from '../../actions/quizAction';
import { createTopicCertificate, clearCertificateState } from '../../actions/certificateAction';

import MyPDFDocument from '../MyPDFDocument/MyPDFDocument';

import './QuizResults.scss';

const QuizResults = (props) => {
  const [isReplacementOption, setIsReplacementOption] = useState(false);

  const quizState = useSelector((state) => state.quiz);
  const certificateState = useSelector((state) => state.certificate);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      quizState.submissionResponse.previous_percentage &&
      quizState.submissionResponse.has_passed
    ) {
      setIsReplacementOption(true);
    } else {
      setIsReplacementOption(false);
    }
  }, [quizState.submissionResponse.previous_percentage]);

  const handleCreateTopicCertificate = async () => {
    const formData = new FormData();

    formData.append('quiz_result_uuid', quizState.submissionResponse.uuid);
    formData.append('date_obtained', new Date().getTime());
    formData.append('is_active', true);

    await dispatch(createTopicCertificate(formData));
    setIsReplacementOption(false);
  };

  const handleRetryQuiz = () => {
    props.setIsRetry(true);
    dispatch(incrementQuizTry(props.quizUuid));
    dispatch(clearSubmissionResponse());
    dispatch(clearCertificateState());
  };

  const renderQuizResult = () => {
    if (quizState.submissionResponse.has_passed) {
      if (!props.isTimeUp) {
        if (quizState.submissionResponse.topic_certificate_issued) {
          if (quizState.submissionResponse.flow_certificate_issued) {
            return `Congratulations! You have successfully passed this quiz and earned a certificate for this topic and the complete flow. You were able to answer ${quizState.submissionResponse.correct_questions} out of ${props.questionsList.length} questions correctly. Your obtained score is ${quizState.submissionResponse.current_percentage}%. You can download the topic certificate by clicking the button below or view all certificates later under the My Certifications section on My Profile page.`;
          } else {
            return `Congratulations! You have successfully passed this quiz and earned a certificate for this topic. You were able to answer ${quizState.submissionResponse.correct_questions} out of ${props.questionsList.length} questions correctly. Your obtained score is ${quizState.submissionResponse.current_percentage}%. You can download the certificate by clicking the button below or view all certificates later under the My Certifications section on My Profile page.`;
          }
        } else {
          return `Congratulations! You have successfully passed this quiz. You were able to answer ${quizState.submissionResponse.correct_questions} out of ${props.questionsList.length} questions correctly. Your obtained score is ${quizState.submissionResponse.current_percentage}%.`;
        }
      } else {
        return `Your time is up! You have successfully passed this quiz. You were able to answer ${quizState.submissionResponse.correct_questions} out of ${props.questionsList.length} questions correctly. Your obtained score is ${quizState.submissionResponse.current_percentage}%.`;
      }
    } else {
      if (props.isTimeUp) {
        return `Your time is up! You failed to pass this quiz. You were able to answer ${quizState.submissionResponse.correct_questions} out of ${props.questionsList.length} questions correctly. Your obtained score is ${quizState.submissionResponse.current_percentage}%.`;
      } else {
        return `Uh-oh! You failed to pass this quiz. You were able to answer ${quizState.submissionResponse.correct_questions} out of ${props.questionsList.length} questions correctly. Your obtained score is ${quizState.submissionResponse.current_percentage}%.`;
      }
    }
  };

  const resultStatusClass = classNames(
    'result-status',
    { pass: quizState.submissionResponse.has_passed },
    { fail: !quizState.submissionResponse.has_passed },
  );

  const resultIconClass = classNames(
    'result-icon',
    { pass: quizState.submissionResponse.has_passed },
    { fail: !quizState.submissionResponse.has_passed || props.isTimeUp },
  );

  return (
    <div className="quiz-result-window">
      <div className="quiz-result-header">
        <div className={resultStatusClass}>
          <div className="status-dot"></div>
          <p className="status-text">
            {quizState.submissionResponse.has_passed ? 'Quiz Passed' : 'Quiz Failed'}
          </p>
        </div>
      </div>
      <div className="quiz-result-body">
        <FontAwesomeIcon
          className={resultIconClass}
          icon={
            quizState.submissionResponse.has_passed && !props.isTimeUp
              ? faCheckCircle
              : !quizState.submissionResponse.has_passed && !props.isTimeUp
              ? faTimes
              : faStopwatch
          }
        />
        <p className="result-text">{renderQuizResult()}</p>
        {(quizState.submissionResponse.topic_certificate_issued ||
          certificateState.newCertificate) &&
          !isReplacementOption && (
            <PDFDownloadLink
              className="download-certificate-button"
              document={<MyPDFDocument type="topic" pdfData={certificateState.topicPDF} />}
              fileName={'kooledge_certificate.pdf'}
            >
              {({ loading }) => (loading ? 'Loading document...' : 'Download Certificate')}
            </PDFDownloadLink>
          )}
        {isReplacementOption && (
          <div className="replacement-wrapper">
            <h3 className="replacement-heading">
              Do you want to replace your current certificate for this topic?
            </h3>
            <div className="replacement-buttons-wrapper">
              <button
                className="replacement-button no"
                onClick={() => setIsReplacementOption(false)}
              >
                No
              </button>
              <button className="replacement-button yes" onClick={handleCreateTopicCertificate}>
                Yes
              </button>
            </div>
          </div>
        )}
      </div>
      {!isReplacementOption && (
        <div className="quiz-result-footer">
          <button className="retry-quiz-button" type="button" onClick={handleRetryQuiz}>
            Retry
          </button>
          <button className="finish-quiz-button" type="button" onClick={props.handleLeaveQuiz}>
            Finish
          </button>
        </div>
      )}
    </div>
  );
};

export default QuizResults;
