import React from 'react';

import './NavAuthButton.scss';

const NavAuthButton = (props) => {
  return (
    <div className={props.className} onClick={props.onClick}>
      <h3>{props.text}</h3>
    </div>
  );
};

export default NavAuthButton;
