import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import TimeAgo from 'react-timeago';
import classNames from 'classnames';
import blankImage from '../../images/blank-image.png';

import { getComments, deleteComment } from '../../actions/commentAction';
import { formatSeconds } from '../../actions/helper';
import { GET_COMMENTS_LIMIT } from '../../actions/constants';

import NewComment from '../NewComment/NewComment';
import Reply from '../Reply/Reply';
import CustomModal from '../CustomModal/CustomModal';

import './Comment.scss';

const Comment = (props) => {
  const [showReplies, setShowReplies] = useState(false);
  const [reply, setReply] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [customModalShow, setCustomModalShow] = useState(false);

  const dispatch = useDispatch();

  const handleDeleteComment = async () => {
    setDisabled(true);

    await dispatch(deleteComment(props.comment.uuid));

    var res = await getComments(props.video.uuid, props.topicUuid, GET_COMMENTS_LIMIT, 0);

    if (res) {
      props.setCurrentVideoComments(res.data.results);
      props.setCurrentVideoNextComment(res.data.next);
    }

    setDisabled(false);
  };

  const nameClass = classNames('name', { 'owner-name': props.comment.is_creator });

  return (
    <>
      <div className="comment" style={{ opacity: disabled ? '0.3' : '1' }}>
        <div className="user-info">
          <img className="avatar" src={props.comment.avatar ? props.comment.avatar : blankImage} />
          <div className="name-wrapper">
            <h3 className={nameClass}>{props.comment.user}</h3>
            <p className="comment-timestamp">-</p>
            <p className="comment-timestamp">
              <TimeAgo
                date={format(new Date(props.comment.timestamp), 'MMM dd, yyyy hh:mm:ss a')}
              />
            </p>
          </div>
        </div>
        <div className="content-wrapper">
          <p className="content">
            {props.comment.video_timestamp > 0 && (
              <p
                className="video-timestamp"
                onClick={() => props.playerRef.current?.seekTo(props.comment.video_timestamp)}
              >
                {formatSeconds(props.comment.video_timestamp)}
              </p>
            )}
            {props.comment.content}
          </p>
          <div className="comment-actions">
            {props.comment.replies.length > 0 && (
              <button
                className="show-replies-button"
                type="button"
                disabled={disabled}
                onClick={() => setShowReplies(!showReplies)}
              >
                {(showReplies ? 'Hide' : 'Show') + ' Replies'}
                <FontAwesomeIcon
                  className="show-replies-icon"
                  style={{ transform: showReplies ? 'rotate(180deg)' : 'unset' }}
                  icon={faChevronDown}
                />
              </button>
            )}

            {localStorage.getItem('accessToken') && (
              <div className="comment-buttons-wrapper">
                {localStorage.getItem('accessToken') &&
                  (props.isOwner ||
                    props.comment.creator_uuid === localStorage.getItem('uuid')) && (
                    <button
                      className="delete-button"
                      type="button"
                      disabled={disabled}
                      onClick={() => setCustomModalShow(true)}
                    >
                      Delete
                    </button>
                  )}
                <button
                  className="reply-button"
                  type="button"
                  disabled={disabled}
                  onClick={() => setReply(!reply)}
                >
                  {reply ? 'Cancel' : 'Reply'}
                </button>
              </div>
            )}
          </div>
        </div>
        {reply && (
          <NewComment
            reply={true}
            video={props.video}
            topicUuid={props.topicUuid}
            parentComment={props.comment}
            setReply={setReply}
            currentVideoComments={props.currentVideoComments}
            setCurrentVideoComments={props.setCurrentVideoComments}
            setCurrentVideoNextComment={props.setCurrentVideoNextComment}
          />
        )}
        {props.comment.replies.length > 0 && showReplies && (
          <div className="comment-replies-grid">
            {props.comment.replies.map((reply) => (
              <Reply
                key={reply.uuid}
                reply={reply}
                video={props.video}
                topicUuid={props.topicUuid}
                isOwner={props.isOwner}
                setCurrentVideoComments={props.setCurrentVideoComments}
                setCurrentVideoNextComment={props.setCurrentVideoNextComment}
              />
            ))}
          </div>
        )}
      </div>
      <CustomModal
        show={customModalShow}
        onHide={() => setCustomModalShow(false)}
        modalTitle="Delete Comment"
        modalBody="Are you sure you want to delete this comment?"
        submitButtonText="Delete"
        handleSubmit={handleDeleteComment}
      />
    </>
  );
};

export default Comment;
