import React, { useState, useEffect } from 'react';
import { RadioGroup, FormControlLabel } from '@material-ui/core';

import StyledRadio from '../StyledRadio/StyledRadio';

const TakeQuizAnswer = (props) => {
  const [radioGroupValue, setRadioGroupValue] = useState('');
  const [isMultipleSelection, setIsMultipleSelection] = useState(false);

  useEffect(() => {
    var correctAnswerCount = 0;
    props.questionsList[props.displayQuestionIndex].answers.forEach((answer) =>
      answer.is_correct ? correctAnswerCount++ : null,
    );
    if (correctAnswerCount > 1) {
      setIsMultipleSelection(true);
    } else {
      setIsMultipleSelection(false);
    }
  }, []);

  useEffect(() => {
    props.selectedAnswersList[props.displayQuestionIndex]?.answers.includes(props.value)
      ? setRadioGroupValue(props.value)
      : setRadioGroupValue('');
  }, [props.selectedAnswersList]);

  const handleSelectAnswer = (e) => {
    var selectedAnswersListCopy = [...props.selectedAnswersList];
    var currentAnswersListCopy = [...props.selectedAnswersList[props.displayQuestionIndex].answers];

    if (e.target.value === radioGroupValue) {
      setRadioGroupValue('');
    }

    if (currentAnswersListCopy.includes(e.target.value)) {
      currentAnswersListCopy = currentAnswersListCopy.filter(
        (answerUuid) => answerUuid !== e.target.value,
      );
      selectedAnswersListCopy[props.displayQuestionIndex] = {
        question: props.questionsList[props.displayQuestionIndex].uuid,
        answers: [...currentAnswersListCopy],
      };
    } else {
      if (isMultipleSelection) {
        currentAnswersListCopy = [...currentAnswersListCopy, e.target.value];
        selectedAnswersListCopy[props.displayQuestionIndex] = {
          question: props.questionsList[props.displayQuestionIndex].uuid,
          answers: [...currentAnswersListCopy],
        };
      } else {
        currentAnswersListCopy = [e.target.value];
        selectedAnswersListCopy[props.displayQuestionIndex] = {
          question: props.questionsList[props.displayQuestionIndex].uuid,
          answers: [...currentAnswersListCopy],
        };
      }
    }

    props.setSelectedAnswersList([...selectedAnswersListCopy]);
  };

  return (
    <RadioGroup
      className="answer-radio"
      aria-label="answer"
      name="styled-answer-radios"
      key={props.key}
      value={radioGroupValue}
    >
      <FormControlLabel
        value={props.value}
        control={<StyledRadio onClick={handleSelectAnswer} />}
        label={props.label}
      />
    </RadioGroup>
  );
};

export default TakeQuizAnswer;
