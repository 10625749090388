import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import blankThumbnail from '../../images/blank-thumbnail.png';

import {
  submitQuiz,
  setQuizSubmitted,
  clearSubmissionResponse,
  setQuizLoading,
} from '../../actions/quizAction';
import { clearCertificateState } from '../../actions/certificateAction';
import { formatSeconds } from '../../actions/helper';

import CardStats from '../CardStats/CardStats';
import CustomModal from '../CustomModal/CustomModal';
import QuizResults from '../QuizResults/QuizResults';
import TakeQuizAnswer from '../TakeQuizAnswer/TakeQuizAnswer';
import Loader from '../Loader/Loader';

import './TakeQuizWindow.scss';

const TakeQuizWindow = ({
  topicUuid,
  quiz,
  setIsStartQuiz,
  onHide,
  customLeaveQuizModalShow,
  setCustomLeaveQuizModalShow,
}) => {
  const [questionsList, setQuestionsList] = useState(quiz.questions ? quiz.questions : []);
  const [displayQuestionIndex, setDisplayQuestionIndex] = useState(0);
  const [selectedAnswersList, setSelectedAnswersList] = useState([]);
  const [estimatedTime, setEstimatedTime] = useState(quiz.is_timed ? quiz.estimated_time : 0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [isRetry, setIsRetry] = useState(false);
  const [questionProgressWidth, setQuestionProgressWidth] = useState(
    quiz.questions ? (quiz.questions.length > 0 ? 100 / quiz.questions.length : 0) : 0,
  );
  const [numberOfQuestionsAnswered, setNumberOfQuestionsAnswered] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const quizState = useSelector((state) => state.quiz);

  const dispatch = useDispatch();

  useEffect(() => {
    var count = 0;
    selectedAnswersList.forEach((question) => (question.answers.length > 0 ? count++ : null));
    setNumberOfQuestionsAnswered(count);
  }, [selectedAnswersList]);

  useEffect(() => {
    if (quizState.quizSubmitted) {
      setIsRetry(false);
    }
  }, [quizState.quizSubmitted]);

  useEffect(() => {
    if (isRetry) {
      setDisplayQuestionIndex(0);
      setEstimatedTime(quiz.is_timed ? quiz.estimated_time : 0);
      setElapsedTime(0);
      setIsTimeUp(false);
      setQuestionProgressWidth(
        quiz.questions ? (quiz.questions.length > 0 ? 100 / quiz.questions.length : 0) : 0,
      );
      setOpenSnackbar(false);

      dispatch(setQuizSubmitted(false));

      if (quiz.questions.length > 0) {
        var selectedAnswersListCopy = [];
        quiz.questions.forEach((question) => {
          const emptyAnswer = { question: `${question.uuid}`, answers: [] };
          selectedAnswersListCopy.push(emptyAnswer);
        });
        setSelectedAnswersList([...selectedAnswersListCopy]);
      }
    }
  }, [isRetry]);

  useEffect(() => {
    setQuestionsList(quiz.questions);

    if (quiz.questions.length > 0) {
      var selectedAnswersListCopy = [];
      quiz.questions.forEach((question) => {
        const emptyAnswer = { question: `${question.uuid}`, answers: [] };
        selectedAnswersListCopy.push(emptyAnswer);
      });
      setSelectedAnswersList([...selectedAnswersListCopy]);
    }
  }, [quiz.questions]);

  useEffect(() => {
    if (!quizState.quizSubmitted) {
      const interval = setInterval(() => {
        if (quiz.is_timed) {
          if (estimatedTime > 0) {
            setEstimatedTime(estimatedTime - 1);
          } else {
            const formData = new FormData();

            formData.append('quiz', quiz.uuid);
            formData.append('topic_uuid', topicUuid);
            formData.append('completion_time', elapsedTime);
            formData.append('timestamp', new Date().getTime());
            formData.append('answers', JSON.stringify(selectedAnswersList));

            dispatch(setQuizLoading());
            dispatch(submitQuiz(formData));

            setIsTimeUp(true);
          }
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  });

  useEffect(() => {
    if (!quizState.quizSubmitted) {
      const interval = setInterval(() => {
        if (elapsedTime < 3600) {
          setElapsedTime(elapsedTime + 1);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleNextQuestion = (e) => {
    e.preventDefault();

    if (displayQuestionIndex < questionsList.length - 1) {
      setDisplayQuestionIndex(displayQuestionIndex + 1);
    } else {
      if (localStorage.getItem('accessToken')) {
        const formData = new FormData();

        formData.append('quiz', quiz.uuid);
        formData.append('topic_uuid', topicUuid);
        formData.append('completion_time', elapsedTime);
        formData.append('timestamp', new Date().getTime());
        formData.append('answers', JSON.stringify(selectedAnswersList));

        dispatch(setQuizLoading());
        dispatch(submitQuiz(formData));
      } else {
        setOpenSnackbar(true);
      }
    }
  };

  const handlePreviousQuestion = (e) => {
    e.preventDefault();

    setDisplayQuestionIndex(displayQuestionIndex - 1);
  };

  const handleLeaveQuiz = () => {
    dispatch(setQuizSubmitted(false));
    dispatch(clearSubmissionResponse());
    dispatch(clearCertificateState());
    setIsStartQuiz(false);
    onHide();
  };

  const nextButtonClass = classNames(
    { 'next-question-button': displayQuestionIndex < questionsList.length - 1 },
    { 'submit-quiz-button': displayQuestionIndex === questionsList.length - 1 },
  );

  return (
    <>
      {customLeaveQuizModalShow ? (
        <CustomModal
          show={customLeaveQuizModalShow}
          onHide={() => setCustomLeaveQuizModalShow(false)}
          modalTitle="Leave Quiz"
          modalBody="Are you sure you want to leave this quiz?"
          submitButtonText="Leave"
          handleSubmit={handleLeaveQuiz}
        />
      ) : (quizState.quizSubmitted || isTimeUp) && !quizState.quizLoading ? (
        <QuizResults
          quizUuid={quiz.uuid}
          isTimeUp={isTimeUp}
          questionsList={questionsList}
          setIsRetry={setIsRetry}
          handleLeaveQuiz={handleLeaveQuiz}
        />
      ) : (
        <div className="take-quiz-window">
          {quizState.quizLoading ? (
            <Loader margin="0" />
          ) : (
            <>
              <div className="quiz-header">
                <div
                  className="quiz-thumbnail"
                  style={{
                    backgroundImage: `url(${quiz?.thumbnail ? quiz.thumbnail : blankThumbnail})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                ></div>
                <div className="quiz-title-wrapper">
                  <h3 className="quiz-title">{quiz.title}</h3>
                  <p className="quiz-description">{quiz.description}</p>
                </div>
                <div className="quiz-stats">
                  <CardStats
                    tries={quiz.try_count}
                    successRate={quiz.success_rate}
                    questions={quiz.questions.length}
                    width="100%"
                    margin="0"
                  />
                </div>
              </div>
              <div className="quiz-body">
                <div className="quiz-progress">
                  <p className="question-progress-indicator">
                    {`${numberOfQuestionsAnswered}/${questionsList.length}`}
                  </p>
                  <div
                    className="question-progress"
                    style={{
                      width: `${questionProgressWidth * numberOfQuestionsAnswered}%`,
                    }}
                  ></div>
                </div>
                <div className="question-section">
                  {questionsList.length > 0 ? (
                    <>
                      <div className="quiz-settings-wrapper">
                        <div
                          className="quiz-time"
                          style={{ background: quiz.is_timed ? '#FA5646' : '#364a4e' }}
                        >
                          <p className="text">
                            {quiz.is_timed ? 'Remaining Time:' : 'Elapsed Time:'}
                          </p>
                          {formatSeconds(quiz.is_timed ? estimatedTime : elapsedTime)}
                        </div>
                        <div className="quiz-points-wrapper">
                          <FontAwesomeIcon icon={faCheckSquare} className="point-icon" />
                          <p className="quiz-points-text">
                            Correct Answer:{' '}
                            <span className="quiz-points-number">
                              +{questionsList[displayQuestionIndex].points_for_correct}
                            </span>
                          </p>
                        </div>
                        <div className="quiz-points-wrapper penalty">
                          <FontAwesomeIcon icon={faTimes} className="penalty-icon" />
                          <p className="quiz-points-text">
                            Wrong Answer:{' '}
                            <span className="quiz-points-number">
                              -
                              {questionsList[displayQuestionIndex].penalty_for_wrong < 0
                                ? questionsList[displayQuestionIndex].penalty_for_wrong * -1
                                : questionsList[displayQuestionIndex].penalty_for_wrong}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="quiz-question-answer-wrapper">
                        <h3 className="quiz-question-number">
                          Question {displayQuestionIndex + 1}:
                        </h3>
                        <p className="quiz-question">
                          Q. {questionsList[displayQuestionIndex].statement}
                        </p>
                        {questionsList[displayQuestionIndex].answers.map((answer) => (
                          <TakeQuizAnswer
                            key={answer.uuid}
                            value={answer.uuid}
                            label={answer.statement}
                            selectedAnswersList={selectedAnswersList}
                            setSelectedAnswersList={setSelectedAnswersList}
                            displayQuestionIndex={displayQuestionIndex}
                            questionsList={questionsList}
                          />
                        ))}
                      </div>
                    </>
                  ) : (
                    <p className="no-questions">No questions</p>
                  )}
                </div>
              </div>
              <div className="quiz-footer">
                <button
                  className="leave-quiz-button"
                  type="button"
                  onClick={() => setCustomLeaveQuizModalShow(true)}
                >
                  Leave Quiz
                </button>
                {questionsList.length > 0 && (
                  <div className="previous-and-next-buttons">
                    {quiz.allow_previous_question && (
                      <button
                        className="previous-question-button"
                        type="button"
                        style={{
                          opacity: displayQuestionIndex === 0 ? '0.3' : '1',
                          pointerEvents: displayQuestionIndex === 0 ? 'none' : 'auto',
                        }}
                        onClick={handlePreviousQuestion}
                      >
                        Previous Question
                      </button>
                    )}
                    <button className={nextButtonClass} type="button" onClick={handleNextQuestion}>
                      {displayQuestionIndex === questionsList.length - 1
                        ? 'Submit Quiz'
                        : 'Next Question'}
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <MuiAlert elevation={6} variant="filled" severity="error">
          Login to submit quiz.
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default TakeQuizWindow;
