import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { getFlow, deleteFlow, setFlowLoading, clearFlowState } from '../../../actions/flowAction';

import FlowCard from '../../FlowCard/FlowCard';
import TopicCardCollapsed from '../../TopicCardCollapsed/TopicCardCollapsed';
import Loader from '../../Loader/Loader';
import CustomModal from '../../CustomModal/CustomModal';

import './Flow.scss';

const Flow = () => {
  const [isOwner, setIsOwner] = useState(false);
  const [isAddTopic, setIsAddTopic] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [customModalShow, setCustomModalShow] = useState(false);
  const [openSuggestVideoSnackbar, setOpenSuggestVideoSnackbar] = useState(false);

  const flowState = useSelector((state) => state.flow);

  const dispatch = useDispatch();

  const history = useHistory();

  const match = useRouteMatch();

  useEffect(async () => {
    dispatch(setFlowLoading());
    await dispatch(getFlow(match.params.uuid));

    return () => {
      dispatch(clearFlowState());
    };
  }, []);

  useEffect(() => {
    if (flowState.errorMessage.data?.detail === 'Not found.') {
      history.push('/login');
    }
  }, [flowState.errorMessage]);

  useEffect(() => {
    if (flowState.creatorUuid === localStorage.getItem('uuid')) {
      setIsOwner(true);
    } else {
      setIsOwner(false);
    }
    if (document.getElementById('addTopicButton')) {
      document.getElementById('addTopicButton').disabled = false;
    }
  }, [flowState]);

  const addTopic = () => {
    if (!editMode) {
      setEditMode(true);
    }
    setIsAddTopic(!isAddTopic);
  };

  const handleDeleteFlow = async () => {
    dispatch(setFlowLoading());
    await dispatch(deleteFlow(flowState.uuid));
    history.go(-1);
  };

  const handleCloseSuggestVideoSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuggestVideoSnackbar(false);
  };

  const editFlowButtonClass = classNames('flow-page-edit-flow-button', {
    'edit-mode-button': editMode,
  });

  const flowPageContainerClass = classNames('my-container flow-page-container', {
    'flow-page-container-non-owner': !isOwner,
  });

  return !flowState.uuid ? (
    <Loader />
  ) : flowState.flowLoading ? (
    <Loader />
  ) : (
    <>
      <div className={flowPageContainerClass}>
        {isOwner && (
          <div className="flow-page-heading-row">
            <div className={editFlowButtonClass} onClick={() => setEditMode(!editMode)}>
              <FontAwesomeIcon icon={faEdit} className="edit-icon" />
              <h3 className="flow-page-edit-flow">{editMode ? 'Done Editing' : 'Edit Flow'}</h3>
            </div>
            {editMode && (
              <div
                className="flow-page-delete-flow-button"
                onClick={() => setCustomModalShow(!customModalShow)}
              >
                <FontAwesomeIcon icon={faTimes} className="delete-icon" />
                <h3 className="flow-page-delete-flow">Delete Flow</h3>
              </div>
            )}
          </div>
        )}
        <FlowCard editMode={editMode} />
        <h3 className="flow-page-topics-heading">Topics</h3>
        {flowState.topics.length ? (
          flowState.topics.map((topic) => (
            <TopicCardCollapsed
              key={topic.uuid}
              topic={topic}
              editMode={editMode}
              isAddTopic={false}
              isOwner={isOwner}
              setOpenSuggestVideoSnackbar={setOpenSuggestVideoSnackbar}
              topics={flowState.topics}
            />
          ))
        ) : (
          <p className="no-topics-text">No Topics</p>
        )}
        {isAddTopic && (
          <TopicCardCollapsed
            topic={{
              title: '',
              description: '',
              tags: [],
              thumbnail: '',
              suggestion: true,
              videos: [],
              quizzes: [],
            }}
            editMode={editMode}
            isAddTopic={isAddTopic}
            isOwner={isOwner}
            setIsAddTopic={setIsAddTopic}
            topics={flowState.topics}
          />
        )}
        {isOwner && editMode && (
          <button
            id="addTopicButton"
            className="add-topic-button"
            type="submit"
            form="topicForm-"
            onClick={addTopic}
          >
            <span>
              <FontAwesomeIcon icon={faPlus} className="add-topic-icon" />
            </span>
            Add Topic
          </button>
        )}
      </div>
      <CustomModal
        show={customModalShow}
        onHide={() => setCustomModalShow(false)}
        modalTitle="Delete Flow"
        modalBody="Are you sure you want to delete this flow?"
        submitButtonText="Delete"
        handleSubmit={handleDeleteFlow}
      />
      <Snackbar
        open={openSuggestVideoSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSuggestVideoSnackbar}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">
          Videos suggested.
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default Flow;
