import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Checkbox, FormControlLabel, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Collapse } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faExpandArrowsAlt,
  faTimesCircle,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';
import shareFlow from '../../images/share-flow-icon.png';
import videoIconWhite from '../../images/video-icon-white.png';

import { likeVideo, dislikeVideo, getMyVideos, setVideoLoading } from '../../actions/videoAction';
import { setQuizSubmitted } from '../../actions/quizAction';
import { getComments } from '../../actions/commentAction';
import {
  MAXIMUM_TITLE_LENGTH,
  MAXIMUM_DESCRIPTION_LENGTH,
  GET_COMMENTS_LIMIT,
} from '../../actions/constants';

import CardStats from '../CardStats/CardStats';
import ResponsivePlayer from '../ResponsivePlayer/ResponsivePlayer';
import Tag from '../Tag/Tag';
import TagModal from '../TagModal/TagModal';
import CustomModal from '../CustomModal/CustomModal';
import TopicFeaturedContentCard from '../TopicFeaturedContentCard/TopicFeaturedContentCard';
import TopicContentList from '../TopicContentList/TopicContentList';
import VideoNote from '../VideoNote/VideoNote';
import SocialShareModal from '../SocialShareModal/SocialShareModal';
import NewComment from '../NewComment/NewComment';
import Comment from '../Comment/Comment';
import Loader from '../Loader/Loader';
import AddTopicVideoModal from '../AddTopicVideoModal/AddTopicVideoModal';
import QuizWindowModal from '../QuizWindowModal/QuizWindowModal';

import './TopicCardExpanded.scss';

const TopicCardExpanded = (props) => {
  const [currentVideo, setCurrentVideo] = useState(props.topic.featured_video);
  const [currentVideoUuid, setCurrentVideoUuid] = useState('');
  const [currentVideoTitle, setCurrentVideoTitle] = useState('');
  const [currentVideoDescription, setCurrentVideoDescription] = useState('');
  const [currentVideoCreatorName, setCurrentVideoCreatorName] = useState('');
  const [currentVideoTags, setCurrentVideoTags] = useState('');
  const [currentVideoLiked, setCurrentVideoLiked] = useState(false);
  const [currentVideoLikes, setCurrentVideoLikes] = useState(0);
  const [currentVideoViews, setCurrentVideoViews] = useState(0);
  const [currentVideoComments, setCurrentVideoComments] = useState([]);
  const [currentVideoNextComment, setCurrentVideoNextComment] = useState('');

  const [isStartQuiz, setIsStartQuiz] = useState(false);
  const [isTakeQuiz, setIsTakeQuiz] = useState(false);

  const [quizWindowModalShow, setQuizWindowModalShow] = useState(false);
  const [suggestTopicVideoModalShow, setSuggestTopicVideoModalShow] = useState(false);
  const [customModalShow, setCustomModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [videoModalShow, setVideoModalShow] = useState(false);
  const [socialShareTopicModalShow, setSocialShareTopicModalShow] = useState(false);
  const [socialShareVideoModalShow, setSocialShareVideoModalShow] = useState(false);
  const [showTopicDetail, setShowTopicDetail] = useState(false);
  const [showTopicVideoDetail, setShowTopicVideoDetail] = useState(false);
  const [selectedTab, setSelectedTab] = useState(`browse-videos-${props.topic.uuid}`);
  const [previousSelectedTab, setPreviousSelectedTab] = useState(
    `browse-videos-${props.topic.uuid}`,
  );
  const [newComment, setNewComment] = useState(false);
  const [openCommentSnackbar, setOpenCommentSnackbar] = useState(false);
  const [playerPlaying, setPlayerPlaying] = useState(true);
  const [playerProgress, setPlayerProgress] = useState('');

  const flowState = useSelector((state) => state.flow);

  const dispatch = useDispatch();

  const match = useRouteMatch();

  const playerRef = useRef();

  // useEffect(() => {
  //   const updatedVideo = props.topic.videos.find((video) => video.uuid === currentVideo.uuid);
  //   setCurrentVideo(updatedVideo);
  // }, [props.topic]);

  useEffect(() => {
    if (match.params.videoUuid && match.params.topicUuid === props.topic.uuid) {
      const vid = props.topic.videos.find((video) => video.uuid === match.params.videoUuid);
      setCurrentVideo(vid ? vid : props.topic.featured_video);
    }
  }, []);

  useEffect(async () => {
    if (currentVideo) {
      setCurrentVideoUuid(currentVideo.uuid);
      setCurrentVideoTitle(currentVideo.title);
      setCurrentVideoDescription(currentVideo.description);
      setCurrentVideoCreatorName(currentVideo.creator_name);
      setCurrentVideoTags(currentVideo.tags);
      setCurrentVideoLiked(currentVideo.is_liked);
      setCurrentVideoLikes(currentVideo.likes);
      setCurrentVideoViews(currentVideo.views);

      var res = await getComments(currentVideo.uuid, props.topic.uuid, GET_COMMENTS_LIMIT, 0);

      if (res) {
        setCurrentVideoComments(res.data.results);
        setCurrentVideoNextComment(res.data.next);
      }
    }
  }, [currentVideo]);

  useEffect(() => {
    if (!currentVideo && props.topic.featured_video) {
      setCurrentVideo(props.topic.featured_video);
    }
  }, [props.topic.featured_video]);

  const handleTabSelect = (e) => {
    document.getElementById(previousSelectedTab).classList.remove('topic-card-tab-selected');
    document.getElementById(e.target.id).classList.add('topic-card-tab-selected');
    setPreviousSelectedTab(e.target.id);
    setSelectedTab(e.target.id);
  };

  const removeCurrentVideoTag = (tagId) => {
    setCurrentVideoTags((prevState) => prevState.filter((tag, index) => index !== tagId));
  };

  const handleCurrentVideoLiked = () => {
    if (localStorage.getItem('accessToken')) {
      if (currentVideoLiked) {
        setCurrentVideoLiked(!currentVideoLiked);
        dispatch(
          dislikeVideo({ topic_uuid: props.topic.uuid }, currentVideo.uuid, match.params.uuid),
        );
        setCurrentVideoLikes(currentVideoLikes > 0 ? currentVideoLikes - 1 : 0);
      } else {
        setCurrentVideoLiked(!currentVideoLiked);
        dispatch(likeVideo({ topic_uuid: props.topic.uuid }, currentVideo.uuid, match.params.uuid));
        setCurrentVideoLikes(currentVideoLikes + 1);
      }
    }
  };

  const handleLeaveQuiz = () => {
    dispatch(setQuizSubmitted(false));
    setIsStartQuiz(false);
    setIsTakeQuiz(false);
    props.setExpandCard(!props.expandCard);
  };

  const handleNewComment = () => {
    if (!newComment) {
      setPlayerPlaying(false);
      setNewComment(true);
    } else {
      setPlayerPlaying(true);
      setNewComment(false);
    }
  };

  const fetchMoreComments = async () => {
    var res = await getComments(
      currentVideo.uuid,
      props.topic.uuid,
      GET_COMMENTS_LIMIT,
      currentVideoComments.length,
    );

    if (res) {
      setCurrentVideoComments((prevState) => [...prevState, ...res.data.results]);
      setCurrentVideoNextComment(res.data.next);
    }
  };

  const handleCloseCommentSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenCommentSnackbar(false);
  };

  const handleSuggestTopicVideoModalShow = () => {
    setSuggestTopicVideoModalShow(!suggestTopicVideoModalShow);
    dispatch(setVideoLoading());
    dispatch(getMyVideos());
  };

  const collapseTopicDetailIconClass = classNames('collapse-detail-icon', {
    'collapse-detail-icon-rotate': showTopicDetail,
  });

  const collapseVideoDetailIconClass = classNames('collapse-detail-icon', {
    'collapse-detail-icon-rotate': showTopicVideoDetail,
  });

  return (
    <>
      <div className="topic-card-expanded-wrapper">
        {props.editMode && (
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="delete-topic-icon"
            onClick={() => setCustomModalShow(!customModalShow)}
          />
        )}
        <div className="topic-card-expanded-row-1">
          <div className="topic-card-expanded-title-and-details-wrapper">
            <div className="topic-card-expanded-title-textarea-wrapper">
              <textarea
                className="topic-card-expanded-title-textarea"
                maxLength={`${MAXIMUM_TITLE_LENGTH}`}
                placeholder={props.editMode ? 'Topic Title' : ''}
                disabled={props.editMode ? false : true}
                value={props.title}
                onChange={(e) => props.setTitle(e.target.value)}
                onBlur={props.handleTitleChange}
              />
              <FontAwesomeIcon
                icon={faChevronDown}
                className={collapseTopicDetailIconClass}
                onClick={() => setShowTopicDetail(!showTopicDetail)}
              />
              <div className="topic-expand-buttons-wrapper">
                <div
                  className="share-topic-button"
                  style={{
                    opacity: props.editMode ? '0.3' : '1',
                    pointerEvents: props.editMode ? 'none' : 'auto',
                  }}
                  onClick={() => setSocialShareTopicModalShow(true)}
                >
                  <img src={shareFlow} alt="share-topic" />
                  <h3 className="share-topic-button-text">Share Topic</h3>
                </div>
                {localStorage.getItem('accessToken') && props.suggestion && !props.isOwner && (
                  <div
                    className="suggest-video-button"
                    style={{
                      opacity: props.editMode || isTakeQuiz ? '0.3' : '1',
                      pointerEvents: props.editMode || isTakeQuiz ? 'none' : 'auto',
                    }}
                    onClick={handleSuggestTopicVideoModalShow}
                  >
                    <img src={videoIconWhite} alt="suggest-video" />
                    <h3 className="suggest-video-button-text">Suggest Video</h3>
                  </div>
                )}

                <div
                  className="topic-collapse-button"
                  onClick={() => props.setExpandCard(!props.expandCard)}
                >
                  <FontAwesomeIcon icon={faExpandArrowsAlt} className="topic-collapse-icon" />
                  <h3 className="topic-collapse-button-text">Collapse</h3>
                </div>
              </div>
            </div>
            <Collapse in={showTopicDetail}>
              <div className="topic-card-expanded-details">
                <CardStats
                  views={props.topic.videos.map((video) => video.views).reduce((a, b) => a + b, 0)}
                  likes={props.topic.videos.map((video) => video.likes).reduce((a, b) => a + b, 0)}
                  videos={props.topic.videos.length}
                  quizzes={props.topic.quizzes.length}
                  width="100%"
                  margin="6px 6px 15px"
                />
                {props.description || props.editMode ? (
                  <textarea
                    className="topic-card-expanded-details-description-textarea"
                    maxLength={`${MAXIMUM_DESCRIPTION_LENGTH}`}
                    placeholder={
                      props.editMode ? 'Click here to start writing the description' : ''
                    }
                    disabled={props.editMode ? false : true}
                    value={props.description}
                    onChange={(e) => props.setDescription(e.target.value)}
                    onBlur={props.handleDescriptionChange}
                  />
                ) : null}
                {props.editMode && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.suggestion}
                        onClick={props.setSuggestion}
                        value="suggest-video"
                        color="primary"
                      />
                    }
                    label="Others can suggest a video for this topic"
                  />
                )}
                {props.tags.length > 0 || props.editMode ? (
                  <div className="topic-card-expanded-details-tags-row">
                    {props.tags.length ? (
                      props.tags.map((tag, index) => (
                        <Tag
                          key={index}
                          tagId={index}
                          tagText={tag}
                          tagType="topic"
                          editMode={props.editMode}
                          removeTag={props.removeTag}
                        />
                      ))
                    ) : (
                      <p className="topic-card-expanded-details-no-tags">No Tags</p>
                    )}
                    {props.editMode && (
                      <div
                        className="topic-card-expanded-details-add-tag-button"
                        onClick={() => setModalShow(true)}
                      >
                        <FontAwesomeIcon icon={faPlus} className="add-icon" />
                        <p className="topic-card-expanded-details-add-tag-button-text">Add Tag</p>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </Collapse>
          </div>
        </div>
        <div className="topic-card-expanded-row-2">
          <ResponsivePlayer
            playerRef={playerRef}
            url={currentVideo ? currentVideo.url : ''}
            currentVideo={currentVideo ? currentVideo : ''}
            topic={props.topic}
            setExpandCard={props.setExpandCard}
            playerPlaying={playerPlaying}
            setPlayerPlaying={setPlayerPlaying}
            playerProgress={playerProgress}
            setPlayerProgress={setPlayerProgress}
            setQuizWindowModalShow={setQuizWindowModalShow}
          />
        </div>
        {currentVideo ? (
          <div className="topic-card-expanded-row-3">
            <div className="topic-video-title-and-details-wrapper">
              <div className="topic-video-title-textarea-wrapper">
                <textarea
                  className="topic-video-title-textarea"
                  maxLength={`${MAXIMUM_TITLE_LENGTH}`}
                  placeholder="Video Title"
                  disabled={true}
                  value={currentVideoTitle}
                  onChange={(e) => setCurrentVideoTitle(e.target.value)}
                />
                <div
                  className="topic-video-details-icon"
                  onClick={() => setShowTopicVideoDetail(!showTopicVideoDetail)}
                >
                  <p className="topic-video-details-icon-text">See all details</p>
                  <FontAwesomeIcon icon={faChevronDown} className={collapseVideoDetailIconClass} />
                </div>
              </div>
              <Collapse in={showTopicVideoDetail}>
                <div className="topic-video-details">
                  {currentVideoDescription && (
                    <p className="topic-video-description-textarea">{currentVideoDescription}</p>
                  )}
                  <div className="topic-video-owner-and-tags-wrapper">
                    <div className="topic-video-owner-wrapper">
                      <FontAwesomeIcon icon={faUser} className="user-icon" />
                      <p className="topic-video-owner-name">{currentVideoCreatorName}</p>
                    </div>
                    {currentVideoTags.length > 0 && (
                      <div className="topic-video-tags-row">
                        {currentVideoTags ? (
                          currentVideoTags.map((currentVideoTag, index) => (
                            <Tag
                              key={index}
                              tagId={index}
                              tagText={currentVideoTag}
                              tagType="topic"
                              editMode={false}
                              removeTag={removeCurrentVideoTag}
                            />
                          ))
                        ) : (
                          <p className="topic-video-no-tags">No Tags</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Collapse>
            </div>
            <div className="topic-video-stats-wrapper">
              <CardStats
                views={currentVideoViews}
                likes={currentVideoLikes}
                likable={true}
                liked={currentVideoLiked}
                setLiked={handleCurrentVideoLiked}
                noMargin={true}
                width="auto"
                button="like"
                margin="6px 6px 15px"
              />
              <div
                className="share-topic-video-button"
                style={{
                  opacity: props.editMode ? '0.3' : '1',
                  pointerEvents: props.editMode ? 'none' : 'auto',
                }}
                onClick={() => setSocialShareVideoModalShow(true)}
              >
                <img src={shareFlow} alt="share-video" />
                <h3 className="share-topic-video-button-text">Share Video</h3>
              </div>
            </div>
          </div>
        ) : null}
        <div className="topic-card-expanded-row-4">
          <div className="topic-card-tab-labels-wrapper">
            <div
              id={`browse-videos-${props.topic.uuid}`}
              className="topic-card-tab-label topic-card-tab-selected"
              onClick={handleTabSelect}
            >
              Browse Videos
            </div>
            <div
              id={`browse-quizzes-${props.topic.uuid}`}
              className="topic-card-tab-label topic-card-tab-label-center"
              onClick={handleTabSelect}
            >
              Browse Quizzes
            </div>
            {currentVideo && (
              <div
                id={`notes-${props.topic.uuid}`}
                className="topic-card-tab-label"
                onClick={handleTabSelect}
              >
                Notes
              </div>
            )}
            {currentVideo && (
              <div
                id={`comments-${props.topic.uuid}`}
                className="topic-card-tab-label"
                onClick={handleTabSelect}
              >
                Comments
              </div>
            )}
          </div>
          {selectedTab === `browse-videos-${props.topic.uuid}` && (
            <div className="topic-card-browse-videos-panel">
              <TopicFeaturedContentCard
                featuredVideo={props.topic.featured_video ? props.topic.featured_video : ''}
                topicUuid={props.topic.uuid}
                setCurrentVideo={setCurrentVideo}
                setCurrentVideoTitle={setCurrentVideoTitle}
                editMode={props.editMode}
              />
              <TopicContentList
                topicUuid={props.topic.uuid}
                videos={props.topic.videos}
                featuredVideo={props.topic.featured_video ? props.topic.featured_video : ''}
                currentVideo={currentVideo}
                setCurrentVideo={setCurrentVideo}
                setCurrentVideoTitle={setCurrentVideoTitle}
                editMode={props.editMode}
              />
            </div>
          )}
          {selectedTab === `browse-quizzes-${props.topic.uuid}` && (
            <div className="topic-card-browse-quizzes-panel">
              <TopicFeaturedContentCard
                officialQuiz={props.topic.official_quiz ? props.topic.official_quiz : ''}
                topicUuid={props.topic.uuid}
                quizView={true}
                editMode={props.editMode}
                setQuizWindowModalShow={setQuizWindowModalShow}
              />
              <TopicContentList
                topicUuid={props.topic.uuid}
                quizzes={props.topic.quizzes}
                officialQuiz={props.topic.official_quiz ? props.topic.official_quiz : ''}
                quizView={true}
                editMode={props.editMode}
              />
            </div>
          )}
          {selectedTab === `notes-${props.topic.uuid}` && (
            <div className="topic-card-notes-panel">
              {currentVideo?.notes.length > 0 && (
                <div className="topic-card-notes-grid">
                  {currentVideo.notes.map((note) => (
                    <VideoNote key={note.uuid} note={note} />
                  ))}
                </div>
              )}
            </div>
          )}
          {selectedTab === `comments-${props.topic.uuid}` && (
            <div className="topic-card-comments-panel">
              {localStorage.getItem('accessToken') && currentVideo && (
                <button className="new-comment-button" type="button" onClick={handleNewComment}>
                  {newComment ? 'Cancel' : '+ New Comment'}
                </button>
              )}
              {newComment && (
                <NewComment
                  video={currentVideo}
                  topicUuid={props.topic.uuid}
                  setNewComment={setNewComment}
                  setOpenCommentSnackbar={setOpenCommentSnackbar}
                  currentVideoComments={currentVideoComments}
                  setCurrentVideoComments={setCurrentVideoComments}
                  setCurrentVideoNextComment={setCurrentVideoNextComment}
                  playerProgress={playerProgress}
                  playerRef={playerRef}
                />
              )}
              {currentVideoComments.length > 0 && (
                <div
                  id="topicCardCommentsGrid"
                  className="topic-card-comments-grid"
                  style={{ rowGap: localStorage.getItem('accessToken') ? '5px' : '10px' }}
                >
                  <InfiniteScroll
                    dataLength={currentVideoComments.length}
                    next={fetchMoreComments}
                    hasMore={currentVideoNextComment ? true : false}
                    loader={
                      <Loader
                        margin="0"
                        height="4vh"
                        svgWidth="1.2rem"
                        svgHeight="1.2rem"
                        svgBorderWidth="0.2em"
                      />
                    }
                    scrollableTarget="topicCardCommentsGrid"
                  >
                    {currentVideoComments.map((comment) => (
                      <Comment
                        key={comment.uuid}
                        comment={comment}
                        video={currentVideo}
                        topicUuid={props.topic.uuid}
                        isOwner={props.isOwner}
                        currentVideoComments={currentVideoComments}
                        setCurrentVideoComments={setCurrentVideoComments}
                        setCurrentVideoNextComment={setCurrentVideoNextComment}
                        playerRef={playerRef}
                      />
                    ))}
                  </InfiniteScroll>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <TagModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        tagType="topic"
        tags={props.tags}
        setTags={props.setTags}
      />
      <TagModal
        show={videoModalShow}
        onHide={() => setVideoModalShow(false)}
        tagType="video"
        tags={currentVideoTags}
        setTags={setCurrentVideoTags}
      />
      <CustomModal
        show={customModalShow}
        onHide={() => setCustomModalShow(false)}
        modalTitle="Delete Topic"
        modalBody="Are you sure you want to delete this topic?"
        submitButtonText="Delete"
        handleSubmit={props.handleDeleteTopic}
      />
      <SocialShareModal
        show={socialShareTopicModalShow}
        onHide={() => setSocialShareTopicModalShow(false)}
        url={`${window.location.origin}/flow/${flowState.uuid}/${props.topic.uuid}`}
      />
      <SocialShareModal
        show={socialShareVideoModalShow}
        onHide={() => setSocialShareVideoModalShow(false)}
        url={`${window.location.origin}/flow/${flowState.uuid}/${props.topic.uuid}/${currentVideoUuid}`}
      />
      <Snackbar
        open={openCommentSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseCommentSnackbar}
      >
        <MuiAlert elevation={6} variant="filled" severity="success" {...props}>
          Comment posted.
        </MuiAlert>
      </Snackbar>
      <AddTopicVideoModal
        show={suggestTopicVideoModalShow}
        onHide={() => setSuggestTopicVideoModalShow(false)}
        topicUuid={props.topic.uuid}
        suggest={true}
        topicVideos={props.topic.videos}
        setOpenSuggestVideoSnackbar={props.setOpenSuggestVideoSnackbar}
      />
      {props.topic.official_quiz && (
        <QuizWindowModal
          show={quizWindowModalShow}
          onHide={() => setQuizWindowModalShow(false)}
          topicUuid={props.topic.uuid}
          quiz={props.topic.official_quiz}
          isStartQuiz={isStartQuiz}
          setIsStartQuiz={setIsStartQuiz}
          isTakeQuiz={isTakeQuiz}
          setIsTakeQuiz={setIsTakeQuiz}
        />
      )}
    </>
  );
};

export default TopicCardExpanded;
