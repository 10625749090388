import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';

import { getComments, addComment, addReply } from '../../actions/commentAction';
import { formatSeconds } from '../../actions/helper';
import { MINIMUM_COMMENT_LENGTH, GET_COMMENTS_LIMIT } from '../../actions/constants';

import './NewComment.scss';

const NewComment = (props) => {
  const [comment, setComment] = useState('');
  const [timestampCheck, setTimestampCheck] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [commentDisabled, setCommentDisabled] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.playerRef?.current === null) {
      setTimestampCheck(false);
    }
  }, [props.playerRef?.current]);

  useEffect(() => {
    comment.length === 0 ? setButtonDisabled(true) : setButtonDisabled(false);
  }, [comment]);

  const handlePostComment = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append('topic_uuid', props.topicUuid);
    formData.append('video_uuid', props.video.uuid);
    formData.append('content', comment);

    const date = new Date();
    formData.append('timestamp', date.getTime());

    if (timestampCheck && !props.reply) {
      console.log(props.playerRef.current?.getCurrentTime().toFixed(0));
      formData.append('video_timestamp', props.playerRef.current?.getCurrentTime().toFixed(0));
    }

    setButtonDisabled(true);
    setCommentDisabled(true);

    if (props.reply) {
      formData.append('parent_uuid', props.parentComment.uuid);
      formData.append('timestamp', date.getTime());

      await dispatch(addReply(formData));

      var res = await getComments(
        props.video.uuid,
        props.topicUuid,
        props.currentVideoComments.length,
        0,
      );

      props.setReply(false);
    } else {
      await dispatch(addComment(formData));

      var res = await getComments(props.video.uuid, props.topicUuid, GET_COMMENTS_LIMIT, 0);

      props.setOpenCommentSnackbar(true);
      props.setNewComment(false);
    }

    if (res) {
      props.setCurrentVideoComments(res.data.results);
      props.setCurrentVideoNextComment(res.data.next);
    }

    setButtonDisabled(false);
    setCommentDisabled(false);
    setComment('');
  };

  return (
    <div className="new-comment" style={{ margin: props.reply ? '10px 0 0 48px' : '0' }}>
      <form
        className="new-comment-form"
        onKeyPress={(event) => {
          if (event.which === 13 /* Enter */) {
            event.preventDefault();
          }
        }}
        encType="multipart/form-data"
        onSubmit={handlePostComment}
      >
        <TextField
          className="comment-input"
          label=""
          placeholder={'Enter ' + (props.reply ? 'reply' : 'comment') + ' here'}
          variant="outlined"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          disabled={commentDisabled}
          multiline
          required
          InputProps={{
            inputProps: {
              minLength: MINIMUM_COMMENT_LENGTH,
            },
          }}
        />
        <div className="new-comment-buttons-wrapper">
          {!props.reply && (
            <div className="timestamp-wrapper">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={timestampCheck}
                    onClick={() =>
                      setTimestampCheck(props.playerRef.current ? !timestampCheck : false)
                    }
                    value="timestamp"
                    color="primary"
                  />
                }
                label="Video Timestamp - "
              />
              <p className="timestamp-text">
                {timestampCheck
                  ? formatSeconds(props.playerRef.current?.getCurrentTime())
                  : '- : - -'}
              </p>
            </div>
          )}
          <button className="post-comment-button" type="submit" disabled={buttonDisabled}>
            Post
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewComment;
