import {
  CREATE_TOPIC,
  CREATE_TOPIC_ERROR,
  DELETE_TOPIC,
  DELETE_TOPIC_ERROR,
  EDIT_TOPIC,
  EDIT_TOPIC_ERROR,
  CLEAR_TOPIC_CREATED,
  TOPIC_LOADING,
} from '../actions/actionTypes';

const INITIAL_STATE = {
  topic: '',
  errorMessage: '',
  topicLoading: false,
};

const topicReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_TOPIC:
      return {
        ...state,
        topic: action.payload,
        errorMessage: '',
        topicLoading: false,
      };
    case CREATE_TOPIC_ERROR:
      return {
        ...state,
        topic: '',
        errorMessage: action.payload,
        topicLoading: false,
      };
    case DELETE_TOPIC:
      return {
        ...state,
        topic: '',
        topicLoading: false,
      };
    case DELETE_TOPIC_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        topicLoading: false,
      };
    case EDIT_TOPIC:
      return {
        ...state,
        errorMessage: '',
        topicLoading: false,
      };
    case EDIT_TOPIC_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        topicLoading: false,
      };
    case CLEAR_TOPIC_CREATED:
      return {
        ...state,
        topic: '',
      };
    case TOPIC_LOADING:
      return {
        ...state,
        topicLoading: true,
      };
    default:
      return state;
  }
};

export default topicReducer;
