import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import blankPicture from '../../images/blank-image.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import SearchBar from 'material-ui-search-bar';

import { signOut } from '../../actions/authAction';
import { toggleProfileMenu, toggleSideMenu } from '../../actions/navbarAction';
import { clearProfile } from '../../actions/profileAction';
import {
  searchFlows,
  clearSearchedFlows,
  setSearchFlowLoading,
  setLoadSearchResults,
  incrementSearchFlowsAPICount,
} from '../../actions/flowAction';

import './ProfileMenu.scss';

const ProfileMenu = ({ isMobile }) => {
  const [searchText, setSearchText] = useState('');

  const profileState = useSelector((state) => state.profile);
  const flowState = useSelector((state) => state.flow);

  const dispatch = useDispatch();

  const history = useHistory();

  const handleLogout = () => {
    dispatch(signOut());
    dispatch(toggleProfileMenu());
    dispatch(clearProfile());
    window.location.href = '/';
  };

  const handleLogin = () => {
    dispatch(toggleProfileMenu());
    history.push('/login');
  };

  const handleMobileLogout = () => {
    dispatch(signOut());
    dispatch(toggleSideMenu());
    dispatch(clearProfile());
    window.location.href = '/';
  };

  const handleMobileLogin = () => {
    dispatch(toggleSideMenu());
    history.push('/login');
  };

  const handleMobileSignUp = () => {
    dispatch(toggleSideMenu());
    history.push('/register');
  };

  const handleMobileNewFlow = () => {
    if (!flowState.flowLoading) {
      if (localStorage.getItem('accessToken')) {
        history.push('/create-flow');
      } else {
        history.push('/login');
      }
      dispatch(toggleSideMenu());
    }
  };

  const handleSearchBarChange = (newValue) => {
    setSearchText(newValue);
  };

  const handleCancelSearch = () => {
    setSearchText('');
    dispatch(clearSearchedFlows());
    dispatch(setLoadSearchResults(false));
  };

  const handleSearchEnter = (e) => {
    if (e.keyCode == 13) {
      if (e.target.value) {
        dispatch(setSearchFlowLoading());
        dispatch(incrementSearchFlowsAPICount());
        dispatch(searchFlows(e.target.value));
        dispatch(setLoadSearchResults(true));
        dispatch(toggleSideMenu());

        e.target.blur();

        history.push('/search-flows');
      } else {
        dispatch(clearSearchedFlows());
      }
    }
  };

  return !isMobile ? (
    <>
      <div id="overlay" onClick={() => dispatch(toggleProfileMenu())}></div>
      <div className="arrow-up"></div>
      <div className="profile-menu">
        {profileState.uuid && (
          <div className="profile-menu-wrapper">
            <p className="profile-menu-name">
              {profileState.firstName + ' ' + profileState.lastName}
            </p>
            <p className="profile-menu-email">{profileState.email}</p>
            <div className="profile-menu-divider profile-menu-item"></div>
          </div>
        )}
        <Link
          to="/my-profile"
          className="profile-menu-link profile-menu-item"
          onClick={() => dispatch(toggleProfileMenu())}
        >
          My Profile
        </Link>
        <Link
          to="/my-profile/account-settings"
          className="profile-menu-link profile-menu-item"
          onClick={() => dispatch(toggleProfileMenu())}
        >
          Account Settings
        </Link>
        <div className="profile-menu-divider profile-menu-item"></div>
        {localStorage.getItem('accessToken') ? (
          <p className="profile-menu-logout profile-menu-item" onClick={handleLogout}>
            Logout
          </p>
        ) : (
          <p className="profile-menu-login profile-menu-item" onClick={handleLogin}>
            Login
          </p>
        )}
      </div>
    </>
  ) : (
    <>
      <div className="profile-menu-mobile">
        {profileState.uuid && (
          <div className="profile-menu-profile-wrapper">
            <img
              className="profile-menu-profile-image"
              src={profileState.avatar ? profileState.avatar : blankPicture}
              alt="profile-picture"
            />
            <div className="profile-menu-user-wrapper">
              <p className="profile-menu-user-name">
                {profileState.firstName + ' ' + profileState.lastName}
              </p>
              <p className="profile-menu-user-email">{profileState.email}</p>
            </div>
          </div>
        )}
        <div className="new-flow-button-wrapper profile-menu-item" onClick={handleMobileNewFlow}>
          <FontAwesomeIcon icon={faPlus} className="new-flow-button-icon" />
          <h3>New Flow</h3>
        </div>
        {localStorage.getItem('accessToken') && (
          <div className="profile-menu-divider profile-menu-item"></div>
        )}
        {localStorage.getItem('accessToken') && (
          <Link
            to="/my-profile"
            className="profile-menu-link profile-menu-item"
            onClick={() => dispatch(toggleSideMenu())}
          >
            My Profile
          </Link>
        )}
        {localStorage.getItem('accessToken') && (
          <Link
            to="/my-profile/account-settings"
            className="profile-menu-link profile-menu-item"
            onClick={() => dispatch(toggleSideMenu())}
          >
            Account Settings
          </Link>
        )}
        <div className="profile-menu-divider profile-menu-item"></div>
        {localStorage.getItem('accessToken') ? (
          <p className="profile-menu-logout profile-menu-item" onClick={handleMobileLogout}>
            Logout
          </p>
        ) : (
          <p className="profile-menu-login profile-menu-item" onClick={handleMobileLogin}>
            Login
          </p>
        )}
        {!localStorage.getItem('accessToken') && (
          <p className="profile-menu-login profile-menu-item" onClick={handleMobileSignUp}>
            Sign Up
          </p>
        )}
        <div className="profile-menu-divider profile-menu-item"></div>
        <SearchBar
          placeholder="Search"
          value={searchText}
          onChange={(newValue) => handleSearchBarChange(newValue)}
          onCancelSearch={handleCancelSearch}
          onKeyDown={(e) => handleSearchEnter(e)}
        />
      </div>
    </>
  );
};

export default ProfileMenu;
