import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import './Tag.scss';

const Tag = (props) => {
  const tagWrapperClass = classNames(
    'tag-wrapper',
    { 'tag-wrapper-flow': props.tagType === 'flow' },
    { 'tag-wrapper-topic': props.tagType === 'topic' || props.tagType === 'video' },
  );

  return (
    <div className={tagWrapperClass}>
      <p className="tag-text">{props.tagText}</p>
      {props.editMode && (
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="tag-delete-icon"
          onClick={() => props.removeTag(props.tagId)}
        />
      )}
    </div>
  );
};

export default Tag;
