import { TOGGLE_PROFILE_MENU, TOGGLE_SIDE_MENU } from '../actions/actionTypes';

const INITIAL_STATE = {
  showProfileMenu: false,
  showSideMenu: false,
};

const navbarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_PROFILE_MENU:
      return {
        ...state,
        showProfileMenu: !state.showProfileMenu,
      };
    case TOGGLE_SIDE_MENU:
      return {
        ...state,
        showSideMenu: !state.showSideMenu,
      };
    default:
      return state;
  }
};

export default navbarReducer;
