import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSideMenu } from '../../actions/navbarAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import ProfileMenu from '../ProfileMenu/ProfileMenu';

import './NavbarMenuMobile.scss';

const NavbarMenuMobile = (props) => {
  const navbarState = useSelector((state) => state.navbar);

  const dispatch = useDispatch();

  return (
    <>
      {navbarState.showSideMenu ? (
        <div id="overlay" onClick={() => dispatch(toggleSideMenu())}></div>
      ) : null}
      <div className={props.className}>
        <FontAwesomeIcon
          icon={faTimes}
          className="menu-icon-times side-menu-item"
          onClick={() => dispatch(toggleSideMenu())}
        />
        <ProfileMenu isMobile={true} />
      </div>
    </>
  );
};

export default NavbarMenuMobile;
