import React from 'react';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import { Tooltip } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbsUp as faThumbsUpUnliked,
  faEye,
  faStickyNote,
  faPlayCircle,
  faQuestionCircle,
  faUser,
  faClock,
  faHourglass,
} from '@fortawesome/free-regular-svg-icons';
import { faThumbsUp as faThumbsUpLiked, faCheck } from '@fortawesome/free-solid-svg-icons';

import { formatSeconds } from '../../actions/helper';

import './CardStats.scss';

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: '#364a4e',
  },
  tooltip: {
    backgroundColor: '#364a4e',
  },
  tooltipPlacementTop: {
    margin: '6px 0',
  },
}));

const CardStats = (props) => {
  const classes = useStylesBootstrap();

  const likeIconClass = classNames('card-stats-icon', { 'like-button': props.button === 'like' });

  return (
    <div className="card-stats-row" style={{ width: `${props.width}`, margin: `${props.margin}` }}>
      {props.views >= 0 ? (
        <Tooltip classes={classes} title="Views" placement="top" arrow>
          <div className="card-stats-wrapper">
            <FontAwesomeIcon icon={faEye} className="card-stats-icon" />
            <p className="card-stats">
              <NumberFormat value={props.views} displayType={'text'} thousandSeparator={true} />
            </p>
          </div>
        </Tooltip>
      ) : null}
      {props.likes >= 0 ? (
        <Tooltip classes={classes} title="Likes" placement="top" arrow>
          <div className="card-stats-wrapper">
            <FontAwesomeIcon
              icon={props.liked ? faThumbsUpLiked : faThumbsUpUnliked}
              className={likeIconClass}
              onClick={props.setLiked}
            />
            <p className="card-stats">
              <NumberFormat value={props.likes} displayType={'text'} thousandSeparator={true} />
            </p>
          </div>
        </Tooltip>
      ) : null}
      {props.videos >= 0 ? (
        <Tooltip classes={classes} title="Videos" placement="top" arrow>
          <div className="card-stats-wrapper">
            <FontAwesomeIcon icon={faPlayCircle} className="card-stats-icon" />
            <p className="card-stats">
              <NumberFormat value={props.videos} displayType={'text'} thousandSeparator={true} />
            </p>
          </div>
        </Tooltip>
      ) : null}
      {props.quizzes >= 0 ? (
        <Tooltip classes={classes} title="Quizzes" placement="top" arrow>
          <div className="card-stats-wrapper">
            <FontAwesomeIcon icon={faStickyNote} className="card-stats-icon" />
            <p className="card-stats">
              <NumberFormat value={props.quizzes} displayType={'text'} thousandSeparator={true} />
            </p>
          </div>
        </Tooltip>
      ) : null}
      {props.tries >= 0 ? (
        <Tooltip classes={classes} title="Tries" placement="top" arrow>
          <div className="card-stats-wrapper">
            <FontAwesomeIcon icon={faUser} className="card-stats-icon" />
            <p className="card-stats">
              <NumberFormat value={props.tries} displayType={'text'} thousandSeparator={true} />
            </p>
          </div>
        </Tooltip>
      ) : null}
      {props.percentageOfTriesToPass >= 0 ? (
        <Tooltip classes={classes} title="Percentage of tries to pass" placement="top" arrow>
          <div className="card-stats-wrapper">
            <FontAwesomeIcon icon={faCheck} className="card-stats-icon" />
            <p className="card-stats">
              <NumberFormat
                value={props.percentageOfTriesToPass}
                displayType={'text'}
                suffix={' %'}
                thousandSeparator={true}
              />
            </p>
          </div>
        </Tooltip>
      ) : null}
      {props.questions >= 0 ? (
        <Tooltip classes={classes} title="Questions" placement="top" arrow>
          <div className="card-stats-wrapper">
            <FontAwesomeIcon icon={faQuestionCircle} className="card-stats-icon" />
            <p className="card-stats">
              <NumberFormat value={props.questions} displayType={'text'} thousandSeparator={true} />
            </p>
          </div>
        </Tooltip>
      ) : null}
      {props.averageTime > 0 ? (
        <Tooltip classes={classes} title="Average completion time" placement="top" arrow>
          <div className="card-stats-wrapper">
            <FontAwesomeIcon icon={faClock} className="card-stats-icon" />
            <p className="card-stats">{`${formatSeconds(props.averageTime)} avg time`}</p>
          </div>
        </Tooltip>
      ) : null}
      {props.estimatedTime > 0 ? (
        <Tooltip classes={classes} title="Maximum time to complete" placement="top" arrow>
          <div className="card-stats-wrapper">
            <FontAwesomeIcon icon={faHourglass} className="card-stats-icon" />
            <p className="card-stats">{`${formatSeconds(props.estimatedTime)} max time`}</p>
          </div>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default CardStats;
